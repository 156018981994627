import React, { Component } from "react";
import { withRouter } from "react-router";
import customFetch from "./apicall/api";
import { logout, chkapiinput } from "./Utility";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
class Physical_NACH_register extends Component {
  constructor() {
    super();
    this.state = {
      file: "",
      warning_msg: "",
      uid_warning_msg: "",
      bankdetail: [],
      nachpartner: [],
      nachp: "",
      bankac: "",
      nachuserid: "",
      nachuseriddel: "",
      isErrOpen: false,
      isTokenId: false,
      isSuccess: false,
      isOrderidnull: false,
      isOrderidnotnull: false,
      isUpDowload: true,
      isLoading: false,
      isRegisterDisable: false,
      isBankdisable: false,
      isOrderid: false,
      orderSuccess: [],
      bankNACHdata: [],
      isConfirmdisable: false,
      nach_token_id: "",
      nach_status: "",
      nach_status_flag: "",
      isTokenDel: false,
      isDiffType: false,
      diffTypeMsg: "",
      PremapLoan: [],
      source_loan_id: "",
      loan_documentation_id: 0,
      ISNACH_form_upload: false,
      NACH_form_upload: "",
    };
  }
  componentDidMount() {
    document.title = "NACH Registration";
    this.getbankdetail();
    //this.getnachpartner();
  }
  handleChange = (e) => {
    const {
      prevloans,
      nach_customer_id,
      nach_order_id,
      nach_status_type_flag,
      nach_status_type_description,
      loan_documentation_id,
    } = (this.props.location && this.props.location.state) || {};
    const name = e.target.name;
    let value = e.target.value;
    if (name == "file") value = e.target.files[0];
    this.setState({ [name]: value });
    if (name == "nachp") {
      this.setState({ [name]: value }, function() {
        //console.log("nachp", this.state.nachp);
        this.getbankdetail();
      });
    }
    if (name == "bankac") {
      this.setState({ [name]: value }, function() {
        //console.log("bankac", this.state.nachp);
        this.chknachtype();
      });

      for (let i = 0; i < this.state.bankdetail.length; i++) {
        if (
          parseInt(this.state.bankdetail[i].account_number) ===
          parseInt(e.target.value.split("~")[0])
        ) {
          //this.state.sort_data.push(this.state.partnerpayment[i].payment_gateway);
          // this.setState(
          //   {
          //     bankNACHdata: this.state.bankdetail[i].nach_details,
          //   },
          //   () => console.log("bankNACHdata", this.state.bankNACHdata)
          // );
        }
      }
    }
    if (name == "nachuserid") {
      const pattern = /((^[0-9]+[a-z]+)|(^[a-z]+[0-9]+))+[0-9a-z]+$/i;
      const result = pattern.test(e.target.value);
      console.log("Email Valid:", result);
      let uid_warning_msg = "";
      if (result === true) {
        uid_warning_msg = "";
        this.setState({ uid_warning_msg });
      } else {
        uid_warning_msg = "Please enter username";
        this.setState({ uid_warning_msg });
      }
    }
  };
  chknachtype() {
    const { api_token, ntype } =
      (this.props.location && this.props.location.state) || {};
    let bankntype = this.state.bankac.split("~")[12];
    let bankac = this.state.bankac.split("~")[0];
    let rntype = "";
    if (ntype === "P") {
      rntype = "Phisical";
    }
    if (ntype === "N") {
      rntype = "Net Banking eNach";
    }
    if (ntype === "D") {
      rntype = "Debit Card eNach";
    }
    console.log("ntype", ntype);
    console.log("bankntype", bankntype);
    if (bankntype === "P" || bankntype === "N" || bankntype === "D") {
      if (ntype !== bankntype) {
        this.setState({
          isDiffType: true,
          diffTypeMsg:
            "Household with Bank A/C " +
            bankac +
            " already register through " +
            rntype +
            " mode.",
          isRegisterDisable: true,
        });
      }
      console.log(bankac);
    }
  }
  getnachpartner() {
    const { api_token, household_id } =
      (this.props.location && this.props.location.state) || {};
    //console.log("Get Bank Detail");
    // const formdata = new FormData();
    // formdata.append("household_id", household_id);
    customFetch(process.env.REACT_APP_URL + "/get/nach/partner/list", {
      method: "GET",
      headers: { "api-token": api_token },
      //   body: formdata,
    })
      .then((res) => {
        // if (res.headers.get("api_code") > 0) {
        //   this.setState({
        //     isErrOpen: true,
        //     api_error_message: res.headers.get("api_error_message"),
        //   });
        // }
        return res.json();
      })

      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          nachpartner: json,
        });
      })
      .catch((error) => console.log("error", error));
  }
  getbankdetail() {
    this.setState({ isLoading: true, isBankdisable: true });
    const { api_token, household_id } =
      (this.props.location && this.props.location.state) || {};
    //console.log("Get Bank Detail", this.state.nachp);
    const uid = localStorage.getItem("in_userid");
    /*Check if data is null then logout*/
    var arr = [household_id];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }
    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    const params = {
      household_id: household_id,
    };
    const queryParams = new URLSearchParams(params).toString();
    customFetch(
      process.env.REACT_APP_URL + "/get/household/bank/details?" + queryParams,
      {
        method: "GET",
        headers: { "api-token": api_token },
      }
    )
      .then((res) => {
        // if (res.headers.get("api_code") > 0) {
        //   this.setState({
        //     isErrOpen: true,
        //     api_error_message: res.headers.get("api_error_message"),
        //   });
        // }
        return res.json();
      })

      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          bankdetail: json,
          isLoading: false,
        });
      })
      .catch((error) => console.log("error", error));
  }
  // logout() {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // }
  deleteToken = (e) => {
    e.preventDefault();
    const uname = localStorage.getItem("in_username");
    let uid_warning_msg = "";
    //console.log("this.state.nachuserid", this.state.nachuseriddel);
    //console.log("uname", uname);
    if (this.state.nachuseriddel == "") {
      //console.log("1");
      uid_warning_msg = "Please enter username";
      this.setState({ uid_warning_msg });
      return;
    } else {
      uid_warning_msg = "";
      this.setState({ uid_warning_msg });
    }
    if (this.state.nachuseriddel !== uname) {
      //console.log("2");
      uid_warning_msg = "Please enter valid usnername ";
      this.setState({ uid_warning_msg });
      return;
    } else {
      uid_warning_msg = "";
      this.setState({ uid_warning_msg });
    }
    if (!uid_warning_msg) {
      this.setState({
        isConfirmdisable: true,
      });
      let file = "";
      const { api_token } =
        (this.props.location && this.props.location.state) || {};
      /*Check if data is null then logout*/
      var arr = [this.state.nach_token_id];
      var chkdata = chkapiinput(arr);
      if (chkdata === false) {
        this.setState({ isSpin: false });
        setTimeout(function() {
          logout();
        }, 2000);
      }
      /*Check if data is null then logout End*/
      const formdata = new FormData();
      formdata.append("nach_token_id", this.state.nach_token_id);
      customFetch(process.env.REACT_APP_URL + "/deletetoken", {
        method: "POST",
        headers: { "api-token": api_token },
        body: formdata,
      })
        .then((res) => {
          // if (res.headers.get("api_code") > 0) {
          //   this.setState({
          //     isErrOpen: true,
          //     api_error_message: res.headers.get("api_error_message"),
          //   });
          // }
          return res.json();
        })

        .then((json) => {
          if (
            json.api_code === 4 ||
            json.api_code === 3 ||
            json.api_code === 5
          ) {
            localStorage.clear();
            this.props.history.push("/");
          }
          this.setState({
            isTokenDel: false,
            isOrderid: true,
            orderSuccess: json.message,
          });
        })
        .catch((error) => console.log("error", error));
    }
  };
  validateuser = (e) => {
    e.preventDefault();
    const uname = localStorage.getItem("in_username");
    let uid_warning_msg = "";
    //console.log("this.state.nachuserid", this.state.nachuserid);
    //console.log("uname", uname);
    if (this.state.nachuserid == "") {
      //console.log("1");
      uid_warning_msg = "Please enter username";
      this.setState({ uid_warning_msg });
      return;
    } else {
      uid_warning_msg = "";
      this.setState({ uid_warning_msg });
    }
    if (this.state.nachuserid !== uname) {
      //console.log("2");
      uid_warning_msg = "Please enter valid usnername ";
      this.setState({ uid_warning_msg });
      return;
    } else {
      uid_warning_msg = "";
      this.setState({ uid_warning_msg });
    }
    if (!uid_warning_msg) {
      this.setState({
        isConfirmdisable: true,
      });
      let file = "";
      const {
        api_token,
        household_id,
        ntype,
        loan_id,
        map_id,
        prevloans,
        nach_customer_id,
        nach_order_id,
        nach_status_type_flag,
        nach_status_type_description,
        filename,
        loan_documentation_id,
      } = (this.props.location && this.props.location.state) || {};
      // console.log("Output");
      // console.log(this.state.bankac);
      let bankacno = this.state.bankac.split("~")[0];
      let ifsc = this.state.bankac.split("~")[1];
      let account_type_name = this.state.bankac.split("~")[2];
      let name_as_per_account = this.state.bankac.split("~")[3];

      const formdata = new FormData();
      // formdata.append("vendor_id", this.state.nachp);
      // formdata.append("hh_id", household_id);
      formdata.append("loan_id", loan_id);
      if (ntype !== "P") {
        formdata.append("nach_type", "E");
        formdata.append("auth_type", ntype);
      } else {
        formdata.append("nach_type", "P");
      }
      formdata.append("bank_acc_no", bankacno.trim());
      formdata.append("ifsc_code", ifsc.trim());
      formdata.append(
        "beneficiary_name",
        name_as_per_account.toLowerCase().trim()
      );
      formdata.append("acc_type", account_type_name.toLowerCase().trim());

      // console.log(this.state);

      this.props.history.push({
        pathname: "/Nach_type",
        state: {
          api_token: api_token,
          household_id: household_id,
          loan_id: loan_id,
          map_id: map_id,
          prevloans: prevloans,
          nach_customer_id: nach_customer_id,
          nach_order_id: nach_order_id,
          nach_status_type_flag: nach_status_type_description,
          nach_status_type_description: nach_status_type_description,
          filename: filename,
          loan_documentation_id: loan_documentation_id,
          name_as_per_account: name_as_per_account,
          bankacno: bankacno,
          ifsc: ifsc,
          account_type_name: account_type_name,
        },
      });
      //   customFetch(process.env.REACT_APP_URL + "/set/nach/details", {
      //     method: "POST",
      //     headers: { "api-token": api_token },
      //     body: formdata,
      //   })
      //     .then((res) => {
      //       // if (res.headers.get("api_code") > 0) {
      //       //   this.setState({
      //       //     isErrOpen: true,
      //       //     api_error_message: res.headers.get("api_error_message"),
      //       //   });
      //       // }
      //       return res.json();
      //     })

      //     .then((json) => {
      //       if (
      //         json.api_code === 4 ||
      //         json.api_code === 3 ||
      //         json.api_code === 5
      //       ) {
      //         localStorage.clear();
      //         this.props.history.push("/");
      //       }
      //       if (json.order_id) {
      //         this.setState({
      //           orderSuccess: "Your Orderid: " + json.order_id,
      //           isOrderid: true,
      //           isOrderidnull: false,
      //         });
      //       } else {
      //         this.setState({
      //           orderSuccess:
      //             "Please raise a support ticket with Household id and partner detail",
      //           isOrderid: true,
      //           isOrderidnull: false,
      //         });
      //       }
      //     })
      //     .catch((error) => console.log("error", error));
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      api_token,
      household_id,
      ntype,
      prevloans,
      nach_customer_id,
      nach_order_id,
      nach_status_type_flag,
      nach_status_type_description,
      map_id,
      filename,
      loan_id,
    } = (this.props.location && this.props.location.state) || {};
    let warning_msg = "";
    if (this.state.bankac == "") {
      warning_msg = "Please choose bank account";
      this.setState({ warning_msg });
      return;
    } else {
      this.setState({
        isRegisterDisable: true,
      });
    }

    let nach_token_id = "";
    //let nach_order_id = "";
    //let filename = "";
    let upload_link = "";
    //let nach_customer_id = "";
    let nach_partner_id = "";

    //let nach_status_type_flag = "";
    let nach_type = "";

    prevloans.forEach((element) => {
      if (
        element.account_number === this.state.bankac.split("~")[0] &&
        element.loan_id !== loan_id
      ) {
        console.log("nach_token_id", element.nach_token_id);
        nach_token_id = element.nach_token_id;
        nach_type = element.nach_type;
        this.setState({
          source_loan_id: element.prev_loan_documentation_id,
        });
        //nach_order_id = col.nach_order_id;

        //nach_customer_id = col.nach_customer_id;

        //nach_status_type_description = element.nach_status_type_description;
        //nach_status_type_flag = col.nach_status_type_flag;
      }
    });
    let bankacno = this.state.bankac.split("~")[0];
    let ifsc = this.state.bankac.split("~")[5];
    let account_type_name = this.state.bankac.split("~")[6];
    let name_as_per_account = this.state.bankac.split("~")[7];
    if (
      nach_token_id === "" ||
      nach_token_id === null
      //  &&
      // nach_partner_id !== this.state.nachp
    ) {
      ntype === "D" || ntype === "N" || ntype === "E"
        ? nach_order_id === "" ||
          nach_order_id === null ||
          nach_order_id === undefined
          ? this.setState({
              isOrderidnull: true,
            })
          : this.setState({
              isOrderidnotnull: true,
            })
        : filename === "" || filename === null || filename === undefined
        ? this.setState({
            isOrderidnull: true,
          })
        : this.setState({
            isOrderidnotnull: true,
          });
    } else {
      this.setState({
        isTokenId: true,
        nach_token_id: nach_token_id,
        nach_status: nach_status_type_description,
        nach_status_flag: nach_status_type_flag,
      });
    }
  };
  redirectclick() {
    window.location.assign(this.state.bankac.split("~")[4]);
  }
  ImgchangeHandler(event, loan_id, nach_order_id) {
    this.setState({ isSpin: true });
    event.stopPropagation();
    //console.log("Change Image");
    //event.preventDefault();
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    var file = event.target.files[0];
    //event.target.value = null;
    console.log("loan_id", loan_id);
    console.log("FileNew", loan_id + "." + file.name.split(".").pop());
    const formdata = new FormData();
    formdata.append("file", file);
    formdata.append("order_id", nach_order_id);
    formdata.append("loan_id", loan_id);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
      },
      body: formdata,
    };
    customFetch(process.env.REACT_APP_URL + "/upload/nach/form", requestOptions)
      .then((response) => {
        if (response.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: response.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        console.log("Data", data);
        this.setState({
          NACH_form_upload: data,
          ISNACH_form_upload: true,
          isSpin: false,
        });
        //this.fetchDetails();
      })
      .catch((error) => console.log("error", error));
  }
  downloadclick() {
    const { api_token, household_id, filename } =
      (this.props.location && this.props.location.state) || {};

    let path = filename;
    if (path !== "" || path !== null || path !== undefined) {
      /*Check if data is null then logout*/
      // var arr = [
      //   path.split("/")[0],
      //   path.split("/")[2],
      //   path.split("/")[1] + "/" + path.split("/")[2],
      // ];
      // var chkdata = chkapiinput(arr);
      // if (chkdata === false) {
      //   this.setState({ isSpin: false });
      //   setTimeout(function () {
      //     logout();
      //   }, 2000);
      // }
      /*Check if data is null then logout End*/
      const formdata = new FormData();
      // formdata.append("bucket_name", path.split("/")[0]);
      // formdata.append("blob_name", path.split("/")[2]);
      formdata.append("blob_url", path);
      const requestOptions = {
        method: "POST",
        headers: {
          "api-token": api_token,
        },
        body: formdata,
      };
      customFetch(
        process.env.REACT_APP_URL + "/download/files/bucket",
        requestOptions
      )
        .then((res) => {
          if (res.headers.get("api_code") > 0) {
            this.setState({
              isErrOpen: true,
              api_error_message: res.headers.get("api_error_message"),
            });
          }

          if (res.api_code === 4 || res.api_code === 3 || res.api_code === 5) {
            localStorage.clear();
            this.props.history.push("/");
          }
          res.blob().then((img) => {
            //URL.createObjectURL(img)
            const url = window.URL.createObjectURL(img);
            //console.log(URL.createObjectURL(img))
            // this.setState({
            //   viewImg: URL.createObjectURL(img),
            //   isOpen: true,
            // });
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", path.split("/")[2]);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
          });
        })
        .catch((error) => console.log("error", error));
    } else {
      this.setState({
        api_error_message: "File Not Found",
      });
    }
  }
  deltoken(tid) {
    this.setState({
      isTokenDel: true,
    });
  }
  duplicate_nach() {
    this.setState({ isSpin: true });
    const { loan_documentation_id, api_token } =
      (this.props.location && this.props.location.state) || {};
    const uid = localStorage.getItem("in_userid");
    console.log("source_loan_id", this.state.source_loan_id);
    console.log("target_loan_id", loan_documentation_id);
    /*Check if data is null then logout*/
    var arr = [uid, this.state.source_loan_id, loan_documentation_id];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }
    const formdata = new FormData();
    formdata.append("source_loan_doc_id", this.state.source_loan_id);
    formdata.append("target_loan_doc_id", loan_documentation_id);
    formdata.append("user_id", uid);
    customFetch(process.env.REACT_APP_URL + "/set/duplicate/nach", {
      method: "POST",
      headers: { "api-token": api_token },
      body: formdata,
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
          });
        }
        return res.json();
      })

      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isSpin: false,
          isErrOpen: true,
          api_error_message: json.message,
        });
      })
      .catch((error) => console.log("error", error));
  }
  render() {
    const {
      isErrOpen,
      bankdetail,
      api_error_message,
      nachpartner,
      isSuccess,
      isOrderidnull,
      isOrderidnotnull,
      isUpDowload,
      isBankdisable,
      isOrderid,
      orderSuccess,
      isTokenId,
      isTokenDel,
      isDiffType,
      diffTypeMsg,
      ISNACH_form_upload,
      NACH_form_upload,
    } = this.state;
    const {
      api_token,
      household_id,
      ntype,
      loan_id,
      map_id,
      prevloans,
      nach_customer_id,
      nach_order_id,
      nach_status_type_flag,
      nach_status_type_description,
      filename,
      hhname,
      nach_details_nach_token_id,
    } = (this.props.location && this.props.location.state) || {};

    async function displayRazorpay(
      nach_order_id,
      nach_customer_id,
      api_token,
      hhname,
      household_id
    ) {
      localStorage.setItem("api_token", api_token);
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
      const options = {
        key: process.env.REACT_APP_RZP_KEY,
        order_id: nach_order_id,
        customer_id: nach_customer_id,
        recurring: "1",
        //callback_url:"https://d8ea-116-74-138-50.in.ngrok.io/callbackurl/razorpay",
        callback_url:
          process.env.REACT_APP_URL + "/callbackurl/razorpay?frm=ca",
        redirect: true,
        // handler: function(response) {
        //   alert(response.razorpay_payment_id);
        //   alert(response.razorpay_order_id);
        //   alert(response.razorpay_signature);
        // },
        notes: {
          "note_key 1": household_id,
          "note_key 2": hhname,
        },
        theme: {
          color: "#F37254",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }

    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div
          className={
            ISNACH_form_upload
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ ISNACH_form_upload: false });
                  window.location.reload();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>
                {NACH_form_upload ? (
                  NACH_form_upload.razorpay_order_id ? (
                    <>
                      <strong>Order Id : </strong>
                      {NACH_form_upload.razorpay_order_id}
                      <br />
                      <strong>Payment Id : </strong>
                      {NACH_form_upload.razorpay_payment_id}
                      <br />
                    </>
                  ) : (
                    <>
                      <span>{NACH_form_upload.message}</span>
                    </>
                  )
                ) : (
                  ""
                )}
              </p>
            </div>
          </div>
        </div>
        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>
        <div
          className={
            isDiffType
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isDiffType: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{diffTypeMsg}</p>
            </div>
          </div>
        </div>
        <div
          className={
            isTokenDel
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() =>
                  this.setState({
                    isTokenDel: false,
                  })
                }
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>Please Confirm your userid to Delete Token</p>
              <input
                className="form-control"
                name="nachuseriddel"
                onChange={this.handleChange}
              />
              {this.state.uid_warning_msg != "" ? (
                <span className="text-danger">
                  *{this.state.uid_warning_msg}
                </span>
              ) : (
                ""
              )}
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={this.state.isConfirmdisable}
                  onClick={this.deleteToken}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() =>
                    this.setState({
                      isTokenDel: false,
                    })
                  }
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            isOrderid
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ isOrderid: false });
                  window.location.reload(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{orderSuccess}</p>
            </div>
          </div>
        </div>
        <div
          className={
            isSuccess
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isSuccess: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>NACH Registration Success!</p>
            </div>
          </div>
        </div>
        <div
          className={
            isOrderidnull
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() =>
                  this.setState({
                    isOrderidnull: false,
                    isRegisterDisable: false,
                  })
                }
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>Please Confirm your userid to register NACH</p>
              <input
                className="form-control"
                name="nachuserid"
                onChange={this.handleChange}
              />
              {this.state.uid_warning_msg != "" ? (
                <span className="text-danger">
                  *{this.state.uid_warning_msg}
                </span>
              ) : (
                ""
              )}
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={this.state.isConfirmdisable}
                  onClick={this.validateuser}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() =>
                    this.setState({
                      isOrderidnull: false,
                      isRegisterDisable: false,
                    })
                  }
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        <section className="main-sec">
          <div className="container">
            <div className="header-area">
              <div
                className="icon_bg"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../assets/images/back.png")}
                  className="cso_icon_img"
                  alt=""
                />
              </div>
              <div className="head-title">NACH Register</div>
              <div className="log-out-report">
                <img
                  src={require("../assets/images/power-off.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
            </div>

            {!this.state.isOrderidnotnull ? (
              <div className="card card-border mt-3">
                <div className="card-body support_form_body">
                  <form onChange={this.handleChange} className="support_form">
                    {bankdetail.length > 0 ? (
                      <>
                        <select name="bankac" defaultValue="" id="">
                          <option value="" disabled hidden>
                            Select Bank Account
                          </option>
                          {bankdetail.map((item, key) => {
                            let filename = "";

                            let upload_link =
                              item.upload_link !== null
                                ? "~" + item.upload_link
                                : "~";
                            return (
                              <option
                                key={key}
                                value={
                                  (item.account_number !== null
                                    ? item.account_number
                                    : "") +
                                  "~" +
                                  (item.ifsc !== null ? item.ifsc : "") +
                                  "~" +
                                  (item.account_type_name !== null
                                    ? item.account_type_name
                                    : "") +
                                  "~" +
                                  (item.name_as_per_account !== null
                                    ? item.name_as_per_account
                                    : "")
                                }
                              >
                                {item.account_number + " - " + item.bank_name}
                              </option>
                            );
                          })}
                        </select>
                        {this.state.warning_msg != "" ? (
                          <span className="text-danger">
                            *{this.state.warning_msg}
                          </span>
                        ) : (
                          ""
                        )}
                        <button
                          className="btn btn-primary rbtn btn-lg col-8 col-md-6 col-lg-4"
                          type="submit"
                          //disabled={this.state.isRegisterDisable}
                          onClick={this.handleSubmit}
                        >
                          Register
                        </button>
                      </>
                    ) : this.state.isLoading ? (
                      "Loading..."
                    ) : (
                      "Bank Deatail Not Found"
                    )}
                  </form>
                </div>
              </div>
            ) : (
              ""
            )}

            {this.state.isOrderidnotnull ? (
              <div className="card card-border mt-3">
                <div className="card-body support_form_body">
                  <div className="col-md-12">
                    {ntype === "N" || ntype === "D" || ntype === "E" ? (
                      <>
                        <div className="col-md-12 float-left text-center">
                          {/* <button
                            onClick={() =>
                              displayRazorpay(
                                nach_order_id,
                                nach_customer_id,
                                api_token,
                                hhname,
                                household_id
                              )
                            }
                            target="_blank"
                            className="btn btn-primary"
                          >
                            Authentication
                          </button> */}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-md-6 float-left text-center">
                          <p>Download NACH form</p>
                          <button
                            onClick={() => {
                              this.downloadclick();
                            }}
                            className="btn btn-primary"
                          >
                            Download
                          </button>
                        </div>
                        <div className="col-md-6 float-left text-center">
                          <p>Upload sign NACH form</p>
                          <button
                            onClick={(event) => {
                              event.preventDefault();
                              // this.['upload'+item.cash_balance_id].click();
                              this["upload" + loan_id].click();
                            }}
                            target="_blank"
                            className="btn btn-primary"
                          >
                            Upload
                          </button>
                          <input
                            type="file"
                            name="file"
                            accept=".jpg, .JPG, .jpeg, .JPEG, .png, .PNG"
                            onChange={(event) => {
                              event.preventDefault();
                              this.setState({ isSpin: false });
                              this.ImgchangeHandler(
                                event,
                                loan_id,
                                nach_details_nach_token_id
                              );
                            }}
                            //ref={(ref) => (this.upload = ref)}
                            ref={(ref) => {
                              this["upload" + loan_id] = ref;
                            }}
                            style={{ display: "none" }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {this.state.isTokenId ? (
              <>
                <div className="card card-border mt-3">
                  <div className="card-body support_form_body">
                    <div className="col-md-12">
                      <>
                        <div className="pop-title">
                          <span>
                            Do you want to add this token for loan A/C{" "}
                            <strong>{loan_id}</strong>?
                          </span>
                        </div>
                        <div className="form-group row text-center">
                          <div className="col-sm-3">
                            <button
                              className="btn btn-primary"
                              onClick={() => this.duplicate_nach()}
                            >
                              Yes, Add This Token
                            </button>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
                <div className="card card-border mt-3">
                  <div className="card-body support_form_body">
                    <div className="col-md-12">
                      <>
                        <div className="pop-title">
                          <strong>Token :</strong> {this.state.nach_token_id}
                          <div className="stsr">{this.state.nach_status}</div>
                        </div>

                        {this.state.nach_status_flag === "J" ||
                        this.state.nach_status_flag === "C" ? (
                          <div className="delbtn text-center">
                            <button
                              className="btn btn-danger"
                              onClick={() =>
                                this.deltoken(this.state.nach_token_id)
                              }
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(Physical_NACH_register);
