import React, { Component } from "react";
import { logout, generateId } from "./Utility";

export class Esign_card extends Component {
  render() {
    return (
      <>
        <div className="container">
          <div className="payload_view_navbar">
            <button
              className="button back_button"
              onClick={() => this.props.history.goBack()}
            >
              <img src={require("../assets/images/back.png")} alt="" />
            </button>
            <h4>E sign</h4>
            <button className="button" onClick={() => logout()}>
              <img src={require("../assets/images/power-off.png")} alt="" />
            </button>
          </div>
          <div className="mt-4">
            <div
              className="card card-border-small col-sm-6 py-2"
              onClick={() => {
                this.props.history.push({
                  pathname: "/Esign",
                  state: {
                    api_token: this.props.location.state.api_token,
                    effective_designation_id: this.props.location
                      .stateeffective_designation_id,
                    isDCB: this.props.location.state.isDCB,
                  },
                });
              }}
            >
              <div className="card-body">
                <div className="image-holder-5">
                  <img
                    src={require("../assets/images/check-list.png")}
                    alt=""
                  />
                </div>
                <div className="card-heading">
                  <h2>E-sign</h2>
                </div>
                <div className="text-center"></div>
              </div>
            </div>
            <div
              className="card card-border-small col-sm-6 py-2"
              onClick={() => {
                this.props.history.push({
                  pathname: "/Group_esign",
                  state: {
                    api_token: this.props.location.state.api_token,
                    effective_designation_id: this.props.location.state
                      .effective_designation_id,
                    isDCB: this.props.location.state.isDCB,
                  },
                });
              }}
            >
              <div className="card-body">
                <div className="image-holder-5">
                  <img
                    src={require("../assets/images/check-list.png")}
                    alt=""
                  />
                </div>
                <div className="card-heading">
                  <h2>Group E-sign</h2>
                </div>
                <div className="text-center"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Esign_card;
