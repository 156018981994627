import React, { Component, useState } from "react";
import customFetch from "./apicall/api";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "antd/dist/antd.css";
import { Switch } from "antd";
import { logout, chkapiinput, generateId } from "./Utility";
import { ThemeContext } from "../context/IsLoader";
import "../assets/css/cheque.css";
import { PESpan } from "./permissionComponents"
const generate_Id1 = generateId(10);

export default class Cheque extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: "",
      // incorrect_mobile_number: 0,
      // unable_to_reach: 0,
      //preferred_date: new Date(),
      selectedFile: "",
      status: 1,
      isDisable: true,
      isErrOpen: false,
      message: "",
      firstChq: "",
      isOpen: false,
      data: [],
      isSpin: false,
      IPv4: null,
      latitude: null,
      longitude: null,
      search_text: "",
      purpose: "",
      og_data: [],
      cheque_no: [1],
      seqChqNo: [],
      chq_data: "",
      loan_id: [],
      loanModal: false,
      setLoanModal: false,
      errMessage: "",
      errFlag: false,
      assignedLoanID: [],
      selectedChqNo: "",
      isErrOpenNew: false,
      messageNew: "",
      editFlag: false,
      selChqEdit: "",
      deletedChq: [],
      chq_upload_filename: "",
      fileuploaded: false,
      viewFile: "",
      isView: false,
      succmessage: "",
      isSuccOpen: false,
      selPurposeForSave: "",
      tempLoanID: [],
      ref_loan_doc_id: "",
      unLoanID: [],
      bckLoanID: [],
      selectedLoanData: []
    };
    this.addChqInput = this.addChqInput.bind(this);
    this.addChqSequence = this.addChqSequence.bind(this);
    this.setFirstChqNo = this.setFirstChqNo.bind(this);
    this.saveChequeDetails = this.saveChequeDetails.bind(this);
    this.addPurpose = this.addPurpose.bind(this);
    this.loadLoanID = this.loadLoanID.bind(this);
    this.handleLoanID = this.handleLoanID.bind(this);
    this.assignLoanID = this.assignLoanID.bind(this);
    this.editDeleteChequeDetails = this.editDeleteChequeDetails.bind(this);
    this.editPurpose = this.editPurpose.bind(this);
    this.updateChequeDetails = this.updateChequeDetails.bind(this);
  }

  setChqData(item) {
    this.state.selChqEdit = item;
    console.log("selchqedit :   ..." +  JSON.stringify(this.state.selChqEdit));
  }

  editDeleteChequeDetails(event) {
    console.log(event.target.value);

    console.log("selchqedit :   ..." +  JSON.stringify(this.state.selChqEdit));
    this.state.deletedChq = [];



    if (event.target.value == 1) {
      this.state.editFlag = true;
    } else if (event.target.value == 2) {

      // let text = "Do You Really Want To Delete?";
      // if (confirm(text) == true) {
      const uid = localStorage.getItem("in_userid");
      const { api_token } =
        (this.props.location && this.props.location.state) || {};

      const formdata = new FormData();
      formdata.append("userid", uid);
      formdata.append("bank_id", this.state.og_data.bank_id);
      formdata.append("cheque_number", this.state.selChqEdit.cheque_number.replaceAll("-", "|"));
      formdata.append("household_file_id", this.state.selChqEdit.household_file_upload_log_id);
      formdata.append("purpose", this.state.selChqEdit.purpose);
      let generate_Id = generateId(10);
      customFetch(process.env.REACT_APP_URL + "/delete/stored/cheque", {
        method: "POST",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "Cheque",
          user_id: localStorage.getItem("in_userid"),
        },
        body: formdata,
      })
        .then((res) => {
          return res.json();
        }).then((json) => {
          console.log("data - ", json);
          if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
            localStorage.clear();
            this.props.history.push("/");
          } else if (json.length == 0) {
            this.setState({
              isSpin: false,
              // message: "No Data Found!",
              // isErrOpen: true
            });
          } else {

            if (json.status == 0) {
              this.setState({
                isSpin: false,
                message: json.message,
                isErrOpen: true
              });
            } else {
              this.setState({
                isSpin: false,
                succmessage: json.message,
                isSuccOpen: true
              });

            }

          }
        })

      // }
      //  else {
      //   text = "You canceled!";
      // }
    } else {
      this.state.editFlag = false;
    }
    this.forceUpdate();
  }

  // AT-1935 | Vaibhav Sawant | 03-08-2023 | User can Unassign and Reassign loan, Back Button will navigate to dashboard
  assignLoanID(purpose) {
    // console.log(purpose);
    let flag = 0;
    this.setState({ isSpin: true });
    this.forceUpdate();

    this.state.assignedLoanID = [];

    // console.log(this.state.chq_data);
    // console.log(this.state.selectedChqNo);
    let final_chq_no = [];

    this.state.chq_data.map((item) => {
      if (item.cheque_number === this.state.selectedChqNo) {
        final_chq_no.push(item);
      }
    });
    // console.log(final_chq_no);


    // AT-1935 | Vaibhav Sawant | 14-08-2023 | Error for assigning and un-assigning of loan id fixed

    // if (final_chq_no[0].ref_loan_doc_id === "") {
      // console.log(this.state.tempLoanID);

      // let selectedLoan = [];

      // this.state.loan_id.map((item) => {
      //   if (item[1] == 1) {
      //     selectedLoan.push(item[2]);
      //   }
      // });

    //   if (this.state.tempLoanID.length > 0) {
    //     console.log(this.state.tempLoanID.join("|"));
    //     const uid = localStorage.getItem("in_userid");
    //     const { api_token } =
    //       (this.props.location && this.props.location.state) || {};
    //     const formdata = new FormData();
    //     formdata.append("bank_id", this.state.og_data.bank_id);
    //     formdata.append("ref_loan_doc_id", this.state.tempLoanID.join("|"));
    //     formdata.append("cheque_number", this.state.selectedChqNo);
    //     formdata.append("purpose", purpose.split("|")[0]);
    //     // formdata.append("loans", this.state.unLoanID.join("|"));
    //     formdata.append("editFlag", 0);

    //     let generate_Id = generateId(10);
    //     customFetch(process.env.REACT_APP_URL + "/assign/loan/cheque", {
    //       method: "POST",
    //       headers: {
    //         "api-token": api_token,
    //         component_id: generate_Id1,
    //         apiId: generate_Id,
    //         api_group_id: "",
    //         component: "Cheque",
    //         user_id: localStorage.getItem("in_userid"),
    //       },
    //       body: formdata,
    //     })
    //       .then((res) => {
    //         return res.json();
    //       }).then((json) => {
    //         console.log("data - ", json);
    //         if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
    //           localStorage.clear();
    //           this.props.history.push("/");
    //         } else if (json.length == 0) {
    //           this.setState({
    //             isSpin: false,
    //             message: "No Data Found!",
    //             isErrOpen: true
    //           });
    //         } else {

    //           if (json.status == 0) {
    //             this.setState({
    //               loanModal: false,
    //               isSpin: false,
    //               message: json.message,
    //               isErrOpen: true
    //             });
    //           } else {
    //             this.state.loanModal = false;
    //             this.forceUpdate();
    //             this.setState({
    //               isSpin: false,
    //               messageNew: json.message,
    //               isErrOpenNew: true
    //             });

    //           }

    //         }

    //       }).catch((error) => console.log("error", error));
    //   } else {
    //     this.setState({
    //       isSpin: false,
    //       errMessage: "Please Select Loan ID",
    //       errFlag: true
    //     });
    //   }
    // } else {

      // console.log(this.state.tempLoanID);
      // console.log(this.state.bckLoanID);
      // console.log(this.state.unLoanID);

      // if (this.state.tempLoanID.length == this.state.bckLoanID.length) {
      //   if(this.state.tempLoanID)
      //   this.setState({
      //     isSpin: false,
      //     errMessage: "Please Select/Unselect Loan ID",
      //     errFlag: true
      //   });
      // } else {

      // AT-1935 | Vaibhav Sawant | 07-08-2023 | Giving error if user trying to assigned loan which is already assigned
      // let ref_loan_doc = Array.from(new Set(final_chq_no[0].ref_loan_doc_id.split("|")));
      // let temp = [];
      // flag = 0;
      // // console.log(ref_loan_doc);
      // this.state.loan_id.map((item) => {
      //   // console.log(item);
      //   if (ref_loan_doc.includes(item[2])) {
      //     if (item[1] == 0) {
      //       temp.push(item);
      //     }
      //   } else {
      //     if (item[1] == 1) {
      //       temp.push(item);
      //     }
      //   }
      // });

      const uid = localStorage.getItem("in_userid");
      const { api_token } =
        (this.props.location && this.props.location.state) || {};
      const formdata = new FormData();
      formdata.append("bank_id", this.state.og_data.bank_id);
      // formdata.append("ref_loan_doc_id", final_chq_no[0].ref_loan_doc_id);
      formdata.append("ref_loan_doc_id", this.state.tempLoanID.join("|"));
      formdata.append("cheque_number", final_chq_no[0].cheque_number);
      formdata.append("purpose", final_chq_no[0].purpose);
      formdata.append("loans", this.state.unLoanID.join("|"));
      formdata.append("editFlag", 1);
      customFetch(process.env.REACT_APP_URL + "/assign/loan/cheque", {
        method: "POST",
        headers: { "api-token": api_token },
        body: formdata
      })
        .then((res) => {
          return res.json();
        }).then((json) => {
          console.log("data - ", json);
          if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
            localStorage.clear();
            this.props.history.push("/");
          } else if (json.length == 0) {
            this.setState({
              isSpin: false,
              message: "No Data Found!",
              isErrOpen: true
            });
          } else {

            if (json.status == 0) {
              this.setState({
                isSpin: false,
                errMessage: json.message,
                errFlag: true
              });
            } else {
              const updatedChqData = this.state.chq_data.map((item) => {
                if (item.cheque_number === this.state.selectedChqNo) {
                  return {
                    ...item,
                    ref_loan_doc_id: this.state.tempLoanID.join("|"),
                    purpose: final_chq_no[0].purpose,
                  };
                }
                return item;
              });
              this.state.loanModal = false;
              this.forceUpdate();
              this.setState({
                isSpin: false,
                messageNew: json.message,
                isErrOpenNew: true,
                chq_data: updatedChqData,
              });

            }

          }

        }).catch((error) => console.log("error", error));

      // }

      // return;




      // console.log(temp);

      // // return;

      // if (temp.length == 0) {
      //   this.setState({
      //     isSpin: false,
      //     errMessage: "Already Assigned, Please select other Loan ID",
      //     errFlag: true
      //   });
      // } else {




      // }



      // console.log(this.state.loan_id);


    // }

    // if (final_chq_no.ref_loan_doc_id !== "") {
    //   flag = 1;
    //   console.log(this.state.loan_id);
    // } else {
    //   this.setState({
    //     isSpin: false,
    //     errMessage: "Please Select Loan ID",
    //     errFlag: true
    //   });
    // }

    // this.state.loan_id.map((lid, index) => {


    //   this.state.chq_data.map((item) => {
    //     if (item.cheque_number.includes(this.state.selectedChqNo)) {
    //       if (!item.ref_loan_doc_id.includes(lid[2])) {
    //         if (lid[1] == 1) {
    //           flag = 1;
    //           this.state.assignedLoanID.push(lid[2]);
    //         }
    //       }
    //     }
    //   });
    // });


    // if (flag) {
    //   const uid = localStorage.getItem("in_userid");
    //   const { api_token } =
    //     (this.props.location && this.props.location.state) || {};
    //   console.log(this.state.assignedLoanID.join("|"));
    //   console.log(this.state.selectedChqNo);
    //   const formdata = new FormData();
    //   formdata.append("bank_id", this.state.og_data.bank_id);
    //   formdata.append("ref_loan_doc_id", this.state.assignedLoanID.join("|"));
    //   formdata.append("cheque_number", this.state.selectedChqNo);
    //   formdata.append("purpose", purpose.split("|")[0]);
    //   fetch(process.env.REACT_APP_URL + "/assign/loan/cheque", {
    //     method: "POST",
    //     headers: { "api-token": api_token },
    //     body: formdata
    //   })
    //     .then((res) => {
    //       return res.json();
    //     }).then((json) => {
    //       console.log("data - ", json);
    //       if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
    //         localStorage.clear();
    //         this.props.history.push("/");
    //       } else if (json.length == 0) {
    //         this.setState({
    //           isSpin: false,
    //           message: "No Data Found!",
    //           isErrOpen: true
    //         });
    //       } else {

    //         if (json.status == 0) {
    //           this.setState({
    //             loanModal: false,
    //             isSpin: false,
    //             message: json.message,
    //             isErrOpen: true
    //           });
    //         } else {
    //           this.state.loanModal = false;
    //           this.forceUpdate();
    //           this.setState({
    //             isSpin: false,
    //             messageNew: json.message,
    //             isErrOpenNew: true
    //           });

    //         }

    //       }

    //     }).catch((error) => console.log("error", error));

    // } else {
    //   this.setState({
    //     isSpin: false,
    //     errMessage: "Please Select Loan ID",
    //     errFlag: true
    //   });
    // }

  }

  // handleLoanID(e) {
  //   const selectedLoanID = e.target.value;  // Get the value of the selected radio button (Loan ID)
  
  //   if (this.state.ref_loan_doc_id === "") {
  //     // For radio button, you simply set the selected Loan ID
  //     this.setState({
  //       tempLoanID: [selectedLoanID],  // Only one Loan ID should be selected, so store it directly
  //       selectedLoanID: selectedLoanID  // Update the selected Loan ID state
  //     });
  //   } else {
  //     this.setState({
  //       tempLoanID: [selectedLoanID],  // Same logic for updating tempLoanID in case ref_loan_doc_id is present
  //       selectedLoanID: selectedLoanID  // Update the selected Loan ID
  //     });
  
  //     // Add the selected Loan ID to the unLoanID state if it's not already there
  //     if (!this.state.unLoanID.includes(selectedLoanID)) {
  //       this.setState(prevState => ({
  //         unLoanID: [...prevState.unLoanID, selectedLoanID]
  //       }));
  //     }
  //   }
  
  //   console.log("Selected Loan ID:", this.state.selectedLoanID);
  // }
  
  handleLoanID(e) {
    const selectedLoanID = e.target.value;
    const partnerNames = this.state.og_data.partner_name.split(",");
    const selectedIndex = this.state.loan_id.findIndex(item => item[2] === selectedLoanID);

    this.setState({
        tempLoanID: [selectedLoanID],
        selectedLoanID: selectedLoanID,
    });

    console.log(this.state.tempLoanID);
  }

  getPartnerName(loanDocId) {
    const loanDocIds = this.props.location.state.acc_data.loan_doc_id.split(',');
    const partnerNames = this.props.location.state.acc_data.partner_name.split(',');

    const index = loanDocIds.indexOf(loanDocId);

    if (index !== -1) {
        return partnerNames[index];
    }
    return null;
  }

  loadLoanID(chqNo, purpose, ref_loan_doc_id) {

    this.state.errMessage = "";
    this.state.errFlag = false;
    this.state.selectedChqNo = chqNo;
    this.state.selPurposeForSave = purpose;
    this.state.loanModal = true;
    let final_chq_no = [];
    this.state.selectedLoanID = ref_loan_doc_id || "";
    // this.state.ref_loan_doc_id = ref_loan_doc_id;
    console.log(ref_loan_doc_id);
    console.log(this.state.selectedChqNo);

    if (ref_loan_doc_id === "") {
      this.state.tempLoanID = [];
    } else {
      this.state.tempLoanID = Array.from(new Set(ref_loan_doc_id.split("|")));
      this.state.bckLoanID = Array.from(new Set(ref_loan_doc_id.split("|")));
      console.log(this.state.bckLoanID);
    }

    // this.state.chq_data.map((item) => {
    //   if (item.cheque_number === this.state.selectedChqNo) {
    //     final_chq_no.push(item);
    //   }
    // });
    // console.log(final_chq_no);
    // let loanAssigned = Array.from(new Set(final_chq_no[0].ref_loan_doc_id.split("|")));
    // console.log(loanAssigned);

    // if (this.state.loan_id.length > 0) {
    //   this.state.loan_id.map((lid, index) => {
    //     if (loanAssigned.includes(lid[2])) {
    //       this.state.loan_id[index][1] = 1;
    //     } else {
    //       this.state.loan_id[index][1] = 0;
    //     }
    //   });
    // }

    // console.log(this.state.loan_id);
    this.forceUpdate();
  }

  // AT-1935 | Vaibhav Sawant | 17-08-2023 | Display PDF file using public url
  downloadChequeFile(path) {
    console.log("path", path);
    this.setState({ isSpin: true });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};

    if (path) {
      const formdata = new FormData();
      //formdata.append("bucket_name", path.split("/")[0]);
      //formdata.append("blob_name", path.split("/")[4]);
      formdata.append("blob_url", path);
      let generate_Id = generateId(10);
      const requestOptions = {
        method: "POST",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "Cheque",
          user_id: localStorage.getItem("in_userid"),
        },
        body: formdata,
      };
      customFetch(process.env.REACT_APP_URL + "/get/public/url", requestOptions)
        .then((res) => {
          if (res.api_code === 4) {
            localStorage.clear();
            this.props.history.push("/");
          }
          return res.json();
        })
        .then((img) => {
          //console.log(URL.createObjectURL(img))
          this.setState({
            viewFile: img.public_url,
            isView: true,
            isSpin: false,
          });
        })
        .catch((error) => {
          console.log("error", error)
          this.setState({
            isSpin: false,
          });
        });
    } else {
      this.setState({
        viewFile: "",
        isView: true,
        isSpin: false,
      });
    }
  }

  deleteSavedCheque(item, index) {




    let temp = this.state.selChqEdit.cheque_number.split("-");
    this.state.deletedChq.push(temp.splice(index, 1)[0]);
    this.state.selChqEdit.cheque_number = temp.join("-");

    // this.state.selectedChqNo = temp.join("-");

    // this.state.selChqEdit.cheque_number = temp.splice(index,1).join("|");
    // console.log(this.state.selChqEdit.cheque_number);
    // console.log(this.state.deletedChq);
    this.forceUpdate();
  }


  addPurpose(event) {
    if (event.target.checked) {
      this.state.purpose = event.target.id;
    }
  }

  editPurpose(event) {
    if (event.target.checked) {
      this.state.selChqEdit.purpose = event.target.value;
    }

    console.log(this.state.selChqEdit.purpose);
    this.forceUpdate();
  }

  ImgchangeHandler(event) {
    event.preventDefault();
    this.setState({ isSpin: true });
    const uid = localStorage.getItem("in_userid");
    var file = event.target.files[0];
    console.log(
      "FileNew",
      uid +
      "_" +

      moment().valueOf() +
      "." +
      file.name.split(".").pop()
    );
    this.setState({
      selectedFile: file,
    });

    // console.log(file);
    this.setState({
      isSpin: false,
      message: "File Uploaded!",
      isErrOpen: true,
      chq_upload_filename: file.name,
      fileuploaded: true
    });
    this.forceUpdate();
  }

  handleChequeNumberChange = (e, index) => {
    let updatedChequeNumbers = this.state.selChqEdit.cheque_number.split("-");
    updatedChequeNumbers[index] = e.target.value;

    this.setState({
      selChqEdit: {
        ...this.state.selChqEdit,
        cheque_number: updatedChequeNumbers.join("-"),
      },
      editedIndex: index,
    });
    // console.log("Edited Index!!!!: ", index);
};


updateChequeDetails() {
  this.setState({ isSpin: true });

  let chequeNumbers = this.state.selChqEdit.cheque_number.split("-");
  let chequeIds = this.state.selChqEdit.cheque_ids.split("|");

  console.log("Original Cheque Numbers: ", chequeNumbers);
  console.log("Cheque IDs: ", chequeIds);

  let selectedChequeId = chequeIds;
  console.log("Selected Cheque IDs: ", selectedChequeId);

  const uid = localStorage.getItem("in_userid");
  const { api_token } = (this.props.location && this.props.location.state) || {};

  const formdata = new FormData();
  formdata.append("blob_url", "cheque_collection_by_cso");
  formdata.append("household_id", this.state.og_data.household_id);
  formdata.append("family_member_id", this.state.og_data.account_holder);
  formdata.append("upload_document_type_id", 39);
  formdata.append("userid", uid);
  formdata.append("bank_id", this.state.og_data.bank_id);

  formdata.append("cheque_id", selectedChequeId.join("|"));

  this.state.selectedFile && formdata.append("file", this.state.selectedFile);

  let deletedChequeNumbers = this.state.deletedChq;
  let allChequeNumbers = [...chequeNumbers, ...deletedChequeNumbers];

  let uniqueChequeNumbers = [...new Set(allChequeNumbers)].sort();
  let formattedChequeNumbers = uniqueChequeNumbers.join("|");

  formdata.append("cheque_number", formattedChequeNumbers);

  formdata.append("purpose", this.state.selChqEdit.purpose.split("|")[0]);

  formdata.append("deleted_cheque", this.state.deletedChq || "");

  formdata.append(
    "household_file_id",
    this.state.selChqEdit.household_file_upload_log_id
  );

  let generate_Id = generateId(10);
  customFetch(process.env.REACT_APP_URL + "/update/stored/cheque/data", {
    method: "POST",
    headers: {
      "api-token": api_token,
      component_id: generate_Id,
      apiId: generate_Id,
      api_group_id: "",
      component: this.constructor.name,
      user_id: localStorage.getItem("in_userid"),
    },
    body: formdata,
  })
  .then((res) => res.json())
  .then((json) => {
    console.log("data - ", json);
    if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
      localStorage.clear();
      this.props.history.push("/");
    } else if (json.length === 0) {
      this.setState({ isSpin: false });
    } else {
      if (json.status === 0) {
        this.setState({
          isSpin: false,
          message: json.message,
          isErrOpen: true,
        });
      } else {
        this.setState({
          isSpin: false,
          succmessage: json.message,
          isSuccOpen: true,
        });
        this.forceUpdate();
        // window.location.reload();
      }
    }
  });
}

  saveChequeDetails() {

    // console.log(this.state.seqChqNo);
    // return;

    this.setState({ isSpin: true });

    if (this.state.seqChqNo.length == 0) {
      this.setState({
        isSpin: false,
        message: "Cheque No Cannot be Blank!",
        isErrOpen: true
      });
    } else if (this.state.purpose == "") {
      this.setState({
        isSpin: false,
        message: "Purpose Not Selected!",
        isErrOpen: true
      });
    } else if (this.state.selectedFile == "") {
      this.setState({
        isSpin: false,
        message: "Please Upload Cheque File!",
        isErrOpen: true
      });
    } else {
      console.log(this.state.seqChqNo.join("|"));
      const uid = localStorage.getItem("in_userid");
      const { api_token } =
        (this.props.location && this.props.location.state) || {};

      const formdata = new FormData();
      // formdata.append("blob_url", "/cheque_collection_by_cso");
      formdata.append("household_id", this.state.og_data.household_id);
      formdata.append("family_member_id", this.state.og_data.account_holder);
      formdata.append("upload_document_type_id", 39);
      formdata.append("userid", uid);
      formdata.append("ref_loan_doc_id", "");
      formdata.append("bank_id", this.state.og_data.bank_id);
      formdata.append("file", this.state.selectedFile);
      formdata.append("cheque_number", this.state.seqChqNo.join("|"));
      formdata.append("purpose", this.state.purpose);
      let generate_Id = generateId(10);
      customFetch(process.env.REACT_APP_URL + "/save/stored/cheque/data", {
        method: "POST",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "Cheque",
          user_id: localStorage.getItem("in_userid"),
        },
        body: formdata,
      })
        .then((res) => {
          return res.json();
        }).then((json) => {
          console.log("data - ", json);
          if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
            localStorage.clear();
            this.props.history.push("/");
          } else if (json.length == 0) {
            this.setState({
              isSpin: false,
              // message: "No Data Found!",
              // isErrOpen: true
            });
          } else {

            if (json.status == 0) {
              this.setState({
                isSpin: false,
                message: json.message,
                isErrOpen: true
              });
            } else {
              this.setState({
                isSpin: false,
                succmessage: json.message,
                isSuccOpen: true
              });

            }

          }

        }).catch((error) => console.log("error", error));

    }
  }

  deleteChequeInput(item, index) {

    console.log(item + " " + index);

    if (this.state.seqChqNo.length > 1) {
      this.state.seqChqNo.splice(index, 1);
      this.state.cheque_no.splice(index, 1);
      console.log(this.state.seqChqNo);
      console.log(this.state.cheque_no);
    } else if (this.state.seqChqNo.length == 1) {

      this.state.seqChqNo = [];
      this.setState({
        message: "Cannot Delete!",
        isErrOpen: true
      });
    }

    this.forceUpdate();
  }

  setFirstChqNo(e) {
    var numbers = /^[0-9\b]+$/;

    if (e.target.value.match(numbers)) {
      if (e.target.id == "chq_no_1") {

        if (this.state.seqChqNo.length > 1) {
          console.log("ahdsa");
        } else {
          if (e.target.value.length == 6) {
            if (this.state.firstChq == "") {
              this.state.firstChq = e.target.value;
              this.state.seqChqNo[0] = e.target.value;
              this.state.cheque_no = [1];
            } else {
              this.state.firstChq = e.target.value;
              this.state.seqChqNo[0] = e.target.value;
              this.state.cheque_no = [1];
            }
          }
        }
      } else {
        // AT-1935 | Vaibhav Sawant | 29-08-2023 | Allowing user to add other cheque number even after sequence cheques are added
        let flag = 0;
        this.state.seqChqNo.map((item) => {
          if (item === e.target.value) {
            flag = 1;
          }
        });

        if (flag) {
          this.setState({
            message: "Cheque Already Exists!",
            isErrOpen: true
          });
        } else{
          if (e.target.value.length == 6) {
            this.state.seqChqNo.push(e.target.value);
          }          
        }
          
      }

    } else {
      e.target.value = "";
      this.setState({
        message: "Only Number Allowed!",
        isErrOpen: true
      });
    }





    console.log(this.state.seqChqNo);

  }

  addChqSequence = () => {
    const { editFlag, activeIndex } = this.state;

    if (editFlag) {
        let chequeNumbers = this.state.selChqEdit.cheque_number.split("-");

        let selectedIndex = (activeIndex === undefined || activeIndex === null) ? chequeNumbers.length - 1 : activeIndex;

        let currentChequeNo = chequeNumbers[selectedIndex];

        if (!currentChequeNo || currentChequeNo === "") {
            this.setState({
                message: "Please Enter Cheque No",
                isErrOpen: true,
            });
            return;
        }

        let nextChequeNo = (parseInt(currentChequeNo, 10) + 1).toString().padStart(6, "0");

        chequeNumbers.splice(selectedIndex + 1, 0, nextChequeNo);

        this.setState({
            selChqEdit: {
                ...this.state.selChqEdit,
                cheque_number: chequeNumbers.join("-"),
            },
            message: "",
            isErrOpen: false,
        });

        console.log("Updated Cheque Numbers after Add in Sequence (Edit Mode): ", chequeNumbers);
    } else {
        let chequeNumbers = [...this.state.seqChqNo];

        let selectedIndex = (activeIndex === undefined || activeIndex === null) ? chequeNumbers.length - 1 : activeIndex;

        let currentChequeNo = chequeNumbers[selectedIndex];

        if (!currentChequeNo || currentChequeNo === "") {
            this.setState({
                message: "Please Enter Cheque No",
                isErrOpen: true,
            });
            return;
        }

        let nextChequeNo = (parseInt(currentChequeNo, 10) + 1).toString().padStart(6, "0");

        chequeNumbers.splice(selectedIndex + 1, 0, nextChequeNo);

        this.setState((prevState) => ({
            seqChqNo: chequeNumbers,
            cheque_no: [...prevState.cheque_no, nextChequeNo],
            message: "",
            isErrOpen: false,
        }));

        console.log("Updated Cheque Numbers after Add in Sequence (Save Mode): ", this.state.seqChqNo);
    }
};


addChqInput = () => {
  if (this.state.editFlag) {
    let chequeNumbers = this.state.selChqEdit.cheque_number.split("-");

    chequeNumbers.push("");

    this.setState({
      selChqEdit: {
        ...this.state.selChqEdit,
        cheque_number: chequeNumbers.join("-"),
      },
    });

    console.log("Updated Cheque Numbers after Add (Edit Mode): ", chequeNumbers);
  } else {
    let updatedChequeNo = [...this.state.seqChqNo, ""];
    let currentValue = this.state.cheque_no[this.state.cheque_no.length - 1] + 1;

    this.setState({
      seqChqNo: updatedChequeNo,
      cheque_no: [...this.state.cheque_no, currentValue],
    });

    console.log("Updated Cheque Numbers after Add (Save Mode): ", updatedChequeNo);
  }
};


  componentDidMount() {
    document.title = "Cheque";
    //AT-1659 | AT-1767 | Ankur Makavana | 05-07-2023 | store ui performance data
    setTimeout(() => {
      if (document.readyState === "complete") {
        // If the document is fully loaded, call onPageLoad
        if (this.context && typeof this.context.onPageLoad === 'function') {
          this.context.onPageLoad({
            component: "Cheque",
            component_id: generate_Id1,
          });
        }
      } else {
        // Create a handler function for the event listener
        const handlePageLoad = () => {
          if (this.context && typeof this.context.onPageLoad === 'function') {
            this.context.onPageLoad({
              component: "Cheque",
              component_id: generate_Id1,
            });
          }
        };
        
        // Add the event listener for window load event
        window.addEventListener("load", handlePageLoad);
    
        // Cleanup function to remove the event listener
        this.cleanup = () => {
          window.removeEventListener("load", handlePageLoad);
        };
      }
    }, 1000);
    
    // const { Preferred_pay_date } =
    // (this.props.location && this.props.location.state) || {};
    const uid = localStorage.getItem("in_userid");

    customFetch("https://geolocation-db.com/json/")
      .then((response) => {
        return response.json();
      }, "jsonp")
      .then((res) => {
        // console.log(res);
        this.setState({
          IPv4: res.IPv4,
          latitude: res.latitude,
          longitude: res.longitude,
        });
      })
      .catch((err) => console.log(err));

    // console.log(this.props.location.state);

    this.state.og_data = this.props.location.state && this.props.location.state.acc_data;

    this.state.search_text = this.props.location.state && this.props.location.state.hhno;

    const { api_token } =
      (this.props.location && this.props.location.state) || {};

    // console.log(api_token);

    console.log(this.state.og_data);

    // console.log(this.state.og_data);

    if (this.state.og_data && this.state.og_data.loan_id && this.state.og_data.loan_id.split(",").length > 0) {
      this.state.loan_id = [];
      this.state.og_data.loan_id && this.state.og_data.loan_id.split(",").map((item, index) => {
        let temp = [
          item,
          0,
          this.state.og_data.loan_doc_id.split(",")[index],
          this.state.og_data.ld_status.split(",")[index],
        ];
        this.state.loan_id.push(temp);
      });

    }

    this.fetchChequeDetails(this.state.og_data && this.state.og_data.bank_id);

    // console.log(this.state.og_data);

    // this.state.cheque_no.push("<input type='text' className='form-control' id='cheque_no_1' placeholder='Cheque No' />");

    // console.log(this.state.og_data);

    // console.log("Superman ID: " + uid);
    // if (!uid) {
    //   localStorage.clear();
    //   this.props.history.push("/");
    // }
    // if (Preferred_pay_date !== "" || Preferred_pay_date !== null) {
    //   this.setState({
    //     preferred_date: moment(Preferred_pay_date).format("YYYY-MM-DD"),
    //   });
    // }
    // this.setState({
    //   'currentDate': this.getDate(new Date(Date.now()))
    // });
  }

  fetchChequeDetails(bank_id) {

    console.log(bank_id);
    this.setState({ isSpin: true });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};

    // AT-1935 | Vaibhav Sawant | 19-07-2023 | Method Changed to GET
    const params = {
      "bank_id": bank_id
    }
    const queryParams = new URLSearchParams(params).toString();
    let generate_Id = generateId(10);
    customFetch(
      process.env.REACT_APP_URL + "/get/stored/check/details?" + queryParams,
      {
        method: "GET",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "Cheque",
          user_id: localStorage.getItem("in_userid"),
        },
      }
    )
      .then((res) => {
        return res.json();
      }).then((json) => {
        console.log("data - ", json);
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        } else if (json.length == 0) {
          this.setState({
            isSpin: false,
            // message: "No Data Found!",
            // isErrOpen: true
          });
        } else {
          this.setState({
            isSpin: false,
            chq_data: json
          });
        }

      }).catch((error) => console.log("error", error));
  }

  handleSearchInput = (e) => {
    this.setState({ search_text: e.target.value.toUpperCase() });
    console.log(this.state.search_text);
    if (this.state.search_text == "") {
      this.state.utrdata = this.state.og_data;
    }
    this.forceUpdate();


  };



  onSearch = () => {

    console.log(this.state.search_text);

    this.state.og_data = [];

    this.setState({ isSpin: true });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};

    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    const params = {
      household_number: this.state.search_text,
    };
    const queryParams = new URLSearchParams(params).toString();
    let generate_Id = generateId(10);
    customFetch(
      process.env.REACT_APP_URL + "/get/household/bank/details?" + queryParams,
      {
        method: "GET",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "Cheque",
          user_id: localStorage.getItem("in_userid"),
        },
      }
    )
      .then((res) => {
        return res.json();
      }).then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isSpin: false,
          og_data: json
        });
      }).catch((error) => console.log("error", error));

  };

  render() {

    const { og_data, isErrOpen, message, cheque_no, chq_data, messageNew, isErrOpenNew, viewFile, isView, succmessage, isSuccOpen } = this.state;
    // console.log(chq_data);
    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}

        <div
          className={
            isView
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isView: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>

              {viewFile ? (
                <>
                  <iframe className="pdfviewfile" src={viewFile}></iframe>
                  {/* <div className="popupdownload-btn">
                    <a
                      href={viewFile}
                      download
                      className="btn btn-primary popupdownload"
                    >
                      Download
                    </a>
                  </div> */}
                </>
              ) : (
                "File Not Found"
              )}
            </div>
          </div>
        </div>

        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{message}</p>
            </div>
          </div>
        </div>

        <div
          className={
            isSuccOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => window.location.reload()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{succmessage}</p>
            </div>
          </div>
        </div>

        <div
          className={
            isErrOpenNew
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpenNew: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{messageNew}</p>
            </div>
          </div>
        </div>

        <div
          className={this.state.loanModal ? "modal fade bd-example-modal-sm show mymodal"
            : "modal fade bd-example-modal-sm"}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="loan-modal"
          ref={this.sortModal}
        >
          <div className="modal-dialog">
            <div className="modal-content sort_block">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ loanModal: false, unLoanID: [], tempLoanID: [] })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <span className="bluetext-header-sort">Loan ID</span>
              <table className="table">
                <tbody>
                  {
                    (this.state.loan_id.length > 0) ?
                      this.state.loan_id.map((item, index) => {
                        const partnerNames = this.state.og_data.partner_name.split(",");
                        return (
                          <tr key={item[0]}>
                            <td>{item[0]} | {partnerNames[index].trim()}</td>
                            <td>
                              <input
                                type="radio"  // Changed from checkbox to radio
                                className="form-check-input"
                                value={item[2]}
                                name="loan_id"  // Radio buttons share the same name to allow single selection
                                checked={this.state.selectedLoanID === item[2]}  // Only one can be selected at a time
                                onChange={this.handleLoanID}
                              />
                            </td>
                          </tr>
                        );
                      })
                      : "No Loan ID"
                  }
                  {this.state.og_data && this.state.og_data.hbd_status == "S" &&
                    this.state.og_data.loan_doc == null ? (
                    <tr>
                      <td colSpan={2}>
                        <button
                          className="btn btn-info btn-block"
                          onClick={() =>
                            this.assignLoanID(this.state.selPurposeForSave)
                          }
                        >
                          Save
                        </button>
                      </td>
                    </tr>
                  ) : ""}
                </tbody>
              </table>
              {/* <button
                data-dismiss={(this.state.loanModal) ? "modal" : ""}
                type="button"
                className="btn btn-primary btn-lg btn-block rbtn search_elements"
                onClick={this.assignLoanID}
              >
                Save
              </button> */}
            </div>
          </div>
        </div>

        <section className="main-sec">
          <div className="container fix-width">
            <div className="">
              <div className="card-body">
                <div className="transaction-head-area">
                  <div
                    className="trans_back_icon_bg"
                    // onClick={() => this.props.history.goBack()}
                    onClick={() => {
                      this.props.history.push({
                        pathname: "/cheque_upload",
                        state: {
                          hhno: this.state.search_text,
                          api_token: this.props.location.state.api_token
                        },
                      });
                    }}
                  >
                    <img
                      src={require("../assets/images/back.png")}
                      className="back_icon_img"
                      alt=""
                    />
                  </div>
                  Cheque
                  <div className="log-out-history">
                    <img
                      src={require("../assets/images/power-off.png")}
                      onClick={() => logout()}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>





        <div className="call_detail container d-flex flex-column align-items-center">


          <br />
          <div className="cq-blue-box">
            <div className="d-flex">
              <div className="cq-profile-pic">
                <img src={require("../assets/images/profile.png")} />
              </div>

              <div className="cq-name-details">
                <PESpan element_id="ChequeHHName">{og_data && og_data.name_as_per_account}</PESpan>
                <PESpan element_id="ChequePipeone">|</PESpan>
                <PESpan element_id="ChequeSearchText">{this.state.search_text}</PESpan>
                <PESpan element_id="ChequePipetwo">|</PESpan>
                <PESpan element_id="ChequeMobile">{og_data && og_data.mobile_no}</PESpan>
              </div>
              <div className="cq-name-details-mob">
                <span>{og_data && og_data.name_as_per_account}</span>
                <br />
                {/* <span>|</span> */}
                <span>{this.state.search_text}</span><br />
                {/* <span>|</span> */}
                <span>{og_data && og_data.mobile_no}</span>
              </div>
            </div>
            <br />

            {
              (chq_data.length > 0) ?

                (
                  chq_data.map((item) => {
                    console.log("STATES....", item)
                    return (
                      <div className="chq_details_loan_block"  key={item.cheque_number}>
                        <div className="chq_det_loan_block_inner">
                          <div className="save_chq_details_block">
                            <div className="saved_chq_det_block">
                              <div className="row"><span className="pull-left"><b>{og_data.bank_name + "  |  A/c No. " + og_data.account_number}</b></span></div>
                              <div className="row"><span className="pull-left"><b>{this.getPartnerName(item.ref_loan_doc_id)}</b></span></div>
                              <div className="row"><span className="chq-number-block">{item.cheque_number}</span></div>
                              <div className="row"><span className="downloadLink" onClick={() => this.downloadChequeFile(item.filename)}>{og_data.name_as_per_account + " " + og_data.bank_name + " cheques"}</span></div>
                            </div>
                            <div className="saved_chq_purpose_block_mob">
                              {
                                (this.state.og_data.hbd_status == 'S' && this.state.og_data.loan_doc == null) ?
                                  <>
                                    <div>
                                      <select id="editDelete" onChange={this.editDeleteChequeDetails} onFocus={() => this.setChqData(item)}>
                                        <option value="">Select</option>
                                        <option value="1">Edit</option>
                                        <option value="2">Delete</option>
                                      </select>
                                    </div><br /></>
                                  : ""
                              }
                              <button className="btn btn-success btn-block">{(item.purpose.includes("S")) ? "Security" : item.purpose.includes("B") ? "BPI" : (item.purpose.includes("I")) ? "Installment" : ""}</button>
                              <div className="row"><span className="downloadLink" onClick={() => this.downloadChequeFile(item.filename)}>{og_data.name_as_per_account + " " + og_data.bank_name + " cheques"}</span></div>
                            </div>

                            <div className="saved_chq_purpose_block">
                              <button className="btn btn-success btn-block">{(item.purpose.includes("S")) ? "Security" : item.purpose.includes("B") ? "BPI"  : (item.purpose.includes("I")) ? "Installment" : ""}</button>
                            </div>
                            {
                              (this.state.og_data.hbd_status == 'S' && this.state.og_data.loan_doc == null) ?
                                <div className="editDeleteSelect">
                                  <select id="editDelete" onChange={this.editDeleteChequeDetails} onFocus={() => this.setChqData(item)}>
                                    <option value="">Select</option>
                                    <option value="1">Edit</option>
                                    <option value="2">Delete</option>
                                  </select>
                                </div>
                                : ""
                            }

                          </div>
                          <div className="hh_loan_id_block">
                            {
                              (this.state.loan_id.length > 0) ?

                                this.state.loan_id.map((lid, index) => {

                                  if (item.ref_loan_doc_id != "") {
                                    if (item.ref_loan_doc_id.includes(lid[2])) {
                                      return (<div className="sel_loan_ids">{lid[0]}</div>)
                                    }
                                  } else {
                                    return ("")
                                  }

                                })

                                : ""
                            }

                            {
                              // (this.state.og_data.hbd_status == 'S' && this.state.og_data.loan_doc == null) ? <button className="btn btn-success assign-btn" data-toggle="modal" data-target="#loan-modal" onClick={() => this.loadLoanID(item.cheque_number, item.purpose)}>Assign Loan A/C</button> : ""

                              (this.state.og_data.hbd_status == 'S' && this.state.og_data.loan_doc == null) ? <button className="btn btn-success assign-btn" onClick={() => this.loadLoanID(item.cheque_number, item.purpose, item.ref_loan_doc_id)}>Assign Loan A/C</button> : ""
                            }

                          </div>

                        </div>



                      </div>)
                  })

                )

                : ""
            }
            <br />
            <div className="cq-upload-chq">

              <div className="hh_loan_id_block_mob">
                {this.state.editFlag ? (
                  <div className="row">
                    <a
                      href="#"
                      onClick={() =>
                        this.setState({
                          viewFile: "",
                          isView: true,
                        })
                      }
                    >
                    {console.log(og_data + ".............")}
                      {og_data.name_as_per_account +
                        " " +
                        og_data.bank_name +
                        " cheques"}
                    </a>
                  </div>
                ) : (
                  ""
                )}
                {this.state.fileuploaded ? (
                  <>
                    <div className="uploaded-chq-name">
                      {this.state.chq_upload_filename}
                    </div>
                    <br />
                  </>)
                    : ""
                }
                <div className="cq-chq-upload-box" onClick={() => {
                  // this.['upload'+item.cash_balance_id].click();
                  this["upload"].click();
                }}>
                  Upload Cheques<br />(should be in PDF format)

                </div>
                <input
                  type="file"
                  name="file"
                  accept=".pdf, .PDF"
                  onChange={(event) =>
                    this.ImgchangeHandler(
                      event
                      // item.cash_balance_id,
                      // item.amt_submitted
                    )
                  }
                  //ref={(ref) => (this.upload = ref)}
                  ref={(ref) => {
                    this["upload"] = ref;
                  }}
                  style={{ display: "none" }}
                />

              </div>

              <div className="save_cheque_details_mob">
                <div className="chq_add_header_mob">
                  <div>
                    Bank A/C <br />
                    <b>{og_data && og_data.bank_name + " - " + og_data.account_number}</b>
                  </div>
                  <div className="chq_add_block_mob">
                    <div>
                      {this.state.editFlag ? (
                        <div className="cq-chq-details-block">
                          <div className="chq-no-block">
                            <div class="form-group">
                              <label
                                for="exampleFormControlInput1"
                                className="pull-left"
                              >
                                Cheque No.
                              </label>
                              {this.state.selChqEdit.cheque_number
                                .split("-")
                                .map((item, index) => {
                                  console.log('this.state.selChqEdit.cheque_number', this.state.selChqEdit.cheque_number)
                                  return (
                                    <>
                                      <div class="input-group mb-3" key={index}>
                                        <input
                                          type="text"
                                          class="form-control chq-inputs"
                                          aria-label="Recipient's username"
                                          id={"chq_no_" + item}
                                          placeholder="Cheque No"
                                          onChange={this.setFirstChqNo}
                                          value={item}
                                          aria-describedby="button-addon2"
                                          maxLength={6}
                                        />
                                        <button
                                          class="btn btn-danger chq-inputs"
                                          type="button"
                                          id="button-addon2"
                                          onClick={() =>
                                            this.deleteSavedCheque(item, index)
                                          }
                                        >
                                          X
                                        </button>
                                      </div>
                                      {/* <input type='text' className='form-control ' id={'chq_no_' + item} placeholder='Cheque No' onChange={this.setFirstChqNo} value={this.state.seqChqNo[index]} /> <a onClick={this.deleteChequeInput(item, index)}>X</a> */}
                                    </>
                                  );
                                })}
                            </div>

                              {/* <div>
                            <button type="button" class="btn btn-primary" onClick={this.addChqSequence}>
                              Add in Sequence
                            </button>&nbsp;
                            <button type="button" class="btn btn-default" onClick={this.addChqInput}>
                              Add
                            </button>
                          </div> */}

                            </div>
                            <div className="purpose-block">
                              <h6>Purpose</h6>

                              <div class="form-check-inline">
                                {/* <label for="exampleFormControlInput1">Purpose</label> */}
                                <label class="form-check-label" for="radio1">
                                  <input type="radio" class="form-check-input" id="S" name="optradio" value="S" onChange={this.editPurpose} checked={(this.state.selChqEdit.purpose.includes("S")) ? true : false} />Security
                                </label>
                              </div>
                              {/* <div class="form-check-inline">
                                <label class="form-check-label" for="radio2">
                                  <input type="radio" class="form-check-input" id="B" name="optradio" value="B" onChange={this.editPurpose} checked={(this.state.selChqEdit.purpose.includes("B")) ? true : false} />BPI
                                </label>
                              </div> */}
                              <div class="form-check-inline">
                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" id="I" name="optradio" value="I" onChange={this.editPurpose} checked={(this.state.selChqEdit.purpose.includes("I")) ? true : false} />Installment
                                </label>
                              </div>
                            </div>
                          </div>) : <div className="cq-chq-details-block">
                            <div className="chq-no-block">
                              <div class="form-group">
                                <label for="exampleFormControlInput1" className="pull-left">Cheque No.</label>

                                {
                                  cheque_no.map((item, index) => {
                                    return (
                                      <>
                                        <div class="input-group mb-3">
                                          <input type="text" class="form-control chq-inputs" aria-label="Recipient's username" id={'chq_no_' + item} placeholder='Cheque No' onChange={this.setFirstChqNo} value={this.state.seqChqNo[index]} aria-describedby="button-addon2" maxLength={6} />
                                          <button class="btn btn-danger chq-inputs" type="button" id="button-addon2" onClick={() => this.deleteChequeInput(item, index)}>X</button>
                                        </div>
                                        {/* <input type='text' className='form-control ' id={'chq_no_' + item} placeholder='Cheque No' onChange={this.setFirstChqNo} value={this.state.seqChqNo[index]} /> <a onClick={this.deleteChequeInput(item, index)}>X</a> */}
                                      </>
                                    )
                                  })
                                }
                              </div>


                              <div>
                                <button type="button" class="btn btn-primary" onClick={this.addChqSequence}>
                                  Add in Sequence
                                </button>&nbsp;
                                <button type="button" class="btn btn-default" onClick={this.addChqInput}>
                                  Add
                                </button>
                              </div>

                            </div>
                            <div className="purpose-block">
                              <h6>Purpose</h6>

                              <div class="form-check-inline">
                                {/* <label for="exampleFormControlInput1">Purpose</label> */}
                                <label class="form-check-label" for="radio1">
                                  <input type="radio" class="form-check-input" id="S" name="optradio" value="S" onChange={this.addPurpose} />Security
                                </label>
                              </div>
                              {/* <div class="form-check-inline">
                                <label class="form-check-label" for="radio2">
                                  <input type="radio" class="form-check-input" id="B" name="optradio" value="B" onChange={this.addPurpose} />BPI
                                </label>
                              </div> */}
                              <div class="form-check-inline">
                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" id="I" name="optradio" value="I" onChange={this.addPurpose} />Installment
                                </label>
                              </div>
                            </div>
                          </div>
                      }

                      <br />
                      <button className="btn btn-block btn-lg btn-success" onClick={(this.state.editFlag) ? this.updateChequeDetails : this.saveChequeDetails}>{((this.state.editFlag) ? "Update" : "Save")}</button>
                    </div>

                  </div>
                </div>
              </div>



              <div className="cheque_upload_details">
                {
                  (this.state.editFlag) ? <div className="row"><a href="#" onClick={() => this.downloadChequeFile(this.state.selChqEdit.filename)}>{og_data.name_as_per_account + " " + og_data.bank_name + " cheques"}</a></div> : ""
                }

                {
                  (this.state.fileuploaded) ? <>
                    <div className="uploaded-chq-name">
                      {this.state.chq_upload_filename}
                    </div>
                    <br />
                  </>
                    : ""
                }

                <div className="cq-chq-upload-box" onClick={() => {
                  // this.['upload'+item.cash_balance_id].click();
                  this["upload"].click();
                }}>
                  Upload Cheques<br />(should be in PDF format)

                </div>
                <input
                  type="file"
                  name="file"
                  accept=".pdf, .PDF"
                  onChange={(event) =>
                    this.ImgchangeHandler(
                      event
                      // item.cash_balance_id,
                      // item.amt_submitted
                    )
                  }
                  //ref={(ref) => (this.upload = ref)}
                  ref={(ref) => {
                    this["upload"] = ref;
                  }}
                  style={{ display: "none" }}
                />

              </div>
              <div className="cq-vertical-line"></div>
              <div className="cq-bank-acc-details">
                <div className="d-flex flex-column">
                  <div>
                    Bank A/C <br />
                    <b>{og_data && og_data.bank_name + " - " + og_data.account_number}</b>
                  </div>
                  {this.state.editFlag ? (
                    <div className="cq-chq-details-block">
                      <div className="chq-no-block">
                        <div class="form-group">
                          <label
                            for="exampleFormControlInput1"
                            className="pull-left"
                          >
                            Cheque No.
                          </label>
                          {this.state.selChqEdit.cheque_number
                            .split("-")
                            .map((item, index) => {
                              return (
                                <>
                                  <div class="input-group mb-3" key={index}>
                                    <input
                                      type="text"
                                      class="form-control chq-inputs"
                                      aria-label="Recipient's username"
                                      id={"chq_no_" + item}
                                      placeholder="Cheque No"
                                      onChange={(e) => {
                                            this.handleChequeNumberChange(e, index)
                                            if(this.state.editFlag === true) {
                                            let arr = this.state.selChqEdit.cheque_number.split("-")
                                            arr[index] = e.target.value
                                            let arrStr = arr.join('-')
                                            this.setState({
                                              selChqEdit: {
                                                ...this.state.selChqEdit,
                                                cheque_number : arrStr}
                                            })
                                            console.log('this.state.selChqEdit.cheque_number', this.state.selChqEdit.cheque_number)
                                          }
                                            this.setFirstChqNo(e, index)
                                          }}
                                          onFocus={() => this.setState({ activeIndex: index })}
                                      value={item}
                                      aria-describedby="button-addon2"
                                      maxLength={6}
                                    />
                                    <button
                                      class="btn btn-danger chq-inputs"
                                      type="button"
                                      id="button-addon2"
                                      onClick={() =>
                                        this.deleteSavedCheque(item, index)
                                      }
                                    >
                                      X
                                    </button>
                                  </div>
                                  {/* <input type='text' className='form-control ' id={'chq_no_' + item} placeholder='Cheque No' onChange={this.setFirstChqNo} value={this.state.seqChqNo[index]} /> <a onClick={this.deleteChequeInput(item, index)}>X</a> */}
                                </>
                              );
                            })}
                        </div>
                        
 
                        <div>
                            <button type="button" class="btn btn-primary" onClick={this.addChqSequence}>
                              Add in Sequence
                            </button>&nbsp;
                            <button type="button" class="btn btn-default" onClick={this.addChqInput}>
                              Add
                            </button>
                          </div>
                      </div>
                      <div className="purpose-block">
                        <h6>Purpose</h6>

                        <div class="form-check-inline">
                          {/* <label for="exampleFormControlInput1">Purpose</label> */}
                          <label class="form-check-label" for="radio1">
                            <input
                              type="radio"
                              class="form-check-input"
                              id="S"
                              name="optradio"
                              value="S"
                              onChange={this.editPurpose}
                              checked={
                                this.state.selChqEdit.purpose.includes("S")
                                  ? true
                                  : false
                              }
                            />
                            Security
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="form-check-label" for="radio2">
                            <input
                              type="radio"
                              class="form-check-input"
                              id="B"
                              name="optradio"
                              value="B"
                              onChange={this.editPurpose}
                              checked={
                                this.state.selChqEdit.purpose.includes("B")
                                  ? true
                                  : false
                              }
                            />
                            BPI
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input
                              type="radio"
                              class="form-check-input"
                              id="I"
                              name="optradio"
                              value="I"
                              onChange={this.editPurpose}
                              checked={
                                this.state.selChqEdit.purpose.includes("I")
                                  ? true
                                  : false
                              }
                            />
                            Installment
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="cq-chq-details-block">
                      <div className="chq-no-block">
                        <div class="form-group">
                          <label
                            for="exampleFormControlInput1"
                            className="pull-left"
                          >
                            Cheque No.
                          </label>

                          {cheque_no.map((item, index) => (
                            <div class="input-group mb-3" key={index}>
                              <input
                                type="text"
                                class="form-control chq-inputs"
                                aria-label="Cheque No"
                                id={"chq_no_" + item}
                                placeholder="Cheque No"
                                onChange={(e) => {
                                  let updatedSeqChqNo = [...this.state.seqChqNo];
                                  updatedSeqChqNo[index] = e.target.value;

                                  // Update the state to make the input editable
                                  this.setState({
                                    seqChqNo: updatedSeqChqNo,
                                  });
                                }}
                                value={this.state.seqChqNo[index]}
                                aria-describedby="button-addon2"
                                maxLength={6}
                              />
                              <button
                                class="btn btn-danger chq-inputs"
                                type="button"
                                onClick={() => this.deleteChequeInput(item, index)}
                              >
                                X
                              </button>
                            </div>
                          ))}
                        </div>

                          <div>
                            <button type="button" class="btn btn-primary" onClick={this.addChqSequence}>
                              Add in Sequence
                            </button>&nbsp;
                            <button type="button" class="btn btn-default" onClick={this.addChqInput}>
                              Add
                            </button>
                          </div>

                        </div>
                        <div className="purpose-block">
                          <h6>Purpose</h6>

                          <div class="form-check-inline">
                            {/* <label for="exampleFormControlInput1">Purpose</label> */}
                            <label class="form-check-label" for="radio1">
                              <input type="radio" class="form-check-input" id="S" name="optradio" value="S" onChange={this.addPurpose} />Security
                            </label>
                          </div>
                          <div class="form-check-inline">
                            <label class="form-check-label" for="radio2">
                              <input type="radio" class="form-check-input" id="B" name="optradio" value="B" onChange={this.addPurpose} />BPI
                            </label>
                          </div>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" class="form-check-input" id="I" name="optradio" value="I" onChange={this.addPurpose} />Installment
                            </label>
                          </div>
                        </div>
                      </div>
                  )}

                  <br />
                  <button className="btn btn-block btn-lg btn-success" onClick={(this.state.editFlag) ? this.updateChequeDetails : this.saveChequeDetails}>{((this.state.editFlag) ? "Update" : "Save")}</button>
                </div>



              </div>
            </div>
          </div>

        </div>
      </>
    );
  }
}