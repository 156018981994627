import React from "react";
import customFetch from "./apicall/api";
import { logout, consolidate, filterData } from "./Utility";
import moment from "moment";
import {
  DatePicker,
  Switch,
  Table,
  Checkbox,
  Button,
  Typography,
  Input,
} from "antd";
import { ExportTableButton } from "ant-table-extensions";
import {
  FileExcelOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";

const { Text } = Typography;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const sdate = moment().subtract(1, "days");
const edate = moment().subtract(2, "days");
const tableProps = {
  bordered: true,
  size: "large",
  showHeader: true,
};
const formatter = new Intl.NumberFormat("en-IN");

const e_designation_id = localStorage.getItem("e_designation_id");
class Cash_balance_report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ReportData: [],
      ReportData_bkp: [],
      sort_data: [],
      sort_data_bkp: [],
      temp_array: [],
      isSetting: false,
      isUpdate: false,
      isUpdMessage: "",
      csotofilterdata: "All",
      wsconnectstatus: 0,
      isFilterByType: "",
      isEntity: "",
      //datasource: [],
      checkedboxes: [],
      sort_data_forsum: [],
      defaultCols: [
        { key: "branch_name", title: "Branch Name", dataIndex: "branch_name" },
        { key: "username", title: "Username", dataIndex: "username" },
        { key: "usertype", title: "Usertype", dataIndex: "usertype" },
        { key: "collected", title: "Collected", dataIndex: "collected" },
        { key: "deposited", title: "Deposited", dataIndex: "deposited" },
        {
          key: "opening_balance",
          title: "Opening Balance",
          dataIndex: "opening_balance",
        },
        {
          key: "closing_balance",
          title: "Closing Balance",
          dataIndex: "closing_balance",
        },
        {
          key: "fcm_approval_pending",
          title: "Fcm Approval Pending",
          dataIndex: "fcm_approval_pending",
        },
        {
          key: "finops_approval_pending",
          title: "Finops Approval Pending",
          dataIndex: "finops_approval_pending",
        },
        {
          key: "fcm_approved_amount",
          title: "Fcm Approved Amount",
          dataIndex: "fcm_approved_amount",
        },
        { key: "as_on_dt", title: "Date", dataIndex: "as_on_dt" },
      ],
      isFilterByTypeuser: "",
      selsdate: "",
      seledate: "",
    };
  }

  componentDidMount() {
    //console.log("new data");
    // socket.addEventListener("open", (event) => {
    //   alert("Error: ", event);
    // });
    // e_designation_id == process.env.REACT_APP_effective_designation_id_to
    //   ? this.setState({ csotofilterdata: false })
    //   : this.setState({ csotofilterdata: true });

    this.getpreferences();
  }
  getpreferences = () => {
    if (!this.state.isloaded || this.state.isloaded === "") {
      // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
      //const formdata = new FormData();
      const { api_token } =
        (this.props.location && this.props.location.state) || {};
      const uid = localStorage.getItem("in_userid");

      const params = {
        "userid": uid,
        "report_id": "1"
      }
      const queryParams = new URLSearchParams(params).toString();

      const requestOptions = {
        method: "GET",
        headers: {
          "api-token": api_token,
        }
      };
      customFetch(
        process.env.REACT_APP_URL +
          "/fetch/column/preferences?userid=" +
          uid +
          "&report_id=1",
        requestOptions
      )
        .then((response) => {
          if (response.headers.get("api_code") > 0) {
            this.setState({
              isErrOpen: true,
              api_error_message: response.headers.get("api_error_message"),
              isSpin: false,
            });
          }
          return response.json();
        })
        .then((data) => {
          if (
            data.api_code === 4 ||
            data.api_code === 3 ||
            data.api_code === 5
          ) {
            localStorage.clear();
            this.props.history.push("/");
          }
          if (data[0].preferences) {
            this.setState(
              {
                baseColumns1: data[0].preferences,
              },
              () => {
                this.defaultCheckedBox();
              }
            );
          } else {
            this.setState(
              {
                baseColumns1: this.state.defaultCols,
              },
              () => {
                this.defaultCheckedBox();
              }
            );
          }
        })
        .catch((error) => console.log("error", error));
      this.setState({
        isloaded: "1",
      });
    }
  };
  defaultCheckedBox() {
    let _checkedboxesrowdata = [];
    this.state.baseColumns1 &&
      this.state.baseColumns1.map((cols) => {
        _checkedboxesrowdata.push(cols.dataIndex);
      });
    this.setState({
      checkedboxes: _checkedboxesrowdata,
    });
  }
  updateTable = () => {
    this.state.uniqueUser = [];
    let _uniqueUser = this.state.uniqueUser;
    let inputdata =
      this.state.sort_data_userfilter &&
      this.state.sort_data_userfilter.length > 0
        ? this.state.sort_data_userfilter
        : this.state.sort_data_entdetail && this.state.sort_data_entdetail;
    if (inputdata) {
      inputdata.map((user) => {
        if (this.state.uniqueUser.indexOf(user.username) === -1) {
          _uniqueUser.push(user.username);
        }
      });
    }

    let _baseColumnsrowdata = [];
    let _baseColumns = [];

    this.setState({ sort_data_forsum: this.state.sort_data });

    // let searchedProducts = this.state.sort_data.map((product) => {
    //   const deposited = formatter.format(product.deposited);
    //   const collected = formatter.format(product.collected);
    //   const opening_balance = formatter.format(product.opening_balance);
    //   const closing_balance = formatter.format(product.closing_balance);
    //   const fcm_approval_pending = formatter.format(
    //     product.fcm_approval_pending
    //   );
    //   const finops_approval_pending = formatter.format(
    //     product.finops_approval_pending
    //   );
    //   const fcm_approved_amount = formatter.format(product.fcm_approved_amount);
    //   return {
    //     ...product,
    //     deposited,
    //     collected,
    //     opening_balance,
    //     closing_balance,
    //     fcm_approval_pending,
    //     finops_approval_pending,
    //     fcm_approved_amount,
    //   };
    // });
    // this.setState(
    //   {
    //     sort_data: searchedProducts,
    //   },
    //   () => {
    //     console.log("searchedproducts", this.state.sort_data);
    //   }
    // );
    //console.log("searchedProducts", searchedProducts);
    const labelvalue =
      this.state.wsdata &&
      this.state.wsdata.map((item) => {
        const pairs = Object.entries(item).map(([key, value]) =>
          _baseColumnsrowdata.push({
            dataIndex: key,
            key: key,
            title: value.lable,
          })
        );
      });
    _baseColumns = [...new Set(_baseColumnsrowdata.map(JSON.stringify))].map(
      JSON.parse
    );

    this.setState(
      {
        baseColumns: _baseColumns,
      }
      // ,
      // function () {
      //   this.defaultCheckedBox();
      // }
    );
    this.createfilter();
  };
  // defaultfilter() {
  //   if (this.state.sort_data.length === 0) {
  //     this.createfilter();
  //   }
  // }
  createfilter() {
    //console.log("Create Filter");
    this.state.uniqueZone = [];
    let _uniqueZone = this.state.uniqueZone;

    this.state.sort_data_bkp.map((zone) => {
      if (this.state.uniqueZone.indexOf(zone.zone) === -1) {
        _uniqueZone.push(zone.zone);
      }
    });

    this.state.uniqueDivision = [];
    let _uniqueDivision = this.state.uniqueDivision;
    this.state.sort_data_bkp.map((division) => {
      if (this.state.uniqueDivision.indexOf(division.division) === -1) {
        _uniqueDivision.push(division.division);
      }
    });

    this.state.uniqueCluster = [];
    let _uniqueCluster = this.state.uniqueCluster;
    this.state.sort_data_bkp.map((cluster) => {
      if (this.state.uniqueCluster.indexOf(cluster.cluster_name) === -1) {
        _uniqueCluster.push(cluster.cluster_name);
      }
    });

    this.state.uniqueBranch = [];
    let _uniqueBranch = this.state.uniqueBranch;
    this.state.sort_data_bkp.map((branch) => {
      if (this.state.uniqueBranch.indexOf(branch.branch_name) === -1) {
        _uniqueBranch.push(branch.branch_name);
      }
    });
    //console.log("sort_data_bkp", this.state.sort_data_bkp);
    //this.csotofilter();
  }
  chkfilterdate() {
    console.log("this.state.seltype1", this.state.isFilterByType);
    if (this.state.isFilterByTypeuser) {
      this.filteruser();
    } else if (this.state.csotofilterdata) {
      console.log("csotofilterdata");
      this.csotofilter();
    } else if (this.state.isFilterByType) {
      console.log("chkfilterdate seltype");
      this.filterdatabyType();
    } else if (this.state.SelEnt) {
      console.log("chkfilterdate selent");
      this.setState(
        {
          sort_data: consolidate(this.state.sort_data_bkp, this.state.SelEnt),
          sort_data_ent: consolidate(
            this.state.sort_data_bkp,
            this.state.SelEnt
          ),
        },
        () => {
          console.log("selentdata", this.state.sort_data);
        }
      );
    }
    this.updateTable();
  }
  csotofilter(e) {
    //console.log("user type:", e);
    if (e) {
      this.setState({ csotofilterdata: e, valdata: "" });
    }

    this.state.filterArray1 = [];
    let _filterArray1 = this.state.filterArray1;

    this.state.datefilter = [];
    let _usertypefilter = {
      usertype: [],
    };
    let userselect = e ? e : this.state.csotofilterdata;
    if (userselect !== "All") {
      _usertypefilter = {
        usertype: [userselect],
      };
    }
    let keysWithMinMax = [];
    let inputdata =
      this.state.sort_data_entdetail &&
      this.state.sort_data_entdetail.length > 0
        ? this.state.sort_data_entdetail
        : this.state.sort_data_bkp;
    //console.log("inputdata", this.state.sort_data_entdetail);
    _filterArray1 = filterData(inputdata, _usertypefilter, keysWithMinMax);
    this.setState(
      {
        sort_data: _filterArray1,
        sort_data_userfilter: _filterArray1,
      },
      () => {
        console.log("csotofilter", this.state.sort_data);
        this.updateTable();
      }
    );

    //this.forceUpdate();
  }
  filteruser(e, valdata, seltypedata) {
    if (valdata && seltypedata) {
      this.setState({
        isFilterByTypeuser: seltypedata,
        valdata: valdata,
      });
    }
    //e.preventDefault();
    let seltype = seltypedata ? seltypedata : this.state.isFilterByTypeuser;
    let val = valdata ? valdata : this.state.valdata;
    console.log("val", val);
    if (seltype === "user" && val !== "") {
      //this.state.datefilter = [];
      let _filterArray = this.state.filterArray;
      let _user_namefilter = {
        username: [val],
      };
      let keysWithMinMax = [];
      let inputdata =
        this.state.sort_data_nouser && this.state.sort_data_nouser != ""
          ? this.state.sort_data_nouser
          : this.state.sort_data_bkp;
      //console.log("inputdata", inputdata);
      _filterArray = filterData(inputdata, _user_namefilter, keysWithMinMax);
      console.log("_filterArray", _filterArray);
      this.setState(
        {
          sort_data: _filterArray,
          //sort_data_tocso: _filterArray,
        },
        () => {
          console.log("filter user", this.state.sort_data);
          this.updateTable();
        }
      );
    } else {
      this.setState(
        {
          //sort_data: this.state.sort_data_ent,
          sort_data: this.state.sort_data_userfilter,
        },
        () => {
          console.log("else filter user", this.state.sort_data);
          this.updateTable();
        }
      );
    }
  }
  filterdatabyType(e, valdata, seltypedata) {
    if (valdata && seltypedata) {
      console.log("chageseltype", seltypedata);
      console.log("changeval", valdata);
      this.setState({
        isFilterByType: valdata,
        seltype: seltypedata,
        csotofilterdata: "",
      });
    }
    //e.preventDefault();
    let seltype = seltypedata ? seltypedata : this.state.seltype;
    let val = valdata ? valdata : this.state.isFilterByType;
    console.log("valdata", val);
    console.log("seltypedata", seltype);
    this.state.filterArray = [];
    let _filterArray = this.state.filterArray;
    if (val) {
      if (seltype === "zone") {
        this.state.datefilter = [];
        let _zonefilter = {
          zone: [val],
        };
        let keysWithMinMax = [];
        _filterArray = filterData(
          this.state.sort_data_bkp,
          _zonefilter,
          keysWithMinMax
        );
        this.setState(
          {
            sort_data: _filterArray,
            sort_data_tocso: _filterArray,
            sort_data_nouser: _filterArray,
            sort_data_entdetail: _filterArray,
          },
          () => {
            console.log("zone filter data", this.state.sort_data);
            this.updateTable();
          }
        );
      }
      if (seltype === "division") {
        this.state.datefilter = [];
        let _divisionfilter = {
          division: [val],
        };
        let keysWithMinMax = [];
        _filterArray = filterData(
          this.state.sort_data_bkp,
          _divisionfilter,
          keysWithMinMax
        );
        this.setState(
          {
            sort_data: _filterArray,
            sort_data_tocso: _filterArray,
            sort_data_nouser: _filterArray,
            sort_data_entdetail: _filterArray,
          },
          () => {
            console.log("Division filter data", this.state.sort_data);
            this.updateTable();
          }
        );
      }
      if (seltype === "cluster") {
        this.state.datefilter = [];
        let _clusterfilter = {
          cluster: [val],
        };
        let keysWithMinMax = [];
        _filterArray = filterData(
          this.state.sort_data_bkp,
          _clusterfilter,
          keysWithMinMax
        );
        this.setState(
          {
            sort_data: _filterArray,
            sort_data_tocso: _filterArray,
            sort_data_nouser: _filterArray,
            sort_data_entdetail: _filterArray,
          },
          () => {
            console.log("cluster filter data", this.state.sort_data);
            this.updateTable();
          }
        );
      }
      if (seltype === "branch") {
        //this.state.datefilter = [];
        //console.log("seltype", seltype);
        let _branch_namefilter = {
          branch_name: [val],
        };
        let keysWithMinMax = [];
        _filterArray = filterData(
          this.state.sort_data_bkp,
          _branch_namefilter,
          keysWithMinMax
        );
        console.log(
          "this.state.sort_data_bkp branch=>",
          this.state.sort_data_bkp
        );
        console.log("branch=>", _filterArray);

        this.setState(
          {
            sort_data: _filterArray,
            sort_data_tocso: _filterArray,
            sort_data_nouser: _filterArray,
            sort_data_entdetail: _filterArray,
          },
          () => {
            this.updateTable();
            console.log("branch sort data =>", this.state.sort_data);
          }
        );
      }
    } else {
      this.setState(
        {
          sort_data: this.state.sort_data_ent,
          //sort_data: this.state.sort_data_bkp,
        },
        () => {
          this.updateTable();
          console.log("sort_data_ent else sort data =>", this.state.sort_data);
        }
      );
    }
    //this.csotofilter();
    // this.forceUpdate();
  }
  onChangechkbox = (checkedValues) => {
    this.setState({ checkedboxes: checkedValues });
    let _baseColumns1 = [];
    this.state.baseColumns
      .filter((item) => checkedValues.includes(item.key))
      .map((filteredName) => _baseColumns1.push(filteredName));

    const uniqueArray = Array.from(
      new Set(_baseColumns1.map(JSON.stringify))
    ).map(JSON.parse);
    this.setState({
      baseColumns1: uniqueArray,
    });
    //this.forceUpdate();
  };

  setDate = (date, dateString) => {
    //console.log("dateString[0]", date.length);
    if (dateString) {
      this.setState({
        selsdate: dateString[0],
        seledate: dateString[1],
      });
      const socket = new WebSocket(
        "wss://python-report-dashboard-dev-htyabn7jja-el.a.run.app/get/report?fdate=" +
          moment(dateString[0]).format("YYYY-MM-DD") +
          "&tdate=" +
          moment(dateString[1]).format("YYYY-MM-DD")
      );
      socket.onmessage = (ev) => {
        //console.log("WebSocket.readyState", socket.readyState);
        this.setState({
          wsconnectstatus: socket.readyState,
        });
        let ojson = ev.data;
        ojson = ojson.replace(/'/g, '"');
        ojson = JSON.parse(ojson);

        this.state.getlabel = [];
        this.state.getkey = [];
        ojson.map((user, index) =>
          Object.entries(user).map(([key, value]) =>
            this.state.getkey.push([key, value.lable])
          )
        );
        this.state.uniqueKey = [];
        this.state.uniqueKey = Array.from(
          new Set(this.state.getkey.map((res) => res.join("-")))
        ).map((data) => data.split("-"));

        ojson.map((user, index) =>
          Object.entries(user).map(([key, value]) =>
            this.state.getlabel.push(value.lable)
          )
        );

        this.state.uniqueLabel = [];
        this.state.getlabel.map((label) => {
          if (this.state.uniqueLabel.indexOf(label) === -1) {
            this.state.uniqueLabel.push(label);
          }
        });

        let _ReportData = [];
        ojson.map((item, index) => {
          const reconciledObj = Object.entries(item).map(([key, value]) => {
            return { [key]: value.value };
          });
          let finalObj = {};
          reconciledObj.forEach((obj) => {
            finalObj = {
              ...finalObj,
              ...obj,
            };
          });
          //return finalObj;
          _ReportData.push({ ...finalObj, key: index });
        });

        this.setState(
          {
            ReportData: _ReportData,
            ReportData_bkp: _ReportData,
            sort_data: _ReportData,
            sort_data_bkp: _ReportData,
            wsdata: ojson,
          },
          () => {
            this.chkfilterdate();
            //this.defaultfilter();
            this.createfilter();
            console.log("call ws", this.state.sort_data);
          }
        );
        socket.close();
      };
    }
  };
  savepreferences = () => {
    const formdata = new FormData();
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const uid = localStorage.getItem("in_userid");
    let _preferences = JSON.stringify(this.state.baseColumns1);

    formdata.append("userid", uid);
    formdata.append("report_id", "1");
    formdata.append("preferences", _preferences);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
      },
      body: formdata,
    };
    customFetch(
      process.env.REACT_APP_URL + "/save/column/preferences",
      requestOptions
    )
      .then((response) => {
        if (response.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: response.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }

        this.setState({
          isUpdate: true,
          isUpdMessage: data[0].message,
        });
      })
      .catch((error) => console.log("error", error));
  };
  handleFilterChange = (columnKey, filterValue) => {
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          [columnKey]: filterValue,
        },
      },
      () => {
        this.sort_datafilter();
      }
    );
  };
  sort_datafilter = () => {
    let _sort_filter = [];
    let _fildata =
      this.state.sort_data_nouser && this.state.sort_data_nouser != ""
        ? this.state.sort_data_nouser
        : this.state.isFilterByType != ""
        ? this.state.sort_data_tocso
        : this.state.SelEnt !== ""
        ? this.state.sort_data_ent
        : this.state.sort_data_bkp;

    _sort_filter = _fildata.filter((record) => {
      return Object.keys(this.state.filterValues).every((columnKey) => {
        const filterValue = this.state.filterValues[columnKey];
        const recordValue = record[columnKey];

        if (!filterValue) {
          return true;
        }

        return String(recordValue)
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      });
    });
    this.setState(
      {
        sort_data: _sort_filter,
      },
      () => console.log("sort_datafilter=>", this.state.sort_data)
    );
  };
  footerRow = () => {
    let totalNotTo = 0;
    let pageData = this.state.sort_data_forsum && this.state.sort_data_forsum;
    pageData.forEach((value) =>
      value.usertype !== "TO" || value.usertype !== "TCLTO"
        ? (totalNotTo += value.deposited)
        : 0
    );

    let total = 0;
    pageData.forEach((value) => (total += value.collected));

    const totalCollection = total - totalNotTo;

    let totalDeposit = 0;
    pageData.forEach((value) =>
      value.usertype === "TO" || value.usertype === "TCLTO"
        ? (totalDeposit += value.deposited)
        : 0
    );

    const minDate = moment.min(pageData.map((d) => moment(d.as_on_dt)));

    let totalopening = 0;
    pageData.forEach((value) =>
      moment(minDate).isSame(moment(value.as_on_dt))
        ? (totalopening += value.opening_balance)
        : 0
    );

    const maxDate = moment.max(pageData.map((d) => moment(d.as_on_dt)));

    let totalclosing = 0;
    pageData.forEach((value) =>
      moment(maxDate).isSame(moment(value.as_on_dt))
        ? (totalclosing += value.closing_balance)
        : 0
    );
    let foot = {
      key: "footer",
      collected: formatter.format(totalCollection),
      deposited: formatter.format(totalDeposit),
      opening_balance: formatter.format(totalopening),
      closing_balance: formatter.format(totalclosing),
    };
    return foot;
  };
  render() {
    const { ReportData, sort_data, baseColumns1, datasource } = this.state;
    console.log("final sort_data:", this.state.sort_data);
    return (
      <>
        <div
          className={
            this.state.isSetting
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isSetting: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              {/* <p>Do you want to register the household for NACH?</p> */}
              <div>
                <Checkbox.Group
                  onChange={this.onChangechkbox}
                  className="form-group row"
                  value={this.state.checkedboxes}
                >
                  {this.state.uniqueKey &&
                    this.state.uniqueKey.map((keydata) => {
                      return (
                        <>
                          <span style={{ marginLeft: "8px" }}>
                            <Checkbox className="col-sm-3" value={keydata[0]}>
                              {keydata[1]}
                            </Checkbox>
                          </span>
                        </>
                      );
                    })}
                </Checkbox.Group>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  // onClick={() => {
                  //   this.props.history.push({
                  //     pathname: "/Nach_type",
                  //     state: {
                  //       api_token: api_token,
                  //       household_id: this.state.household_id,
                  //     },
                  //   });
                  // }}
                  onClick={() => {
                    this.savepreferences();
                    this.setState({ isSetting: false });
                  }}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => this.setState({ isSetting: false })}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.isUpdate
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ isUpdate: false });
                  //window.location.reload();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{this.state.isUpdMessage}</p>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    this.setState({ isUpdate: false });
                    //window.location.reload();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container no-container">
          <div className="top_nav_report">
            <a>
              <div
                className="icon_bg-report"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../assets/images/back-white.png")}
                  className="icon_img"
                />
              </div>
            </a>
            <span className="head-title">Report</span>

            <a>
              <div className="log-out-reporthead">
                <img
                  src={require("../assets/images/power-back.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
            </a>
          </div>
          <div className="form-row">
            <div className="form-group col-md-4">
              <RangePicker
                className="form-control"
                onChange={(date, dateString) => this.setDate(date, dateString)}
                //defaultValue={[moment(sdate), moment(edate)]}
              />
            </div>
          </div>
          {this.state.wsconnectstatus === 1 ? (
            <div className="groupreport">
              <div className="form-group float-right">
                <Button
                  type="primary"
                  icon={<SettingOutlined />}
                  onClick={() => this.setState({ isSetting: true })}
                />
              </div>
              <div className="form-row">
                <div className="form-group col-md-2">
                  <select
                    className="form-control"
                    name="SelEnt"
                    value={this.state.SelEnt}
                    onChange={(e) =>
                      this.setState({
                        //isEntity: e.target.value,
                        SelEnt: e.target.value,
                        isFilterByType: "",
                        sort_data: consolidate(
                          this.state.sort_data_bkp,
                          e.target.value
                        ),
                        sort_data_ent: consolidate(
                          this.state.sort_data_bkp,
                          e.target.value
                        ),
                      })
                    }
                  >
                    <option value="">Select</option>
                    <option value="zone_id">Zone</option>
                    <option value="division_id">Division</option>
                    <option value="cluster_name">Cluster</option>
                    <option value="branch_id">Branch</option>
                  </select>
                </div>
                {this.state.SelEnt === "zone_id" ? (
                  <div className="form-group col-md-2">
                    <select
                      value={this.state.isFilterByType}
                      className="form-control"
                      onChange={(e) =>
                        this.filterdatabyType(e, e.target.value, "zone")
                      }
                    >
                      <option value="">Select Zone</option>
                      {this.state.uniqueZone
                        ? this.state.uniqueZone.map((zone) => (
                            <option value={zone}>{zone}</option>
                          ))
                        : ""}
                    </select>
                  </div>
                ) : (
                  ""
                )}
                {this.state.SelEnt === "division_id" ? (
                  <div className="form-group col-md-2">
                    <select
                      className="form-control"
                      value={this.state.isFilterByType}
                      onChange={(e) =>
                        this.filterdatabyType(e, e.target.value, "division")
                      }
                    >
                      <option value="">Select Division</option>
                      {this.state.uniqueDivision
                        ? this.state.uniqueDivision.map((division) => (
                            <option value={division}>{division}</option>
                          ))
                        : ""}
                    </select>
                  </div>
                ) : (
                  ""
                )}
                {this.state.SelEnt === "cluster_name" ? (
                  <div className="form-group col-md-2">
                    <select
                      className="form-control"
                      value={this.state.isFilterByType}
                      onChange={(e) =>
                        this.filterdatabyType(e, e.target.value, "cluster")
                      }
                    >
                      <option value="">Select Cluster</option>
                      {this.state.uniqueCluster
                        ? this.state.uniqueCluster.map((cluster) => (
                            <option value={cluster}>{cluster}</option>
                          ))
                        : ""}
                    </select>
                  </div>
                ) : (
                  ""
                )}
                {this.state.SelEnt === "branch_id" ? (
                  <div className="form-group col-md-2">
                    <select
                      className="form-control"
                      value={this.state.isFilterByType}
                      onChange={(e) =>
                        this.filterdatabyType(e, e.target.value, "branch")
                      }
                    >
                      <option value="">Select Branch</option>
                      {this.state.uniqueBranch
                        ? this.state.uniqueBranch.map((branch) => (
                            <option value={branch}>{branch}</option>
                          ))
                        : ""}
                    </select>
                  </div>
                ) : (
                  ""
                )}

                {this.state.isFilterByType &&
                this.state.isFilterByType != "" ? (
                  <>
                    <div className="form-group col-md-2">
                      <select
                        className="form-control"
                        value={this.state.csotofilterdata}
                        onChange={(e) => this.csotofilter(e.target.value)}
                      >
                        <option value="">Select User Type</option>
                        <option value="All">All</option>
                        <option value="CSO">CSO</option>
                        <option value="TO">TO</option>
                        <option value="BM">BM</option>
                      </select>
                    </div>
                    <div className="form-group col-md-2">
                      <select
                        className="form-control"
                        value={this.state.valdata}
                        onChange={(e) =>
                          this.filteruser(e, e.target.value, "user")
                        }
                      >
                        <option value="">Select User</option>
                        {this.state.uniqueUser
                          ? this.state.uniqueUser.map((user) => (
                              <option value={user}>{user}</option>
                            ))
                          : ""}
                      </select>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="export_button form-group col-md-2">
                  <ExportTableButton
                    columns={
                      this.state.baseColumns1 &&
                      this.state.baseColumns1.length > 0
                        ? this.state.baseColumns1
                        : this.state.baseColumns
                    }
                    dataSource={[...this.state.sort_data, this.footerRow()]}
                    btnProps={{
                      type: "primary",
                      icon: <FileExcelOutlined />,
                    }}
                    fileName={
                      "Report-" + moment().format("DD-MM-YYYY-hh-mm-ss_a")
                    }
                    //showColumnPicker
                  >
                    Export to CSV
                  </ExportTableButton>
                </div>
              </div>

              <Table
                className="table-striped-rows"
                exportable
                {...tableProps}
                columns={
                  this.state.baseColumns1 &&
                  this.state.baseColumns1.map((column) => ({
                    ...column,
                    onFilterDropdownVisibleChange: (visible) => {
                      if (visible) {
                        setTimeout(() => {
                          const searchInput = document.querySelector(
                            ".ant-table-filter-dropdown input"
                          );
                          if (searchInput) {
                            searchInput.focus();
                          }
                        }, 0);
                      }
                    },
                    filterDropdown: (
                      <div style={{ padding: 8 }}>
                        <Input
                          placeholder={`Search ${column.title}`}
                          value={
                            this.state.filterValues &&
                            this.state.filterValues[column.key]
                          }
                          onChange={(e) =>
                            this.handleFilterChange(column.key, e.target.value)
                          }
                        />
                      </div>
                    ),
                    filterIcon: (
                      <SearchOutlined
                        style={{
                          color:
                            this.state.filterValues &&
                            this.state.filterValues[column.key]
                              ? "#1890ff"
                              : undefined,
                        }}
                      />
                    ),
                  }))
                }
                pagination={false}
                dataSource={[...this.state.sort_data, this.footerRow()]}
              />
            </div>
          ) : !this.state.selsdate && !this.state.seledate ? (
            <div className="text-center">Please Select Date</div>
          ) : this.state.wsconnectstatus === 0 ? (
            <div className="text-center">CONNECTING to server.</div>
          ) : (
            <div className="text-center">
              Can't Connect to server. Please Contact Techincal Team.
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Cash_balance_report;
