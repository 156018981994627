import React, { Component } from "react";
import { withRouter } from "react-router";
import * as serviceWorker from "./../serviceWorker";
import { ThemeContext } from "../context/IsLoader";
//import useRazorpay from "react-razorpay";
import moment from "moment";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
class Test extends Component {
  static contextType = ThemeContext;
  constructor() {
    super();
    this.state = {
      items: [],
      //isLoaded: "This is Loader",
      orders: [],
      name: "",
      price: "",
    };
    //this.doThis = this.doThis.bind(this)
  }
  componentDidMount() {
    const { isLoaded, setisLoaded } = this.context;
    console.log("isLoaded:", isLoaded);
    //setisLoaded("Call from Test");
    console.log("isLoaded2:", this.state);
    //console.log("navigator.connection.downlink", navigator.connection.downlink);
    // let type = navigator.connection.effectiveType;

    // function updateConnectionStatus() {
    //   console.log(
    //     `Connection type changed from ${type} to ${navigator.connection.effectiveType}`
    //   );
    //   type = navigator.connection.effectiveType;
    // }

    // navigator.connection.addEventListener("change", updateConnectionStatus);
  }
  saveOrder = async () => {
    const event = new CustomEvent("button-clicked");
    window.dispatchEvent(event);
    this.props.history.push({
      pathname: "/Test_two",
      state: {},
    });
    const formdata = new FormData();
    formdata.append("in_household_id", 1);
    formdata.append("in_collection_mode_flag", "aa");
    formdata.append("in_reference_number", "123123");
    var object = {};
    formdata.forEach(function (value, key) {
      object[key] = value;
    });
    object["URL"] = "/set/collection/household/customer";
    var orderData = {
      name: "aaa",
      price: 1,
    };
    // var p = JSON.stringify(object);
    // var z = JSON.parse(p);
    // console.log("P:",p);
    // for (let [key, value] of Object.entries(z)) {
    //   console.log(`${key}: ${value}`);
    // }

    console.log("Length:", object.length);
    if (!navigator.onLine) {
      //console.log("Array:", Array.isArray(object));
      serviceWorker.registerSync();
      this.insertIntoDatabase(JSON.stringify(object));
      console.log("saveOrder", [...this.state.orders, object]);
      this.setState({
        orders: [...this.state.orders, object],
        name: "",
        price: "",
      });
    }
  };
  insertIntoDatabase = (dataObject) => {
    var indexedDBOpenRequest = window.indexedDB.open("order", 1);
    indexedDBOpenRequest.onupgradeneeded = function () {
      this.result.createObjectStore("order_requests", {
        keyPath: "id",
        autoIncrement: true,
      });
    };

    indexedDBOpenRequest.onsuccess = function () {
      let db = this.result;
      let transaction = db.transaction("order_requests", "readwrite");
      let storeObj = transaction.objectStore("order_requests");
      storeObj.add(dataObject);
      //const cursor = indexedDBOpenRequest.result;
      console.log("All key", storeObj.getAllKeys());
      console.log("All Data", storeObj.getAll());
    };
    indexedDBOpenRequest.onerror = function (error) {
      console.error("IndexedDB error:", error);
    };
  };

  render() {
    const { isLoaded, setisLoaded } = this.context;
    // const { entity_name } = (this.props.location && this.props.location.state) || {};
    // console.log("Data:",items);
    var a = moment();
    var b = moment("25/02/2023 18:12:15", "DD/MM/YYYY HH:mm:ss");
    console.log("Chk Date:", moment(b).isSame(moment(), "day"));
    async function displayRazorpay() {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      // const data = await customFetch("http://localhost:1337/razorpay", {
      //   method: "POST",
      // }).then((t) => t.json());

      // console.log(data);

      const options = {
        key: "rzp_test_qNbeYtTNlIX5Lq",
        order_id: "order_KH0kvPHijwh09x",
        customer_id: "cust_KGwX8TMJBgBQdf",
        recurring: "1",
        handler: function (response) {
          alert(response.razorpay_payment_id);
          alert(response.razorpay_order_id);
          alert(response.razorpay_signature);
        },
        notes: {
          "note_key 1": "Beam me up Scotty",
          "note_key 2": "Tea. Earl Gray. Hot.",
        },
        theme: {
          color: "#F37254",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
    var myStr = " Hello World "; // space at both end
    var myStrLength = myStr.length;
    var myStr1 = myStr.trim();
    var myStrLength1 = myStr1.length;
    return (
      <>
        {isLoaded}
        <button onClick={() => setisLoaded("aaa bbb ccc")}>Update</button>
        <button onClick={() => this.props.history.push("/Test_two")}>
          Page 2
        </button>
        <button
          onClick={() => console.log("isLoaded-Button:", this.context.isLoaded)}
        >
          Log
        </button>
        {/* <p>
          Befor Trim Length ={myStr} {myStrLength} OR After Trim Length =
          {myStr1} {myStrLength1}
        </p>
        <a
          className="App-link"
          onClick={displayRazorpay}
          target="_blank"
          rel="noopener noreferrer"
        >
          Pay
        </a>
        <button onClick={this.saveOrder}>Save</button> */}
      </>
    );
  }
}

export default withRouter(Test);
