import React, { Component } from "react";
import customFetch from "./apicall/api";
import { withRouter } from "react-router";
import { logout, chkapiinput } from "./Utility";

class Pm extends Component {
  constructor() {
    super();
    this.state = {
      pmdata: [],
      isLoaded: false,
      isErrOpen: false,
      api_error_message: "",
      isSpin: false,
    };
    this.doThis = this.doThis.bind(this);
  }
  componentDidMount() {
    document.title = "Pm";
    this.setState({ isSpin: true });
    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
    const { api_token, entity_id, entity_unit_level_id } =
      (this.props.location && this.props.location.state) || {};
    if (entity_id) {
      /*Check if data is null then logout*/
      var arr = [uid, entity_id, entity_unit_level_id];
      var chkdata = chkapiinput(arr);
      if (chkdata === false) {
        this.setState({ isSpin: false });
        setTimeout(function () {
          logout();
        }, 2000);
      }
/*Check if data is null then logout End*/
      const formdata = new FormData();
      formdata.append("in_userid", uid);
      formdata.append("in_entity_id", entity_id);
      formdata.append("in_entity_unit_level_id", entity_unit_level_id);
      customFetch(process.env.REACT_APP_URL + "/getreport", {
        method: "POST",
        headers: { "api-token": api_token },
        body: formdata,
      })
        .then((res) => {
          if (res.headers.get("api_code") > 0) {
            this.setState({
              isErrOpen: true,
              api_error_message: res.headers.get("api_error_message"),
              isSpin: false,
            });
          }
          return res.json();
        })
        .then((json) => {
          console.log(this.state.pmdata);
          if (
            json.api_code === 4 ||
            json.api_code === 3 ||
            json.api_code === 5
          ) {
            localStorage.clear();
            this.props.history.push("/");
          }
          this.setState({
            isLoaded: true,
            pmdata: json,
            isSpin: false,
          });
        })
        .catch((error) => console.log("error", error));
    } else {
      const { api_token, max_unit_level_id } =
        (this.props.location && this.props.location.state) || {};
      /*Check if data is null then logout*/
      var arr = [uid, max_unit_level_id];
      var chkdata = chkapiinput(arr);
      if (chkdata === false) {
        this.setState({ isSpin: false });
        setTimeout(function () {
          logout();
        }, 2000);
      }
/*Check if data is null then logout End*/
      const formdata = new FormData();
      formdata.append("in_userid", uid);
      formdata.append("call_procedure", uid);
      formdata.append("in_entity_unit_level_id", max_unit_level_id);
      customFetch(process.env.REACT_APP_URL + "/getreport", {
        method: "POST",
        headers: { "api-token": api_token },
        body: formdata,
      })
        .then((res) => {
          if (res.headers.get("api_code") > 0) {
            this.setState({
              isErrOpen: true,
              api_error_message: res.headers.get("api_error_message"),
              isSpin: false,
            });
          }
          return res.json();
        })
        .then((json) => {
          console.log(this.state.pmdata);
          if (
            json.api_code === 4 ||
            json.api_code === 3 ||
            json.api_code === 5
          ) {
            localStorage.clear();
            this.props.history.push("/");
          }
          this.setState({
            isLoaded: true,
            pmdata: json,
            isSpin: false,
          });
        })
        .catch((error) => console.log("error", error));
    }
  }
  totalbalance() {
    const { pmdata } = this.state;
    if (pmdata.length > 0) {
      const total = pmdata.reduce(
        (prevValue, currentValue) =>
          parseFloat(prevValue) + parseFloat(currentValue.balance),
        0
      );
      return total.toLocaleString("en-IN");
    } else {
      const total = 0;
      return total;
    }
  }
  totaldemand() {
    const { pmdata } = this.state;
    if (pmdata.length > 0) {
      const total = pmdata.reduce(
        (prevValue, currentValue) =>
          parseFloat(prevValue) + parseFloat(currentValue.demand),
        0
      );
      return total.toLocaleString("en-IN");
    } else {
      const total = 0;
      return total;
    }
  }
  totalcollect() {
    const { pmdata } = this.state;
    if (pmdata.length > 0) {
      const total = pmdata.reduce(
        (prevValue, currentValue) =>
          parseFloat(prevValue) + parseFloat(currentValue.collection),
        0
      );
      return total.toLocaleString("en-IN");
    } else {
      const total = 0;
      return total;
    }
  }
  doThis(entity_id, entity_name, entity_unit_level_id) {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    this.props.history.push({
      pathname: "/Cso",
      state: {
        entity_id: entity_id,
        entity_name: entity_name,
        api_token: api_token,
        entity_unit_level_id: entity_unit_level_id,
      },
    });
  }
  // logout() {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // }
  render() {
    const { entity_name } =
      (this.props.location && this.props.location.state) || {};
    const { pmdata, isErrOpen, api_error_message } = this.state;
    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>
        <section className="main-sec">
          <div className="container fix-width">
            <div className="header-area">
              <div
                className="icon_bg"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../assets/images/back.png")}
                  className="icon_img"
                  alt=""
                />
              </div>
              <div className="menu-image-holder" style={{ display: "none" }}>
                <a href="#">
                  <img
                    src={require("../assets/images/menu.png")}
                    alt=""
                    className="menu-image"
                  />
                </a>
              </div>
              <div className="head-title">View Reports</div>
              <div className="menu-image-holder2" style={{ display: "none" }}>
                <a href="#">
                  <img
                    src={require("../assets/images/cloud-download.png")}
                    alt=""
                    className="menu-image2"
                  />
                </a>
              </div>
              <div className="menu-image-holder3" style={{ display: "none" }}>
                <a href="#">
                  <img
                    src={require("../assets/images/hierarchy-64.png")}
                    alt=""
                    className="menu-image3"
                  />
                </a>
              </div>
              <div className="log-out-report">
                <img
                  src={require("../assets/images/power-off.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
            </div>
            <div className="title-holder">
              <h2>DEMAND SHEET BM/PM</h2>
            </div>
            <div className="main-area">
              <div className="name-holder">
                <h2>{entity_name}</h2>
              </div>
              <div className="demand-holder">
                <h2>Demand</h2>
                <span>Rs. {this.totaldemand()}</span>
              </div>
              <div className="main-foor-part mt-3 mb-2">
                <div className="collected-holder">
                  <h2>Collected</h2>
                  <span>Rs. {this.totalcollect()}</span>
                </div>
                <div className="balance-holder">
                  <h2>Balance</h2>
                  <span>Rs. {this.totalbalance()}</span>
                </div>
              </div>
            </div>
            <div className="report-card-holder">
              {pmdata.length > 0
                ? pmdata.map((item) => (
                    <div
                      className="card card-border mt-3"
                      key={item.entity_id}
                      onClick={() =>
                        this.doThis(
                          item.entity_id,
                          item.entity_name,
                          item.entity_unit_level_id
                        )
                      }
                    >
                      <div className="card-body">
                        <div className="report-card-title">
                          <h3>{item.entity_name}</h3>
                        </div>
                        <div className="report-card-subhead d-flex justify-content-between align-items-center">
                          <span className="subhead ml-5"></span>
                          <span className="subamount ml-5">Amount</span>
                          <span className="subamount">HH</span>
                        </div>
                        <div className="report-card-subhead d-flex justify-content-between align-items-center">
                          <span className="subhead">Demand</span>
                          <span className="subamount m-0">
                            Rs.{" "}
                            {parseFloat(item.demand).toLocaleString("en-IN")}
                          </span>
                          <span className="subamount m-0">
                            {item.demand_count}
                          </span>
                        </div>
                        <div className="report-card-subhead d-flex justify-content-between align-items-center">
                          <span className="subheadone">Collected</span>
                          <span className="subamount m-0">
                            Rs.{" "}
                            {parseFloat(item.collection).toLocaleString(
                              "en-IN"
                            )}
                          </span>
                          <span className="subamount m-0">
                            {item.collection_count}
                          </span>
                        </div>
                        <div className="report-card-subhead d-flex justify-content-between align-items-center">
                          <span className="subheadthree">Balance</span>
                          <span className="subamount m-0">
                            Rs.{" "}
                            {parseFloat(item.balance).toLocaleString("en-IN")}
                          </span>
                          <span className="subamount m-0">
                            {item.balance_count}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(Pm);
