import React, { Component } from "react";
import { logout, generateId } from "./Utility";
import { get_payload_config } from "./Services";
import { ThemeContext } from "../context/IsLoader";
import customFetch from "./apicall/api";
const generate_Id1 = generateId(10);
class PayloadViewSelect extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      api_token: "",
      effective_designation_id: "",
      views: [],
      isspin: true,
      flag: 0,
      isLoginuser: "0",
      otherScreen: "",
    };
  }

  componentDidMount() {
    if (
      window.Android &&
      typeof window.Android.getDataFromLocalStorage === "function"
    ) {
      const data = window.Android.getDataFromLocalStorage("your_key");
      if (data) {
        this.setState({ isSpin: false, flag: 1 }); // Set flag to 1 if accessed from Android
        const tokenParts = data.split(",");
        const tokenData = {};

        tokenParts.forEach((part) => {
          const [key, value] = part.split(":");
          tokenData[key] = value;
        });

        console.log("AndroidInterface->", data);
      }
    } else {
      console.log(
        "Error in AndroidInterface: Android method not found or not available."
      );
    }

    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    this.checkForRequest();
  }

  checkForRequest(props) {
    const search = this.props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);

    let api_token = params.get("api_token");
    let desc_id = params.get("desc_id");
    let uid = params.get("uid");
    //let user_id = "";

    if (api_token && desc_id) {
      
      localStorage.setItem("api_token", api_token);
      localStorage.setItem("in_userid", uid);
      localStorage.setItem("e_designation_id", desc_id);      
    }
    if (api_token == null || desc_id == null) {
      // console.log("HELLOOops.location.state.api_token);

      api_token =
        (this.props.location && this.props.location.state.api_token) || {};
      localStorage.setItem("api_token", api_token);      
      this.setState(
        {
          api_token: api_token,
          effective_designation_id: this.props.location.state
            .effective_designation_id,
          isLoginuser: "1",
        },
        function() {
          this.configure_data();
        }
      );
      console.log("if");
    } else {
      console.log("else part");

      this.context.setotherscreen(1);

      const paramsgetrole = {
        userid: uid,
      };
      const queryParams = new URLSearchParams(paramsgetrole).toString();
      const requestOptions = {
        method: "GET",
        headers: {
          "api-token": this.state.api_token,
        },
      };
      // AT-2109 | AT-2156 | Ankur Makavana | 10-07-2023 | replace blob_url with public filepath
      const Reasonlist = customFetch(
        process.env.REACT_APP_URL + "/getrole?" + queryParams,
        requestOptions
      )
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          console.log("branch_id",json.branch_id);
          
          localStorage.setItem("branch_id", json.branch_id);
          this.setState(
            {
              api_token: api_token,
              effective_designation_id: desc_id,
              otherScreen: 1,
            },
            function() {
              this.configure_data();
            }
          );
        });
    }
  }

  configure_data = async () => {
    console.log("api_token", this.state.api_token);
    console.log(
      "effective_designation_id",
      this.state.effective_designation_id
    );
    const response = await get_payload_config(
      this.state.api_token,
      this.state.effective_designation_id
    );
    console.log(
      "call configure_data",
      this.state.api_token + "--" + this.state.effective_designation_id
    );
    this.setState({ views: response, isspin: false });
  };

  render() {
    return (
      <div className="container">
        {this.state.isspin && (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        )}
        <div className="payload_view_navbar">
          <button
            className="button back_button"
            onClick={() => this.props.history.goBack()}
          >
            <img src={require("../assets/images/back.png")} alt="" />
          </button>
          <h4>Views</h4>
          {this.state.flag === 0 && (
            <button
              className="button"
              onClick={() => logout(this.state.otherScreen)}
            >
              <img src={require("../assets/images/power-off.png")} alt="" />
            </button>
          )}
        </div>
        <div className="payload_tiles">
          {this.state.views.length > 0 ? (
            this.state.views.map((item) => {
              return (
                item.view_type != "SubView" && (
                  <>
                    <div
                      className="card card-border-small col-sm-6 py-2 d-flex align-items-center justify-content-center"
                      key={item.view_name}
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/payload/view",
                          state: {
                            api_token: this.state.api_token,
                            effective_designation_id: this.state
                              .effective_designation_id,
                            payload_config: item,
                            isLoginuser: this.state.isLoginuser,
                            otherScreen: this.state.otherScreen,
                            view_name: item.view_name,
                            fetch_onload: item.fetch_onload,
                            all_view: this.state.views,
                          },
                        });
                      }}
                    >
                      <div className="card-body d-flex align-items-center">
                        <h3>{item.view_name}</h3>
                      </div>
                    </div>
                  </>
                )
              );
            })
          ) : (
            <div className="text-center font-bold">No Data Found</div>
          )}
        </div>
      </div>
    );
  }
}

export default PayloadViewSelect;
