import React, { Component } from "react";
import customFetch from "./apicall/api";
import { logout, generateId } from "./Utility";
import { ThemeContext } from "../context/IsLoader";
const generate_Id1 = generateId(10);
export default class Reversal extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      api_token: localStorage.getItem("api_token"),
      flag: 0,
    };
  }
  componentDidMount() {
    if (
      window.Android &&
      typeof window.Android.getDataFromLocalStorage === "function"
    ) {
      const data = window.Android.getDataFromLocalStorage("your_key");
      if (data) {
        this.setState({ isSpin: false, flag: 1 }); // Set flag to 1 if accessed from Android
        const tokenParts = data.split(",");
        const tokenData = {};

        tokenParts.forEach((part) => {
          const [key, value] = part.split(":");
          tokenData[key] = value;
        });

        console.log("AndroidInterface->", data);
      }
    } else {
      console.log(
        "Error in AndroidInterface: Android method not found or not available."
      );
    }


    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
  }
  // logout =()=>{
  //   localStorage.clear();
  //   this.props.history.push("/");
  // }

  render() {
    return (
      <div className="container">
        <div className="reversal_navbar">
          <button
            className="button back_button"
            onClick={() => this.props.history.goBack()}
          >
            <img src={require("../assets/images/back.png")} />
          </button>
          <span>Reversal</span>

          {this.state.flag === 0 && (
          <button className="button" onClick={() => logout()}>
            <img src={require("../assets/images/power-off.png")} alt="" />
          </button>
          )}
        </div>
        <div className="reversal_card_holder">
          <div
            className="card card-border-small col-sm-6 py-2"
            onClick={() => {
              this.props.history.push({
                pathname: "/transactionlist",
              });
            }}
          >
            <div className="card-body">
              <div className="image-holder-6">
                <img src={require("../assets/images/upload-7.png")} alt="" />
              </div>
              <div className="card-heading">
                <h2>
                  Submit
                  <br />
                  Reversal
                </h2>
              </div>
              <div className="text-center"></div>
            </div>
          </div>
          <div
            className="card card-border-small col-sm-6 py-2"
            onClick={() => {
              this.props.history.push({
                pathname: "/reversaldashboard",
              });
            }}
          >
            <div className="card-body">
              <div className="image-holder-7">
                <img src={require("../assets/images/monitor.png")} alt="" />
              </div>
              <div className="card-heading">
                <h2>
                  Reversal
                  <br />
                  Dashboard
                </h2>
              </div>
              <div className="text-center"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
