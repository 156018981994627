import React, { Component } from "react";
import moment from "moment";

class collectionSuccess extends Component {
  render() {
    const {
      api_token,
      tmp_in_amount_collected,
      tmp_in_charges_collected,
      tmp_timestamp,
      Household_name,
      tmp_txn_ref_no,
      payment_method,
      OcrAmount,
      OcrBank,
      OcrDate,
      OcrFrom,
      OcrTo,
      OcrTransaction_ID,
      OcrUPI_UTR_Ref,
      Ocrvendor,
      message,
    } = (this.props.location && this.props.location.state) || {};
    return (
      <div className="container mt-4">
        <div className="row d-flex flex-row align-items-center">
          {/* <div className="col-2">
                        <div className="back-button d-flex align-items-center justify-content-center" onClick={() => this.props.history.goBack()}><img className="back-icon" src={require("../assets/images/back.png")}  alt=""/></div>
                    </div> */}
          <div className="col-12 text-center">
            <div className="header-message">TRANSACTION SUCCESSFUL</div>
            <div className="date-time">{OcrDate ? OcrDate : tmp_timestamp}</div>
          </div>
        </div>

        <div className="row m-1 mt-4  justify-content-center">
          <div className="col paid-to-card">
            <div className="card-box-header text-center mt-3 mb-2 d-flex align-items-center justify-content-center">
              Received From
            </div>
            <div className="d-flex flex-row  align-items-center p-2">
              <div className="profile-icon d-flex align-items-center justify-content-center">
                {OcrFrom
                  ? OcrFrom.substring(0, 1)
                  : Household_name.substring(0, 1)}
              </div>
              <div className="p-2">
                <div className="customer-name-text">
                  {OcrFrom ? OcrFrom : Household_name}
                </div>
                <div className="amount-paid-text">
                  Rs.{" "}
                  {parseFloat(tmp_in_amount_collected).toLocaleString("en-IN")}
                  {/* {OcrAmount
                    ? OcrAmount.toLocaleString("en-IN")
                    : (
                        parseFloat(tmp_in_amount_collected) +
                        parseFloat(tmp_in_charges_collected)
                      ).toLocaleString("en-IN")} */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row m-1 mt-4 justify-content-center">
          <div className="col transfer-details-card ">
            <div className="card-box-header text-center mt-3 mb-2 d-flex align-items-center justify-content-center">
              Transfer Details
            </div>
            <div className="d-flex flex-row  align-items-center justify-content-between p-2">
              <div className="row col-md-12 text-center">
                <div className="col-md-6">
                  <div className="transaction-id-text">Transaction ID</div>
                  <div className="transaction-id">{tmp_txn_ref_no}</div>
                </div>
                <div className="col-md-6">
                  <div className="transaction-id-text">Date</div>
                  <div className="transaction-id">
                    {moment().format("DD/MM/YYYY HH:mm:ss ")}
                  </div>
                </div>
              </div>
              {/* <a
                href="#"
                className="copy-button btn d-flex align-items-center justify-content-center"
              >
                Copy
              </a> */}
            </div>
            {payment_method !== "C" && payment_method !== "Q" && (
              <div className="d-flex flex-column pt-3">
                <div className="mb-2 debited-from-text">Debited From</div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="d-flex flex-row  align-items-center">
                    <div className="bank-logo d-flex align-items-center justify-content-center">
                      {/* <img
                      src={require("../assets/images/sbi-225865.png")}
                      alt=""
                    /> */}
                    </div>

                    <div className="pl-2">
                      <div className="account-number">
                        {OcrBank && OcrBank.split(/[, ]+/).pop()}
                      </div>
                      <div className="utr-number">UTR : {OcrUPI_UTR_Ref}</div>
                    </div>
                  </div>
                  <div className="pl-4 amount-paid-text">
                    Rs.{" "}
                    {OcrAmount
                      ? OcrAmount.toLocaleString("en-IN")
                      : (
                          parseFloat(tmp_in_amount_collected) +
                          parseFloat(tmp_in_charges_collected)
                        ).toLocaleString("en-IN")}
                  </div>
                </div>
                <div className="section-break-line d-flex justify-content-center mt-3 pt-3"></div>
              </div>
            )}
            <div className="d-flex flex-row justify-content-around">
              <div
                className="btn d-flex flex-column align-items-center click_icon"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/dashboard",
                    state: { api_token: api_token },
                  });
                }}
              >
                <div className="logo-box d-flex justify-content-center align-items-center">
                  <img src={require("../assets/images/home.png")} alt="" />
                </div>
                <div className="nav-icons-footer-text">Dashboard</div>
              </div>
              <div
                className="d-flex btn flex-column align-items-center click_icon"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/payload",
                    state: {
                      api_token: api_token,
                      effective_designation_id: localStorage.getItem(
                        "e_designation_id"
                      ),
                    },
                  });
                }}
              >
                <div className="logo-box d-flex justify-content-center align-items-center">
                  <img src={require("../assets/images/waiting.png")} alt="" />
                </div>
                <div className="nav-icons-footer-text">Waiting</div>
              </div>
              {/* <div className="d-flex btn flex-column align-items-center">
                <div className="logo-box d-flex justify-content-center align-items-center">
                  <img src={require("../assets/images/share.png")} alt="" />
                </div>
                <div className="nav-icons-footer-text">Share Receipt</div>
              </div> */}
            </div>
          </div>
        </div>

        {/* <div className="row m-2 mt-4">
          <div className="contact-card btn col d-flex flex-row justify-content-between align-items-center">
            <div>Contact Our Team</div>
            <img src={require("../assets/images/next.png")} alt="" />
          </div>
        </div> */}
        <div className="transaction-msg">
          <span>{message}</span>
        </div>
      </div>
    );
  }
}

export default collectionSuccess;