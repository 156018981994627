import { filterData } from "./Utility";
import customFetch from "./apicall/api";
export const update_payload = async (
  api_token,
  bucket_name,
  payload_key,
  payload
) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };

    const response = await customFetch(
      process.env.REACT_APP_URL +
        "/payload/update/" +
        bucket_name +
        "/" +
        payload_key,
      requestOptions
    );

    if (response.status != 200) throw "Error updating the payload";

    const data = await response.json();
    data["status"] = 1;
    return data;
  } catch (e) {
    console.error(e);
    return { message: "Error updating the payload", status: 0 };
  }
};
export const update_mongopayload = async (
  api_token,
  dbname,
  collectionname,
  objectid,
  change_data
) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(change_data),
    };

    const response = await customFetch(
      process.env.REACT_APP_URL +
        "/mongo/updateone/" +
        dbname +
        "/" +
        collectionname +
        "/" +
        objectid,
      requestOptions
    );

    if (response.status != 200) throw "Error updating the payload";

    const data = await response.json();
    return data;
  } catch (e) {
    return { message: "Error updating the payload", status: 0 };
  }
};
export const update_mongogrouppayload = async (
  api_token,
  dbname,
  collectionname,
  change_data
) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(change_data),
    };

    const response = await customFetch(
      process.env.REACT_APP_URL +
        "/mongo/updateone/" +
        dbname +
        "/" +
        collectionname,
      requestOptions
    );

    if (response.status != 200) throw "Error updating the payload";

    const data = await response.json();
    return data;
  } catch (e) {
    return { message: "Error updating the payload", status: 0 };
  }
};

export const delete_mongofield = async (
  api_token,
  dbname,
  collectionname,
  objectid,
  change_data
) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(change_data),
    };

    const response = await customFetch(
      process.env.REACT_APP_URL +
        "/mongo/delete/" +
        dbname +
        "/" +
        collectionname +
        "/" +
        objectid,
      requestOptions
    );

    if (response.status != 200) throw "Error updating the payload";

    const data = await response.json();
    return data;
  } catch (e) {
    return { message: "Error updating the payload", status: 0 };
  }
};

export const get_payload_config = async (
  api_token,
  effective_designation_id
) => {
  try {
    const formdata = new FormData();
    formdata.append("branch_id", localStorage.getItem("branch_id"));
    formdata.append(
      "effective_designation",
      localStorage.getItem("e_designation_id")
    );
    formdata.append("userid", localStorage.getItem("in_userid"));
    formdata.append("flag", "fetch_user_functionality");
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
      },
      body: formdata,
    };

    const response = await customFetch(
      process.env.REACT_APP_URL + "/permission/data",
      requestOptions
    );
    const data = await response.json();
    console.log("data-1", data.message);
    if (
      !data.message &&
      data.user_functionality_mapping[0].views &&
        data.user_functionality_mapping[0].views.length > 0
    ) {
      return data.user_functionality_mapping[0].views;
    } else {
      return get_view_config(api_token, effective_designation_id);
    }
  } catch (e) {
    console.error(e);
    return [];
  }
};

const get_view_config = async (api_token, effective_designation_id) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        "api-token": api_token,
      },
    };

    const response = await customFetch(
      process.env.REACT_APP_URL +
        "/get/webhook/configuration?effective_designation_id=" +
        effective_designation_id,
      requestOptions
    );
    const data = await response.json();
    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const get_payload_views = async (
  api_token,
  bucket_name,
  event_name,
  defaultfilter,
  isLoginuser,
  filterDate,
  event_location,
  searchData,
  searchconfig
) => {
  console.log("defaultfilter->", defaultfilter.length);
  try {
    let payload = "";
    let defaultpayload = [];
    if (event_name != "") {
      defaultpayload = [
        [event_location, "==", event_name],
        ["created_at", ">", filterDate],
      ];
    } else {
      defaultpayload = [["created_at", ">", filterDate]];
    }
    if (isLoginuser == "1") {
      let convertedData = [];
      if (defaultfilter.length > 0 && defaultfilter[0].operatordata == "==") {
        convertedData = defaultfilter.map((item) => [
          item.field,
          "==",
          parseInt(item.fieldvalue),
        ]);
      }
      if (
        defaultfilter.length > 0 &&
        defaultfilter[0].operatordata == "array_contains_any"
      ) {
        convertedData = defaultfilter.map((item) => [
          item.field,
          "array_contains_any",
          item.fieldvalue.split(",").map((num) => parseInt(num)), //[parseInt(item.fieldvalue)],
        ]);
      }
      payload = [...defaultpayload, ...convertedData];
    } else {
      payload = defaultpayload; //[["event", "==", event_name]];
    }
    let payload_search = [...payload, ...searchData];
    let search_data;
    if (searchconfig) {
      let searchvalue = [];
      searchvalue = searchconfig.map((item) => [item[0], item[1], item[2]]);
      search_data = [...payload_search, ...searchvalue];
    } else {
      search_data = payload_search;
    }
    console.log("search_data-->", search_data);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(search_data),
    };

    const response = await customFetch(
      process.env.REACT_APP_URL + "/payload/get/" + bucket_name,
      requestOptions
    );
    const data = await response.json();
    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const get_mongo_payload = async (
  api_token,
  search_by_pair,
  filterDate,
  searchconfig,
  dbname,
  collectionname,
  defaultfilter,
  isLoginuser,
  limit_per_call,
  offset,
  filter_columns,
  hideloadmore
) => {
  console.log("defaultfilter", defaultfilter);
  try {
    let payload = "";
    let defaultpayload = [];
    // if (event_name != "") {
    //   defaultpayload = [
    //     [event_location, "==", event_name],
    //     ["created_at", ">", filterDate],
    //   ];
    // } else {
    //   defaultpayload = [["created_at", ">", filterDate]];
    // }
    if (isLoginuser == "1" && defaultfilter.length > 0) {
      let convertedData = [];
      if (defaultfilter[0].operatordata == "==") {
        convertedData = defaultfilter.map((item) => [
          item.field,
          "==",
          parseInt(item.fieldvalue),
        ]);
      }
      if (defaultfilter[0].operatordata == "array_contains_any") {
        convertedData = defaultfilter.map((item) => [
          item.field,
          "in",
          item.fieldvalue.split(",").map((num) => parseInt(num)), //[parseInt(item.fieldvalue)],
        ]);
        console.log("convertedData", convertedData);
      }
      payload = [...defaultpayload, ...convertedData];
    }
    let payload_search = JSON.parse(search_by_pair);
    let splitkeypair = [...payload, ...payload_search];
    if (searchconfig) {
      const elementToAdd = ["issearch", "==", [searchconfig]];
      payload = [...splitkeypair, elementToAdd]; // splitkeypair.push(elementToAdd);
    } else {
      payload = search_by_pair;
    }
    // Add bucketFields to the payload
    if (filter_columns) {
      payload.push(["filter_columns", "==", filter_columns]);
    }

    console.log("payload->", payload);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    let url = "";
    if (hideloadmore == "1") {
      url =
        process.env.REACT_APP_URL +
        "/mongo/get/" +
        dbname +
        "/" +
        collectionname +
        "?limit=" +
        limit_per_call +
        "&offset=" +
        offset;
    } else {
      url =
        process.env.REACT_APP_URL +
        "/mongo/get/" +
        dbname +
        "/" +
        collectionname;
    }
    const response = await customFetch(url, requestOptions);
    const data = await response.json();
    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
};
