import React, { Component,useState,useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { logout, navtime } from "./Utility";
//import { logout, navtime, netspeed } from "./Utility";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import DragIcon from "../assets/images/draggable.png";


export default function ChatSupportBtn(props)  {
  
  const [uname,setUname] = useState(localStorage.getItem("employee_name"));
  const ref = useRef(null);
  const [border,setborder] = useState(false);
  const [currentPosition, setCurrentPosition] = useState({
    xRate: 0,
    yRate: 0
  });

  const onDrag = (e, data) => {
    setCurrentPosition({ xRate: data.lastX, yRate: data.lastY });
  };

  const handleClick = (e) => {
    e.preventDefault();
    props.history.push("/createsupportticket");
  };

  useEffect(()=>{
    console.log(border)
  },[border])
    
    var beforeload = (new Date()).getTime();
    return (
        <Draggable
        ref={ref}
        position={{
          x: currentPosition.xRate,
          y: currentPosition.yRate
        }}
        axis="x"
        onDrag={onDrag}
        onTouchStart={()=>{console.log('ontouchstart');setborder(true)}}
        onTouchEnd = {()=>{console.log('ontouchend');setborder(false)}}
        onMouseUp = {()=>{console.log('onmouseup');setborder(true)}}
        onMouseDown = {()=>{console.log('onmousedown');setborder(false)}}
        handle=".handle"
      >
      <div className="support_section">
        <div className="handle support_drag_handle support_drag_handle_hovered">
          <img src={DragIcon} draggable={false} alt=""/>
        </div>
        <div className="navtime">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-clock-history"
            viewBox="0 0 16 16"
          >
            <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
            <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
            <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
          </svg>
          {navtime(beforeload)}
        </div>
        <div className="loguname" >
          <svg viewBox="0 0 130 50">
            <path
              id="MyPath"
              fill="none"
              stroke="lightblue"
              d="m10,50c53,-33 96,3 96,3"
            />

            <text>
              <textPath href="#MyPath">Hi, {uname}</textPath>
            </text>
            
          </svg>
        </div>
       
        <button className="chatSupportBtn" onClick={handleClick}>
          <img draggable={false}  src={require("../assets/images/live-chat.png")} />
        </button>
      </div>
        </Draggable>

    );
}
