import React, { Component } from "react";
import customFetch from "./apicall/api";
import { logout, chkapiinput, filterData, generateId } from "./Utility";
import { ThemeContext } from "../context/IsLoader";
const generate_Id1 = generateId(10);
export default class ReversalDashboard extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      api_token: localStorage.getItem("api_token"),
      reversals: [],
      status: ["Submitted", "Accepted", "Rejected"],
      search_text: "",
      isSpin: false,
      flag: 0,
    };
  }

  componentDidMount() {
    if (
      window.Android &&
      typeof window.Android.getDataFromLocalStorage === "function"
    ) {
      const data = window.Android.getDataFromLocalStorage("your_key");
      if (data) {
        this.setState({ isSpin: false, flag: 1 }); // Set flag to 1 if accessed from Android
        const tokenParts = data.split(",");
        const tokenData = {};

        tokenParts.forEach((part) => {
          const [key, value] = part.split(":");
          tokenData[key] = value;
        });

        console.log("AndroidInterface->", data);
      }
    } else {
      console.log(
        "Error in AndroidInterface: Android method not found or not available."
      );
    }

    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
    this.setState({ isSpin: true });
    this.fetchDetails().then((data) => {
      let _filters = { status: [2] };
      const _reversals = filterData(data, _filters, []);
      this.setState({
        unsorted_reversals: data,
        reversals: _reversals,
        isSpin: false,
      });
    });
  }

  fetchDetails = async () => {
    try {
      const uid = localStorage.getItem("in_userid");
      const api_token = this.state.api_token;
      /*Check if data is null then logout*/
      var arr = [uid];
      var chkdata = chkapiinput(arr);
      if (chkdata === false) {
        this.setState({ isSpin: false });
        setTimeout(function() {
          logout();
        }, 2000);
      }
      /*Check if data is null then logout End*/
      const params = {
        userid: uid,
      };
      const queryParams = new URLSearchParams(params).toString();
      let generate_Id = generateId(10);
      const requestOptions = {
        method: "GET",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
      };

      const response = await customFetch(
        process.env.REACT_APP_URL + "/reversal/get/submissions?" + queryParams,
        requestOptions
      );
      const data = await response.json();
      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  handleSearchInput = (e) => {
    this.setState({ search_text: e.target.value.toUpperCase() });
  };

  onSearch = () => {
    const _sorted = this.state.unsorted_reversals.filter((i) => {
      return (
        i.household_number.match(this.state.search_text) ||
        i.txn_ref_no.match(this.state.search_text)
      );
    });
    this.setState({ reversals: _sorted });
  };

  setFilter = (e, type) => {
    let _filters = { status: [parseInt(e.target.id)] };
    const _reversals = filterData(this.state.unsorted_reversals, _filters, []);
    this.setState({ reversals: _reversals });
  };
  // logout = () => {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // };
  render() {
    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div className="container">
          <div className="reversal_navbar">
            <button
              className="button back_button"
              onClick={() => this.props.history.goBack()}
            >
              <img src={require("../assets/images/back.png")} />
            </button>
            <span>Reversal Dashboard</span>
            {this.state.flag === 0 && (
            <button className="button" onClick={() => logout()}>
              <img src={require("../assets/images/power-off.png")} alt="" />
            </button>
            )}
          </div>
          <div className=" reversal_dashboard_search card px-3 mt-3 py-2">
            {/* <input
              type="text"
              value={this.state.search_text}
              onChange={this.handleSearchInput}
            />
            <button
              className="btn btn-primary rbtn btn-md col-4 col-md- col-lg-"
              onClick={this.onSearch}
            >
              Search
            </button> */}
            <div className="row">
              <div className="col-md-6 m-2">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.search_text}
                  onChange={this.handleSearchInput}
                  placeholder="Enter Txn Ref No, Household number"
                />
              </div>
              <div className="col-md-3 m-2 d-flex justify-content-between">
                <button
                  className="btn btn-primary btn-block"
                  onClick={this.onSearch}
                >
                  Search
                </button>
                <div className="reversal_dashboard_filter ml-3">
                  <div className="dropdown">
                    <button data-toggle="dropdown">
                      Category
                      <img
                        src={require("../assets/images/drop-menu-icon.png")}
                      />
                    </button>
                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg-left">
                      {this.state.status.map((status, index) => {
                        return (
                          <label
                            onClick={(e) => this.setFilter(e, "status")}
                            id={index + 2}
                            className="dropdown-item d-flex align-items-center mt-0 mb-0"
                          >
                            {status}
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column justify-content-center mt-2">
            {this.state.reversals &&
              this.state.reversals.map((reversal, index) => {
                const submitted_at = reversal.submitted_at.split(" ");
                let str = reversal.household_name;
                let acronym = str
                  .split(/\s/)
                  .reduce(
                    (response, word) => (response += word.slice(0, 1)),
                    ""
                  );

                return (
                  <a
                    key={index}
                    className="reversal_trans_card mt-3"
                    onClick={() => {
                      this.props.history.push({
                        pathname: "/reversaldetail",
                        state: { txn_ref_no: reversal.txn_ref_no },
                      });
                    }}
                  >
                    <div className="reversal_trans_card_header">
                      <div>{acronym.toUpperCase()}</div>
                      <ul className="reversal_hh_info">
                        <li>Ref no: {reversal.txn_ref_no}</li>
                        <li>HH ID : {reversal.household_number}</li>
                        <li>{reversal.household_name}</li>
                      </ul>
                    </div>
                    <ul className="reversal_raised_by">
                      <li>Raised by: {reversal.employee_name}</li>
                    </ul>
                    <div className="reversal_trans_card_amount">
                      <span>Amount :</span>
                      <span>Rs. {reversal.amount.toLocaleString()}</span>
                    </div>
                    <div className="reversal_trans_card_footer">
                      <ul>
                        <li>
                          Date :{" "}
                          {submitted_at[1] +
                            " " +
                            submitted_at[2] +
                            " " +
                            submitted_at[3]}
                        </li>
                        <li>Time : {submitted_at[4]}</li>
                      </ul>
                      <div>{this.state.status[reversal.status - 2]}</div>
                    </div>
                  </a>
                );
              })}
          </div>
        </div>{" "}
      </>
    );
  }
}
