import React, { Component } from "react";
import customFetch from "../components/apicall/api";
import db from "../firebase";
import { getFirestore, collection, addDoc } from "firebase/firestore";

export const ThemeContext = React.createContext({ isLoaded: "aaaaa" });

export class ThemeContextProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoaded: "is This Loader",
      setisLoaded: this.setisLoaded,
      ip_address: "",
      setip: this.setip,
      contextuser_id: "",
      setcontextuser_id: this.setcontextuser_id,
      payloadId: "",
      ui_loadeddata: [],
      onPageLoad: this.onPageLoad,
      generateId: this.generateId,
      otherscreen: "",
      setotherscreen: this.setotherscreen,
      no_of_page: "",
      setno_of_page: this.setno_of_page,
    };
  }
  componentDidMount() {}
  // onPageLoad = (data) => {
  //   this.setState({
  //     ui_loadeddata: "",
  //   });

  //   let api_data = {};

  //   api_data.ip_address = this.state.ip_address;
  //   console.log("onPageLoad", api_data);
  //   let bucketName =
  //     process.env.REACT_APP_environment + "_frontend_req_payload";
  //   var jsonformdata = "";
  //   if (data.data) {
  //     var object = {};
  //     data.data.forEach((value, key) => (object[key] = value));
  //     jsonformdata = JSON.stringify(object);
  //   }
  //   api_data.created_at = new Date().toLocaleString();
  //   api_data.added_at = moment().unix();
  //   api_data.component_id = data.component_id || null;
  //   api_data.component_name = data.component || null;
  //   function perfObserver(list, observer) {
  //     let data = list.getEntries();
  //     //console.log("Performance Observer Data =>", data);
  //     const result = data.filter((item) =>
  //       item.name.includes("component-render-start")
  //     );
  //     if (result.length > 0) {
  //       for (const entry of result) {
  //         api_data.startTime = entry.startTime || null;
  //       }
  //     }
  //     const result1 = data.filter((item) =>
  //       item.name.includes("component-render-stop")
  //     );
  //     if (result1.length > 0) {
  //       for (const entry of result1) {
  //         api_data.endTime = entry.startTime || null;
  //       }
  //     }
  //     api_data.Duration = api_data.endTime - api_data.startTime || null;
  //   }
  //   const observer = new PerformanceObserver(perfObserver);
  //   observer.observe({ entryTypes: ["measure", "mark"] });

  //   this.setState({
  //     ui_loadeddata: api_data,
  //   });
  //   if (this.state.ui_loadeddata != null || this.state.ui_loadeddata != "") {
  //     console.log("ui_data", this.state.ui_loadeddata);
  //     addDoc(collection(db, bucketName), this.state.ui_loadeddata)
  //       .then((docRef) => {
  //         console.log("Document written with ID: ", docRef.id);
  //       })
  //       .catch(function(error) {
  //         console.error("Error writing Value: ", error);
  //       });
  //   }
  //   //);
  // };
  onPageLoad = (data) => {
    this.setState({
      ui_loadeddata: "",
    });

    let api_data = {};
    //let IPv4;
    // fetch("https://api.ipify.org?format=json")
    //   .then((response) => {
    //     return response.json();
    //   }, "jsonp")
    //   .then((res) => {
    //     console.log(res);
    //     this.setState(
    //       {
    //         IPv4: res.ip,
    //       },
    //       () => {
    //         api_data.ip_address = this.state.IPv4|| null;
    //       }
    //     );
    //     //IPv4 = res.IPv4;
    //   })
    //   .catch((err) => console.log(err));
    api_data.ip_address = this.state.ip_address || null;
    console.log("onPageLoad", api_data);
    let bucketName =
      process.env.REACT_APP_ENVIRONMENT + "_frontend_req_payload";
    var jsonformdata = "";
    if (data && data.data) {
      var object = {};
      data.data.forEach((value, key) => (object[key] = value));
      jsonformdata = JSON.stringify(object);
    }
    api_data.created_at = new Date().toLocaleString();
    api_data.component_id = data && data.component_id || null;
    api_data.component_name = data && data.component || null;

    const observer = new PerformanceObserver((list, observer) => {
      list.getEntries().forEach((entry) => {
        //console.log("Performance Entry:", entry);
        // Handle and process the performance data here
        if (entry.name.includes("component-render-start")) {
          api_data.startTime = entry.startTime || null;
        }
        if (entry.name.includes("component-render-stop")) {
          api_data.endTime = entry.startTime || null;
        }
      });
      api_data.Duration = api_data.endTime - api_data.startTime || null;
    });

    observer.observe({ entryTypes: ["paint", "mark", "measure"] });

    // Don't forget to disconnect the observer when the component unmounts
    this.performanceObserver = observer;

    // const perfObserver = (list, observer) => {
    //   let data = list.getEntries();
    //   //console.log("Performance Observer Data =>", data);
    //   const result = data.filter((item) =>
    //     item.name.includes("component-render-start")
    //   );
    //   if (result.length > 0) {
    //     //let resdata = [];
    //     let Observer_data = [];
    //     //result.map((data) => resdata.push(data));
    //     for (const entry of result) {
    //       api_data.startTime = entry.startTime || null;
    //     }
    //     // ui_data.performance_data = Observer_data;
    //     //console.log("startTime =>", api_data.startTime);
    //   }

    //   const result1 = data.filter((item) =>
    //     item.name.includes("component-render-stop")
    //   );
    //   if (result.length > 0) {
    //     //let resdata = [];
    //     let Observer_data = [];
    //     //result.map((data) => resdata.push(data));
    //     for (const entry of result1) {
    //       api_data.endTime = entry.startTime || null;
    //     }
    //     // ui_data.performance_data = Observer_data;
    //     //console.log("endTime =>", api_data.endTime);
    //   }

    //   api_data.Duration = api_data.endTime - api_data.startTime || null;
    //   //item.name.includes("component-render-stop")
    // };
    // const observer = new PerformanceObserver(perfObserver);
    // observer.observe({ entryTypes: ["measure", "mark"] });

    console.log("api_data", api_data);
    this.setState(
      {
        ui_loadeddata: api_data,
      },
      () => {
        console.log("ui_loadeddata", this.state.ui_loadeddata);
        setTimeout(() => {
          addDoc(collection(db, bucketName), this.state.ui_loadeddata)
            .then((docRef) => {
              console.log("Document written with ID: ", docRef.id);
            })
            .catch(function(error) {
              console.error("Error writing Value: ", error);
            });
        }, 1000);
      }
    );

    //);
  };
  setip = (a) => {
    this.setState({
      ip_address: a,
    });
  };
  setno_of_page = (a) => {
    this.setState({
      no_of_page: a,
    });
  };
  setotherscreen = (a) => {
    this.setState({
      otherscreen: a,
    });
  };
  setcontextuser_id = (a) => {
    this.setState({
      contextuser_id: a,
    });
  };
  setisLoaded = (a) => {
    this.setState(
      {
        isLoaded: a,
      },
      () => {
        console.log("Update", this.state.isLoaded);
      }
    );
  };

  render() {
    const {
      isLoaded,
      setisLoaded,
      setcontextuser_id,
      contextuser_id,
      payloadId,
      onPageLoad,
      generateId,
      setno_of_page,
      setotherscreen,
      no_of_page,
      otherscreen,
      setip,
    } = this.state;
    //const {setisLoaded} = this;
    return (
      <>
        <ThemeContext.Provider
          value={{
            isLoaded: isLoaded,
            setisLoaded: setisLoaded,
            setcontextuser_id: setcontextuser_id,
            contextuser_id: contextuser_id,
            payloadId: payloadId,
            onPageLoad: onPageLoad,
            generateId: generateId,
            setno_of_page: setno_of_page,
            setotherscreen: setotherscreen,
            no_of_page: no_of_page,
            otherscreen: otherscreen,
            setip: setip,
          }}
        >
          {this.props.children}
        </ThemeContext.Provider>
      </>
    );
  }
}
