import React, { Component } from "react";
import customFetch from "./apicall/api";
import { logout, generateId } from "./Utility";
import Webcam from "react-webcam";
import { ThemeContext } from "../context/IsLoader";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
  facingMode: FACING_MODE_USER,
};
let intervalId;

const generate_Id1 = generateId(10);
class Group_esign extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.webcamRef = React.createRef(null);
    this.state = {
      signersdata: [],
      loan_doc_file: "",
      loan_id: "",
      group_id: "",
      isSpin: false,
      OpenCam: false,
      isdocid: false,
      signingStatusWithFlagB: false,
      facingMode: FACING_MODE_USER,
      isGenerate: false,
      isDownload: false,
      isProcessDoc: false,
      isDelete: false,
      isUploadPopupOpen: false,
    };
  }

  componentDidMount() {
    document.title = "E-sign";
    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    const { modifiedArray } =
      (this.props.location && this.props.location.state) || {};
      console.log("group_id",modifiedArray);
    if (modifiedArray) {
      this.setState(
        {
          group_id: modifiedArray.group_id,
        },
        () => this.getsigners()
      );
    }
  }

  componentDidUpdate() {
    if (this.state.isProcessDoc == true) {
      if (!intervalId) {
        intervalId = setInterval(() => {
          this.check_isdocsign();
        }, 30000);
      }
    } else {
      clearInterval(intervalId);
      intervalId = null;
    }
  }
  componentWillUnmount() {
    this.setState({
      isProcessDoc: false,
    });
    intervalId = null;
  }
  check_isdocsign = () => {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
      customFetch(
      process.env.REACT_APP_URL +
        "/get/groupid/payloads?group_id=" +
        this.state.group_id,
      {
        method: "GET",
        headers: { "api-token": api_token, payload_id: this.context.payloadId },
      }
    )
      .then((res) => {
        if (res.status != 200) {
          this.setState({
            isErrOpen: true,
            api_error_message: "Please Contact Techincal Team.",
            isSpin: false,
          });
        }
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }

        if (
          data.document.blob_url != null &&
          data.document.document_status == true
        ) {
          this.setState({
            isProcessDoc: false,
          });

          const signersWithFlagB = data.signers.filter(
            (signer) => signer.flag === "B"
          );
          const signingStatusWithFlagB = signersWithFlagB.map(
            (signer) => signer.signing_status
          );

          this.setState({
            signersdata: data,
            isSpin: false,
            signingStatusWithFlagB: signingStatusWithFlagB[0],
          });

          data.signers.map((sign) =>
            this.getprofilephoto(
              sign.profile_photo_filename,
              sign.family_member_id
            )
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  change_cam() {
    this.setState((prevState) => ({
      facingMode:
        prevState.facingMode === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER,
    }));
  }
  initDigio = (documentId, identifier, access_token, sign_method) => {
    if (
      (documentId == "" || documentId == null) &&
      (access_token == "" || access_token == null)
    ) {
      this.setState({
        isdocid: true,
      });
    } else {
      let environment = "";
      if (process.env.REACT_APP_ENVIRONMENT == "prod")
        environment = "production";
      else environment = "sandbox";
      const digoOptions = {
        environment: environment,
        logo:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKoCImzRGYjIXBOLGvldo_SKveUrqk1OiXhIg90e5XGQ&s",
        is_iframe: true,
        method: sign_method,
      };
      digoOptions.callback = (_digio) => {
        if (_digio.error_code === "CANCELLED") {
          console.log("Flow cancelled by user");
          return;
        }
        if (_digio.error_code !== undefined) {
          throw new Error(_digio.message);
        }
        console.log(_digio);
        this.getsigners();
      };

      const digioInstance = new window.Digio(digoOptions);
      digioInstance.init();
      digioInstance.submit(documentId, identifier, access_token);
    }
  };
  getprofilephoto = async (path, member) => {
    if (path) {
      const { api_token } =
        (this.props.location && this.props.location.state) || {};
      const formdata = new FormData();

      formdata.append("blob_url", path);
      let generate_Id = generateId(10);
      const requestOptions = {
        method: "POST",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
        body: formdata,
      };

      customFetch(process.env.REACT_APP_URL + "/get/public/url", requestOptions)
        .then((res) => {
          if (res.status != 200) {
            this.setState({
              isErrOpen: true,
              api_error_message: "Please Contact Techincal Team.",
              isSpin: false,
            });
          }
          if (res.api_code === 4) {
            localStorage.clear();
            this.props.history.push("/");
          }
          return res.json();
        })
        .then((img) => {
          let member1 = "member_" + member;

          this.setState({
            [member1]: img.public_url,
          });
          this.getFileMetadata(img.public_url, member);
        });
    }
  };

  getFileMetadata = async (fileUrl, member) => {
    console.log("fileUrl-->", fileUrl);

    try {
      const response = await fetch(fileUrl, { method: "HEAD" });

      if (response.ok) {
        const metadata = {
          contentType: response.headers.get("content-type"),
          contentLength: response.headers.get("content-length"),
          lastModified: response.headers.get("last-modified"),
        };
        const contentLength = response.headers.get("content-length");

        const sizeInMB = contentLength
          ? (contentLength / (1024 * 1024)).toFixed(2)
          : null;

        console.log("metadata->", sizeInMB);
        let memberImg = "memberImg_" + member;
        this.setState({
          [memberImg]: sizeInMB,
        });
      } else {
        console.error(
          "Failed to fetch file metadata. Status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error fetching file metadata:", error);
    }
  };

  getsigners() {
    this.setState({ isSpin: true });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    let generate_Id = generateId(10);
    customFetch(
      process.env.REACT_APP_URL +
        "/get/groupid/payloads?group_id=" +
        this.state.group_id,
      {
        method: "GET",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
      }
    )
      .then((res) => {
        if (res.status != 200) {
          this.setState({
            isErrOpen: true,
            api_error_message: "Please Contact Techincal Team.",
            isSpin: false,
          });
        }
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        if (data.message) {
          this.setState({
            isErrOpen: true,
            api_error_message: data.message,
            isSpin: false,
          });
        } else {
          if (
            data.document.mid_doc_file_path == null &&
            data.document.blob_url == null &&
            data.document.document_status == true
          ) {
            this.setState({
              isProcessDoc: true,
            });
          } else {
            this.setState({
              isProcessDoc: false,
            });
          }

          const signersWithFlagB = data.signers.filter(
            (signer) => signer.flag === "B"
          );
          const signingStatusWithFlagB = signersWithFlagB.map(
            (signer) => signer.signing_status
          );

          this.setState({
            signersdata: data,
            isSpin: false,
            signingStatusWithFlagB: signingStatusWithFlagB[0],
          });

          data.signers.map((sign) =>
            this.getprofilephoto(
              sign.profile_photo_filename,
              sign.family_member_id
            )
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  capture = () => {
    const imageSrc = this.webcamRef.current.getScreenshot();
    this.setState({
      tempImg: imageSrc,
    });
    const fileSize = imageSrc.length * (3 / 4) - 2;
  };

  setcaptureimg = () => {
    let newImage = "member_" + this.state.family_member_id;
    this.setState({
      [newImage]: this.state.tempImg,
      OpenCam: false,
    });
    const fileType = this.state.tempImg.substring(
      this.state.tempImg.indexOf("/") + 1,
      this.state.tempImg.indexOf(";")
    );

    const fileName =
      this.state.group_id + "_" + this.state.family_member_id + "." + fileType;

    let blob = this.base64ToBlob(this.state.tempImg)
      .then((blob) => {
        this.blobToFormData(blob, fileName);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  blobToFormData = (blob, fileName) => {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const formData = new FormData();
    formData.append("file", blob, fileName);
    formData.append("blob_url", "navadhanftp/KYC/" + fileName);
    formData.append("flag", "profile_image");
    formData.append("family_member_id", this.state.family_member_id);
    formData.append(
      "loan_documentation_id",
      this.state.signersdata.loan_documentation_id
    );
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
      body: formData,
    };
    customFetch(
      process.env.REACT_APP_URL + "/save/files/bucket",
      requestOptions
    )
      .then((response) => {
        if (response.status != 200) {
          this.setState({
            isErrOpen: true,
            api_error_message: "Please Contact Techincal Team.",
            isSpin: false,
          });
        }
        if (response.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: response.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        console.log("data->", data);
        this.getprofilephoto(data[0].blob_url, this.state.family_member_id);
      })
      .catch((error) => console.log("error", error));
  };
  base64ToBlob = async (base64String) => {
    const response = await fetch(base64String);
    const blob = await response.blob();
    return blob;
  };
  editmobile = (mid) => {
    let mobileEdit = "hhmobileedit_" + mid;
    this.setState({
      [mobileEdit]: 1,
    });
  };
  changewitnessesmobile = (e, mid, index) => {
    let valid = true;
    let errmobile = "Errormobile_" + mid;
    if (isNaN(e.target.value)) {
      let validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (e.target.value.match(validRegex)) {
        valid = true;
        this.setState({
          [errmobile]: "",
        });
      } else {
        valid = false;
        this.setState({
          [errmobile]: "Please Enter Valid Email",
        });
      }
    } else {
      if (e.target.value.replace(/\D/g, "").length !== 10) {
        valid = false;
        this.setState({
          [errmobile]: "Please Enter Valid Number",
        });
      } else {
        valid = true;
        this.setState({
          [errmobile]: "",
        });
      }
    }
    let hhmobile = "hhmobile_" + mid;
    this.setState({
      [hhmobile]: e.target.value,
    });
    let signdata = this.state.signersdata;
    signdata.witnesses[index]["mobile"] = e.target.value;
    this.setState({
      signersdata: signdata,
    });
  };
  changehhmobile = (e, mid, index) => {
    let valid = true;
    let errmobile = "Errormobile_" + mid;
    if (isNaN(e.target.value)) {
      let validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (e.target.value.match(validRegex)) {
        valid = true;
        this.setState({
          [errmobile]: "",
        });
      } else {
        valid = false;
        this.setState({
          [errmobile]: "Please Enter Valid Email",
        });
      }
    } else {
      if (e.target.value.replace(/\D/g, "").length !== 10) {
        valid = false;
        this.setState({
          [errmobile]: "Please Enter Valid Number",
        });
      } else {
        valid = true;
        this.setState({
          [errmobile]: "",
        });
      }
    }
    let hhmobile = "hhmobile_" + mid;
    this.setState({
      [hhmobile]: e.target.value,
    });
    let signdata = this.state.signersdata;
    signdata.signers[index]["mobile"] = e.target.value;
    this.setState({
      signersdata: signdata,
    });
  };

  getloandoc = async (filename, path) => {
    this.setState({
      isSpin: true,
    });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const formdata = new FormData();
    formdata.append("blob_url", path);
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "Esign",
        user_id: localStorage.getItem("in_userid"),
      },
      body: formdata,
    };

    customFetch(process.env.REACT_APP_URL + "/get/public/url", requestOptions)
      .then((res) => {
        if (res.status != 200) {
          this.setState({
            isErrOpen: true,
            api_error_message: "Please Contact Techincal Team.",
            isSpin: false,
          });
        }
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }

        if (res.api_code === 4 || res.api_code === 3 || res.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        return res.json();
      })
      .then((img) => {
        this.setState({
          isSpin: false,
        });
        const link = document.createElement("a");
        link.href = img.public_url;
        link.setAttribute("download", filename);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  downloaddocument = async (group_id, download_type) => {
    this.setState({ isSpin: true });

    if (this.state.signersdata.document) {
      const { api_token } =
        (this.props.location && this.props.location.state) || {};

      let generate_Id = generateId(10);
      const requestOptions = {
        method: "GET",
        headers: {
          "api-token": api_token,
          payload_id: this.context.payloadId,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
      };

      try {
        const response = await customFetch(
          process.env.REACT_APP_URL +
            "/esign/leegality/document/download?group_id=" +
            group_id +
            "&download_type=" +
            download_type,
          requestOptions
        );

        if (response.headers.get("content-type") === "application/json") {
          const jsonData = await response.json();
          const errorMessage = jsonData.message;
          alert(errorMessage);
        } else {
          const data = await response.blob();
          let url = window.URL.createObjectURL(data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", group_id + ".pdf");

          document.body.appendChild(link);

          link.click();

          link.parentNode.removeChild(link);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        this.setState({ isSpin: false });
      }
    }
  };

  generateloandoc = () => {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    let generate_Id = generateId(10);
    this.setState({ isSpin: true, isGenerate: false });
    const uid = localStorage.getItem("in_userid");
    const data = this.state.signersdata;
    const transformedData = {
      group_id: data.group_id,
      signers: data.signers.map((signer) => ({
        name: signer.family_member_name,
        mobile: signer.mobile ? signer.mobile.toLowerCase() : "",
        household_id: signer.household_id,
        signed_status: "U",
        family_member_id: signer.family_member_id.toString(),
      })),
      witnesses: data.witnesses.map((witness) => ({
        name: witness.employee_name,
        mobile: witness.mobile ? witness.mobile.toLowerCase() : "",
        household_id: witness.household_id,
        signed_status: "U",
        employee_id: witness.employee_id.toString(),
      })),
    };

    const signersMobiles = transformedData.signers.map(
      (signer) => signer.mobile
    );
    const witnessesMobiles = transformedData.witnesses.map(
      (witness) => witness.mobile
    );

    const allMobiles = [...signersMobiles, ...witnessesMobiles];
    const uniqueMobiles = [...new Set(allMobiles)];

    const isMobilesUnique = allMobiles.length === uniqueMobiles.length;
    if (isMobilesUnique) {
      let _body = JSON.stringify(transformedData);
      customFetch(process.env.REACT_APP_URL + "/esign/dcb/generate/document", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
        body: _body,
      })
        .then((res) => {
          if (res.status != 200) {
            this.setState({
              isErrOpen: true,
              api_error_message: "Please Contact Technical Team.",
              isSpin: false,
            });
          }
          if (res.headers.get("api_code") > 0) {
            this.setState({
              isErrOpen: true,
              api_error_message: res.headers.get("api_error_message"),
              isSpin: false,
            });
          }
          return res.json();
        })
        .then((json) => {
          this.setState({
            isProcessDoc: true,
            isErrOpen: true,
            api_error_message: json.message,
            isSpin: false,
          });
          this.getsigners();
        })
        .catch((error) => console.log("error", error));
    } else {
      this.setState({
        isErrOpen: true,
        api_error_message:
          "Mobile number or email should be unique for each signer and witness.",
        isSpin: false,
      });
    }
    // const requestOptions = {
    //   method: "GET",
    //   headers: {
    //     "api-token": api_token,
    //     component_id: generate_Id1,
    //     apiId: generate_Id,
    //     api_group_id: "",
    //     component: this.constructor.name,
    //     user_id: localStorage.getItem("in_userid"),
    //   },
    // };
    // customFetch(
    //   process.env.REACT_APP_URL +
    //     "/get/configuration/parameter/master?parameter_name=agreement_doc_gen_stage",
    //   requestOptions
    // )
    //   .then((response) => {
    //     if (response.headers.get("api_code") > 0) {
    //       this.setState({
    //         isErrOpen: true,
    //         api_error_message: response.headers.get("api_error_message"),
    //         isSpin: false,
    //       });
    //     }
    //     return response.json();
    //   })
    //   .then((data) => {
    //     console.log("generateloandoc->", data);
    //     if (data) {
    //       let form = [
    //         ["group_id", "==", this.state.group_id],
    //         ["status", "!=", parseInt(data.parameter_value)],
    //       ];
    //       const requestOptions = {
    //         method: "POST",
    //         body: JSON.stringify(form),
    //         headers: {
    //           "api-token": api_token,
    //           component_id: generate_Id1,
    //           apiId: generate_Id,
    //           api_group_id: "",
    //           component: this.constructor.name,
    //           user_id: localStorage.getItem("in_userid"),
    //           "Content-Type": "application/json",
    //         },
    //       };
    //       customFetch(
    //         process.env.REACT_APP_URL +
    //           "/mongo/get/callbacks/household_base_data",
    //         requestOptions
    //       )
    //         .then((response) => {
    //           if (response.headers.get("api_code") > 0) {
    //             this.setState({
    //               isErrOpen: true,
    //               api_error_message: response.headers.get("api_error_message"),
    //               isSpin: false,
    //             });
    //           }
    //           return response.json();
    //         })
    //         .then((data) => {
    //           if (data.length > 0) {
    //             const householdNumbers = data
    //               .map((item) => `${item.household_number}`)
    //               .join(", ");
    //             console.log("householdNumbers", householdNumbers);
    //             this.setState({
    //               isErrOpen: true,
    //               api_error_message:
    //                 householdNumbers + " Have Different Status",
    //               isSpin: false,
    //               isGenerate: false,
    //             });
    //           } else {
    //             this.setState({ isSpin: true, isGenerate: false });
    //             const uid = localStorage.getItem("in_userid");
    //             const data = this.state.signersdata;
    //             const transformedData = {
    //               group_id: data.group_id,
    //               signers: data.signers.map((signer) => ({
    //                 name: signer.family_member_name,
    //                 mobile: signer.mobile ? signer.mobile.toLowerCase() : "",
    //                 household_id: signer.household_id,
    //                 signed_status: "U",
    //                 family_member_id: signer.family_member_id.toString(),
    //               })),
    //               witnesses: data.witnesses.map((witness) => ({
    //                 name: witness.employee_name,
    //                 mobile: witness.mobile ? witness.mobile.toLowerCase() : "",
    //                 household_id: witness.household_id,
    //                 signed_status: "U",
    //                 employee_id: witness.employee_id.toString(),
    //               })),
    //             };

    //             const { api_token } =
    //               (this.props.location && this.props.location.state) || {};
    //             let _body = JSON.stringify(transformedData);
    //             let generate_Id = generateId(10);

    //             fetch(
    //               process.env.REACT_APP_URL + "/esign/dcb/generate/document",
    //               {
    //                 method: "POST",
    //                 headers: {
    //                   "Content-Type": "application/json",
    //                   "api-token": api_token,
    //                   component_id: generate_Id1,
    //                   apiId: generate_Id,
    //                   api_group_id: "",
    //                   component: this.constructor.name,
    //                   user_id: localStorage.getItem("in_userid"),
    //                 },
    //                 body: _body,
    //               }
    //             )
    //               .then((res) => {
    //                 if (res.status != 200) {
    //                   this.setState({
    //                     isErrOpen: true,
    //                     api_error_message: "Please Contact Technical Team.",
    //                     isSpin: false,
    //                   });
    //                 }
    //                 if (res.headers.get("api_code") > 0) {
    //                   this.setState({
    //                     isErrOpen: true,
    //                     api_error_message: res.headers.get("api_error_message"),
    //                     isSpin: false,
    //                   });
    //                 }
    //                 return res.json();
    //               })
    //               .then((json) => {
    //                 this.setState({
    //                   isProcessDoc: true,
    //                   isErrOpen: true,
    //                   api_error_message: json.message,
    //                   isSpin: false,
    //                 });
    //                 this.getsigners();
    //               })
    //               .catch((error) => console.log("error", error));
    //           }
    //         })
    //         .catch((error) => console.log("error", error));
    //     }
    //   })
    //   .catch((error) => console.log("error", error));
  };
  delete_doc = () => {
    this.setState({
      isSpin: true,
      isDelete: false,
    });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};

    let _body = JSON.stringify({
      group_id: this.state.signersdata.group_id,
      status: "status from frontend",
    });
    customFetch(process.env.REACT_APP_URL + "/esign/delete/document", {
      method: "POST",
      headers: {
        "api-token": api_token,
        "Content-Type": "application/json",
      },
      body: _body,
    })
      .then((res) => {
        if (res.status != 200) {
          this.setState({
            isErrOpen: true,
            api_error_message: "Please Contact Techincal Team.",
            isSpin: false,
          });
        }
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })
      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isErrOpen: true,
          api_error_message: json.message,
          isSpin: false,
        });
        this.getsigners();
      })
      .catch((error) => console.log("error", error));
  };

  esign_doc = () => {
    this.setState({
      isSpin: true,
    });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const formdata = new FormData();
    let _body = JSON.stringify({
      group_id: this.state.signersdata.group_id,
    });
    customFetch(process.env.REACT_APP_URL + "/esign/leegality/document/send", {
      method: "POST",
      headers: {
        "api-token": api_token,
        "Content-Type": "application/json",
      },
      body: formdata,
      body: _body,
    })
      .then((res) => {
        if (res.status != 200) {
          this.setState({
            isErrOpen: true,
            api_error_message: "Please Contact Techincal Team.",
            isSpin: false,
          });
        }
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })
      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isErrOpen: true,
          api_error_message: json.message,
          isSpin: false,
        });
        this.getsigners();
      })
      .catch((error) => console.log("error", error));
  };

  toggleUploadPopup = () => {
    this.setState((prevState) => ({
      isUploadPopupOpen: !prevState.isUploadPopupOpen,
    }));
  };

  handleUpload = (uploadType) => {
    this.toggleUploadPopup();
  };

  callenque = (signdata, signers) => {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const formdata = new FormData();
    // formdata.append(
    //   "loan_documentation_id",
    //   this.state.signersdata.loan_documentation_id
    // );
    let _body = {
      payload: {
        messages: [{ message: signdata.message }],
        documentId: signdata.documentId,
        request: {
          name: signers.family_member_name,
          phone: signers.mobile,
        },
        vendor_name: "Leegality",
        event: "esign",
      },
      topic: "Transformation",
    };
    customFetch(process.env.REACT_APP_URL + "/enque/", {
      method: "POST",
      headers: {
        "api-token": api_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(_body),
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })
      .then((json) => {
        console.log(json);
      })
      .catch((error) => console.log("error", error));
  };
  openPopup = (signers) => {
    let obj = {
      logoUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKoCImzRGYjIXBOLGvldo_SKveUrqk1OiXhIg90e5XGQ&s",
      callback: (response) => {
        if (response.error) {
          console.log("Flow cancelled by user", response.error);
          return;
        }
        if (response.message) {
          this.callenque(response, signers);
        }
        console.log(response);
        this.getsigners();
      },
    };

    console.log("url", signers.signUrl);
    var leegality = new window.Leegality(obj);
    leegality.init();
    //You will get a signing url by uploading document from backend.
    //var signingUrl = “your-signing-url”;
    leegality.esign(signers.signUrl);
  };
  render() {
    const { facingMode } = this.state;

    return (
      <>
        {this.state.isSpin ? (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}

        <div className="container">
          <div
            className={
              this.state.isErrOpen
                ? "modal fade bd-example-modal-sm show mymodal"
                : "modal fade bd-example-modal-sm"
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="confirm-box"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content cdb-confirm-box col-sm-12">
                <button
                  type="button"
                  className="close text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ isErrOpen: false })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <p>{this.state.api_error_message}</p>
              </div>
            </div>
          </div>
          <div
            className={
              this.state.isDelete
                ? "modal fade bd-example-modal-sm show mymodal"
                : "modal fade bd-example-modal-sm"
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="confirm-box"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content cdb-confirm-box col-sm-12">
                <button
                  type="button"
                  className="close text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ isDelete: false })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <p>Are you sure to delete document ?</p>
                <div className="cb-buttons">
                  <div className="col-sm-6">
                    <button
                      className="btn btn-primary btn-lg btn-block rbtn"
                      onClick={() => {
                        this.delete_doc();
                      }}
                      //disabled={isDisableBtn}
                    >
                      Yes
                    </button>
                  </div>
                  <div className="col-sm-6">
                    <button
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.setState({ isDelete: false })}
                      className="btn btn-primary btn-lg btn-block rbtn"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              this.state.isGenerate
                ? "modal fade bd-example-modal-sm show mymodal"
                : "modal fade bd-example-modal-sm"
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="confirm-box"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content cdb-confirm-box col-sm-12">
                <button
                  type="button"
                  className="close text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ isGenerate: false })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <p>After Generate Document data can not be change</p>
                <div className="cb-buttons">
                  <div className="col-sm-6">
                    <button
                      className="btn btn-primary btn-lg btn-block rbtn"
                      onClick={() => {
                        this.generateloandoc();
                      }}
                      //disabled={isDisableBtn}
                    >
                      Confirm
                    </button>
                  </div>
                  <div className="col-sm-6">
                    <button
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.setState({ isGenerate: false })}
                      className="btn btn-primary btn-lg btn-block rbtn"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              this.state.isDownload
                ? "modal fade bd-example-modal-sm show mymodal"
                : "modal fade bd-example-modal-sm"
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="confirm-box"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content cdb-confirm-box col-sm-12">
                <button
                  type="button"
                  className="close text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ isDownload: false })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                {this.state.signersdata != "" && (
                  <>
                    {/* <button
                      disabled={
                        this.state.signersdata.document.mid_doc_file_path ==
                        null
                      }
                      className={
                        this.state.signersdata.document.mid_doc_file_path ==
                        null
                          ? "generategrey-btn"
                          : "generate-btn"
                      }
                      onClick={() =>
                        this.getloandoc(
                          this.state.signersdata.document &&
                            this.state.signersdata.document.mid_doc_file_path
                              .split("/")
                              .pop(),
                          this.state.signersdata.document.mid_doc_file_path
                        )
                      }
                    >
                      Download MID
                    </button> */}
                    <button
                      disabled={
                        this.state.signersdata.document.blob_url == null
                      }
                      className={
                        this.state.signersdata.document.blob_url == null
                          ? "generategrey-btn"
                          : "generate-btn"
                      }
                      onClick={() =>
                        this.getloandoc(
                          this.state.signersdata.document &&
                            this.state.signersdata.document.blob_url
                              .split("/")
                              .pop(),
                          this.state.signersdata.document.blob_url
                        )
                      }
                    >
                      Download Unsigned Doc
                    </button>
                    <button
                      className={
                        this.state.signersdata.signers.every(
                          (signer) => signer.signing_status
                        ) === false ||
                        this.state.signersdata.witnesses.every(
                          (witnesses) => witnesses.signing_status
                        ) === false
                          ? "generategrey-btn"
                          : "generate-btn"
                      }
                      disabled={
                        this.state.signersdata.signers.every(
                          (signer) => signer.signing_status
                        ) === false ||
                        this.state.signersdata.witnesses.every(
                          (witnesses) => witnesses.signing_status
                        ) === false
                      }
                      onClick={() =>
                        this.downloaddocument(
                          this.state.signersdata.group_id,
                          "DOCUMENT"
                        )
                      }
                    >
                      Download E-signed Document{" "}
                    </button>
                    <button
                      className={
                        this.state.signersdata.signers.every(
                          (signer) => signer.signing_status
                        ) === false ||
                        this.state.signersdata.witnesses.every(
                          (witnesses) => witnesses.signing_status
                        ) === false
                          ? "generategrey-btn"
                          : "generate-btn"
                      }
                      disabled={
                        this.state.signersdata.signers.every(
                          (signer) => signer.signing_status
                        ) === false ||
                        this.state.signersdata.witnesses.every(
                          (witnesses) => witnesses.signing_status
                        ) === false
                      }
                      onClick={() =>
                        this.downloaddocument(
                          this.state.signersdata.group_id,
                          "AUDIT_TRAIL"
                        )
                      }
                    >
                      Audit Trail
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className={
              this.state.OpenCam
                ? "modal fade bd-example-modal-sm show mymodal"
                : "modal fade bd-example-modal-sm"
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="confirm-box"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content pt-confirm-box col-sm-12">
                {this.state.OpenCam && (
                  <>
                    <div className="webcam">
                      <div>
                        <Webcam
                          audio={false}
                          mirrored={true}
                          ref={this.webcamRef}
                          screenshotFormat="image/jpeg"
                          videoConstraints={{
                            ...videoConstraints,
                            facingMode,
                          }}
                        />

                        <button
                          className="btn btn-primary change_cam"
                          onClick={() => this.change_cam()}
                        >
                          <img
                            className="switch-camera"
                            src={require("../assets/images/camera-switch.png")}
                            alt=""
                          />
                        </button>
                      </div>
                      <div>
                        {this.state.tempImg && (
                          <img
                            className="captured-img"
                            src={this.state.tempImg}
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                    <button
                      className="btn btn-primary btn-lg btn-block rbtn mt-3"
                      onClick={this.capture}
                    >
                      Capture Image
                    </button>
                  </>
                )}
                <div className="cb-buttons">
                  <div className="col-sm-6">
                    {this.state.tempImg && (
                      <button
                        className="btn btn-primary btn-lg btn-block rbtn"
                        onClick={() => {
                          this.setcaptureimg();
                        }}
                        //disabled={isDisableBtn}
                      >
                        Confirm
                      </button>
                    )}
                  </div>
                  <div className="col-sm-6">
                    <button
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.setState({ OpenCam: false })}
                      className="btn btn-primary btn-lg btn-block rbtn"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              this.state.isdocid
                ? "modal fade bd-example-modal-sm show mymodal"
                : "modal fade bd-example-modal-sm"
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="confirm-box"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content pt-confirm-box col-sm-12">
                <button
                  type="button"
                  className="close text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ isdocid: false })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <p>Please Generate Document.</p>
              </div>
            </div>
          </div>
          <div className="payload_view_navbar">
            <button
              className="button back_button"
              onClick={() => this.props.history.goBack()}
            >
              <img src={require("../assets/images/back.png")} alt="" />
            </button>
            <h4>Group Esign</h4>
            <button className="button" onClick={() => logout()}>
              <img src={require("../assets/images/power-off.png")} alt="" />
            </button>
          </div>
          <div className="card-holdermain">
            <div className="search-holder">
              <label className="search-label">Enter Group ID</label>
              <div className="searchinput row">
                <div className="col-md-10 mt-1">
                  <input
                    className="form-control search-input"
                    name="group_id"
                    value={this.state.group_id}
                    onChange={(e) =>
                      this.setState({ group_id: e.target.value })
                    }
                  />
                </div>
                <div className="col-md-2 mt-1">
                  <button
                    className="search-btn"
                    onClick={() => {
                      if ((this.state.group_id || "").trim() !== "") {
                        this.getsigners();
                      } else {
                        alert(
                          "Group ID cannot be empty. Please enter a valid Group ID."
                        );
                      }
                    }}
                  >
                    Fetch
                  </button>
                </div>
              </div>
            </div>
            <div className="main-container mt-4">
              <main className="card-container">
                {this.state.signersdata.signers &&
                  this.state.signersdata.signers.map((signers, index) => (
                    <>
                      <div className="card-holder mt-2" key={index}>
                        <figure>
                          <img
                            src={
                              this.state[
                                `member_${signers.family_member_id}`
                              ] &&
                              this.state[`member_${signers.family_member_id}`]
                            }
                            alt=""
                          />
                          {this.state[
                            `memberImg_${signers.family_member_id}`
                          ] && (
                            <strong
                              className={
                                this.state[
                                  `memberImg_${signers.family_member_id}`
                                ] > 2
                                  ? "clrred"
                                  : "clrgreen"
                              }
                            >
                              {this.state[
                                `memberImg_${signers.family_member_id}`
                              ] + " MB"}
                            </strong>
                          )}
                        </figure>

                        <span
                          className={
                            this.state.signersdata.document.document_status ==
                            true
                              ? "disabledbutton"
                              : ""
                          }
                          onClick={() =>
                            this.setState({
                              OpenCam: true,
                              family_member_id: signers.family_member_id,
                              tempImg: "",
                            })
                          }
                        >
                          <img
                            src={require("../assets/images/camera.png")}
                            alt=""
                          />
                        </span>
                        <div className="applicant-info">
                          <div className="hhnamelabel">
                            {signers.flag == "B" && "Applicant Name"}
                            {signers.flag == "C" && "Applicant Name"}
                            {signers.flag == "D" && "Applicant Name"} :
                          </div>
                          <div className="hhname">
                            {signers.family_member_name}
                          </div>
                          <div className="hhnumber">
                            Mo:{" "}
                            {this.state[
                              `hhmobileedit_${signers.family_member_id}`
                            ] == 1 ? (
                              <input
                                className="form-control"
                                value={
                                  this.state[
                                    `hhmobile_${signers.family_member_id}`
                                  ] == signers.mobile
                                    ? this.state[
                                        `hhmobile_${signers.family_member_id}`
                                      ]
                                    : signers.mobile
                                }
                                defaultValue={signers.mobile}
                                onChange={(e) =>
                                  this.changehhmobile(
                                    e,
                                    signers.family_member_id,
                                    index
                                  )
                                }
                                onBlur={() =>
                                  this.setState({
                                    [`hhmobileedit_${signers.family_member_id}`]: 0,
                                  })
                                }
                              />
                            ) : (
                              signers.mobile
                            )}
                            <img
                              className={
                                this.state.signersdata.document
                                  .document_status == true
                                  ? "iconed disabledbutton"
                                  : "iconed"
                              }
                              onClick={() =>
                                this.editmobile(signers.family_member_id)
                              }
                              src={require("../assets/images/number-edit.png")}
                              alt=""
                            />
                            <p className="errmsg">
                              {
                                this.state[
                                  `Errormobile_${signers.family_member_id}`
                                ]
                              }
                            </p>
                          </div>
                        </div>
                        <section className="imgs">
                          {signers.signUrl && signers.signing_status != true ? (
                            <button
                              className="search-btn p-1"
                              onClick={() => this.openPopup(signers)}
                            >
                              Sign
                            </button>
                          ) : (
                            <button
                              className="search-btn disabledbutton p-1"
                              onClick={() => this.openPopup(signers)}
                            >
                              Sign
                            </button>
                          )}
                        </section>
                        {this.state[`memberImg_${signers.family_member_id}`] >
                        2 ? (
                          <strong className="clrred col-md-12 text-center">
                            Please upload image smaller than 2MB.
                          </strong>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ))}
                {this.state.signersdata.witnesses &&
                  this.state.signersdata.witnesses.map((signers, index) => (
                    <>
                      <div className="card-holder mt-2" key={index}>
                        <figure>
                          <img
                            src={require("../assets/images/default-user.png")}
                            alt=""
                          />
                        </figure>
                        <span></span>
                        <div className="applicant-info">
                          <div className="hhnamelabel">Witnesses Name :</div>
                          <div className="hhname">{signers.employee_name}</div>
                          <div className="hhnumber">
                            Mo:{" "}
                            {this.state[
                              `hhmobileedit_${signers.employee_id}`
                            ] == 1 ? (
                              <input
                                className="form-control"
                                value={
                                  this.state[
                                    `hhmobile_${signers.employee_id}`
                                  ] == signers.mobile
                                    ? this.state[
                                        `hhmobile_${signers.employee_id}`
                                      ]
                                    : signers.mobile
                                }
                                defaultValue={signers.mobile}
                                onChange={(e) =>
                                  this.changewitnessesmobile(
                                    e,
                                    signers.employee_id,
                                    index
                                  )
                                }
                                onBlur={() =>
                                  this.setState({
                                    [`hhmobileedit_${signers.employee_id}`]: 0,
                                  })
                                }
                              />
                            ) : (
                              signers.mobile
                            )}
                            <img
                              onClick={() =>
                                this.editmobile(signers.employee_id)
                              }
                              className={
                                this.state.signersdata.document
                                  .document_status == true
                                  ? "iconed disabledbutton"
                                  : "iconed"
                              }
                              src={require("../assets/images/number-edit.png")}
                              alt=""
                            />
                            <p className="errmsg">
                              {this.state[`Errormobile_${signers.employee_id}`]}
                            </p>
                          </div>
                        </div>
                        <section className="imgs">
                          {signers.signUrl && signers.signing_status != true ? (
                            <button
                              className="search-btn p-1"
                              onClick={() => this.openPopup(signers)}
                            >
                              Sign
                            </button>
                          ) : (
                            <button
                              className="search-btn disabledbutton p-1"
                              onClick={() => this.openPopup(signers)}
                            >
                              Sign
                            </button>
                          )}
                        </section>
                      </div>
                    </>
                  ))}
              </main>
              {this.state.signersdata != "" && (
                <div className="generate text-center">
                  {!this.state.signersdata.document.document_id && (
                    <button
                      disabled={
                        this.state.signersdata.document.document_status ==
                          true || this.state.isProcessDoc
                      }
                      className={
                        this.state.signersdata.document.document_status ==
                          true || this.state.isProcessDoc
                          ? "generategrey-btn"
                          : "generate-btn"
                      }
                      onClick={() => this.setState({ isGenerate: true })}
                    >
                      {this.state.isProcessDoc ? (
                        <img
                          src={require("../assets/images/loader-inf.gif")}
                          className="inf_loader"
                          alt=""
                        />
                      ) : (
                        "Generate"
                      )}
                    </button>
                  )}
                  <button
                    disabled={
                      this.state.signersdata.document.mid_doc_file_path ==
                        null && this.state.signersdata.document.blob_url == null
                    }
                    className={
                      this.state.signersdata.document.mid_doc_file_path ==
                        null && this.state.signersdata.document.blob_url == null
                        ? "generategrey-btn"
                        : "generate-btn"
                    }
                    onClick={() => this.setState({ isDownload: true })}
                  >
                    Download Document
                  </button>
                  <button
                    disabled={!this.state.signersdata.document.document_status}
                    className={
                      !this.state.signersdata.document.document_status
                        ? "generategrey-btn"
                        : "generate-btn"
                    }
                    onClick={() => this.setState({ isDelete: true })}
                  >
                    Delete Document
                  </button>
                  {!this.state.signersdata.document.document_id && (
                    <button
                      disabled={
                        this.state.signersdata.document.blob_url == null
                      }
                      className={
                        this.state.signersdata.document.blob_url == null
                          ? "generategrey-btn"
                          : "generate-btn"
                      }
                      onClick={() => this.esign_doc()}
                    >
                      E-sign
                    </button>
                  )}
                  {this.state.isUploadPopupOpen && (
                    <div className="upload-popup">
                      <button
                        className="close-btn"
                        onClick={() => this.toggleUploadPopup()}
                      >
                        &times;
                      </button>
                      <div className="upload-content">
                        <button onClick={() => this.handleUpload("mid")}>
                          Upload MID
                        </button>
                        <br />
                        <button onClick={() => this.handleUpload("esign")}>
                          Upload Esign Docs
                        </button>
                      </div>
                    </div>
                  )}

                  {this.state.signersdata.signers.every(
                    (signer) => signer.signing_status
                  ) === true &&
                    this.state.signersdata.witnesses.every(
                      (witnesses) => witnesses.signing_status
                    ) === true &&
                    this.state.signersdata.document.blob_url != null && (
                      <button
                        className="generate-btn"
                        onClick={() => this.toggleUploadPopup()}
                      >
                        Upload
                      </button>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Group_esign;
