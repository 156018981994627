import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
var firebaseConfig = {
  apiKey: "AIzaSyD0uKECDPafqdaGtB1soay_nQGBRaAmjB4",
  authDomain: "omega-sorter-353009.firebaseapp.com",
  projectId: "omega-sorter-353009",
  storageBucket: "omega-sorter-353009.appspot.com",
  messagingSenderId: "585650645363",
  appId: "1:585650645363:web:3d5d71b80550e5acf83d06",
  measurementId: "G-WZXR1E6DZN",
};

initializeApp(firebaseConfig);
const messaging = getMessaging();
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;
export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BCsXL_EI6PZuso3Nw2d06jaYN0wdONP8l0AxUGau4hTav35Uxmj_zFDCzFowPDvhZtiHIKIo26P0Qgcmqs9aXMg",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        // Perform any other neccessary action with the token
        localStorage.setItem("currentToken", currentToken);
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};
