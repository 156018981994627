import React, { Component } from "react";
import PropTypes from "prop-types";

export class Nach_success extends Component {
  static propTypes = {};
  render() {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("token_id");
    console.log(id); // 55 test null
    const api_token = localStorage.getItem("api_token");
    return (
      <section className="main-sec">
        <div className="container fix-width">
          <div className="dashboard-card-holder pt-5 mt-5">
            <div className="card card-border">
              <div className="card-body">
                <div className="nach_suc_title">
                  <h2>
                    <span>Token ID :</span> {id}
                  </h2>
                  <h2>
                    Your NACH Registration Is Success.{" "}
                    <img
                      src={require("../assets/images/verify-green.png")}
                      alt=""
                    />
                  </h2>
                </div>
                <div className="text-center success-homebtn mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      this.props.history.push({
                        pathname: "/Dashboard",
                        state: { api_token: api_token },
                      })
                    }
                  >
                    <img
                      src={require("../assets/images/home-white.png")}
                      alt=""
                    />{" "}
                    Home
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Nach_success;
