import React, { Component } from "react";
import { IdleTimerProvider } from "react-idle-timer";
import { Redirect } from "react-router-dom";
import { logout } from "./Utility";
import { ThemeContext } from "../context/IsLoader";

class IdleTimeout extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {};
    //this.onIdle = this.onIdle.bind(this);
  }

  // onIdle() {
  //   console.log("user is idle");
  //   //this.props.history.push('/');
  //   localStorage.clear();
  //   window.location.href = "/";
  // }

  render() {
    return (
      <>
        <IdleTimerProvider
          timeout={1000 * 60 * 15}
          onIdle={() =>
            logout(this.context.otherscreen, this.context.no_of_page)
          }
          //onIdle={this.onIdle}
        ></IdleTimerProvider>
      </>
    );
  }
}
export default IdleTimeout;
