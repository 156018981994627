import React, { Component, useState } from "react";
import customFetch from "./apicall/api";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "antd/dist/antd.css";
import { Switch } from "antd";
import { logout, chkapiinput } from "./Utility";

export default class CallListCSO extends Component {
  constructor(props) {
    super(props);

    this.state = {
      household_id: props.location.state.household_id,
      isVisible: "",
      // incorrect_mobile_number: 0,
      // unable_to_reach: 0,
      //preferred_date: new Date(),
      call_duration: 0,
      preferred_mode: "",
      status: 1,
      loan_data: [],
      nach_flag: props.location.state.nach_flag,
      isDisable: true,
      isErrOpen: false,
      api_error_message: "",
      isOpen: false,
      nach_mapid: "",
      prevloans: [],
      isSpin: false,
      NACH_form_upload: "",
      ISNACH_form_upload: false,
    };
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  componentDidMount() {
    const { Preferred_pay_date } =
      (this.props.location && this.props.location.state) || {};
    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
    // if (Preferred_pay_date !== "" || Preferred_pay_date !== null) {
    //   this.setState({
    //     preferred_date: moment(Preferred_pay_date).format("YYYY-MM-DD"),
    //   });
    // }
    // this.setState({
    //   'currentDate': this.getDate(new Date(Date.now()))
    // });
    this.getloandata();
  }
  getloandata() {
    this.setState({ isSpin: true });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    /*Check if data is null then logout*/
    var arr = [this.state.household_id];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }
    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    const params = {
      in_household_id: this.state.household_id,
    };
    const queryParams = new URLSearchParams(params).toString();
    customFetch(
      process.env.REACT_APP_URL +
        "/get/all/loan/detail/household?" +
        queryParams,
      {
        method: "GET",
        headers: { "api-token": api_token },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        console.log("loan_data - ", json);
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isSpin: false,
          loan_data: json,
        });
        json.forEach((element) => {
          if (this.state.prevloans.indexOf(element) == -1) {
            this.state.prevloans.push({
              loan_id: element.loan_id,
              map_id: element.map_id,
              account_number: element.nach_details_account_number,
              nach_token_id: element.nach_details_nach_token_id,
              nach_type: element.nach_details_nach_type,
              prev_nach_type_flag: element.nach_details_nach_status_type_flag,
              prev_loan_documentation_id: element.loan_documentation_id,
            });
          }
        });
      })
      .catch((error) => console.log("error", error));
  }
  // logout() {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // }

  handleChange(e, loan_documentation_id) {
    if (e.target.name === "preferred_date_" + loan_documentation_id) {
      this.setState(
        {
          [e.target.name]: moment(e.target.value).format("YYYY-MM-DD"),
          isDisable: false,
        },
        () => {
          console.log("pref date:", e.target.name);
        }
      );
    }
    if (e.target.name === "preferred_mode_" + loan_documentation_id) {
      if (e.target.value === "H") {
        this.setState({
          ...this.state,
          [`ispreferred_date_${loan_documentation_id}`]: 0,
        });
      } else {
        this.setState({
          ...this.state,
          [`ispreferred_date_${loan_documentation_id}`]: 1,
        });
      }
      this.setState({ [e.target.name]: e.target.value, isDisable: false });
    }
  }
  handleChangebal(e, loan_documentation_id) {
    //if (e.target.name === "balance_maintain") {
    console.log("Tareget name", e);
    let field_name = `isVisible_${loan_documentation_id}`;
    //field_name=0;
    //this.setState({ [e.target.name]: e.target.checked ? 1 : 0 });
    if (!e) {
      //this.setState({ isVisible: true });
      this.setState(
        { ...this.state, [`isVisible_${loan_documentation_id}`]: 1 },
        () =>
          console.log(
            "Dynamic1->" + loan_documentation_id,
            this.state.loan_documentation_id
          )
      );
    } else {
      this.setState(
        { ...this.state, [`isVisible_${loan_documentation_id}`]: 0 },
        () =>
          console.log(
            "Dynamic2->" + loan_documentation_id,
            this.state.field_name
          )
      );
    }
    return;
    //}
  }
  getDate = (date) => {
    return date
      .toUTCString()
      .split(" ")
      .slice(1, 4)
      .join(" ");
  };
  onSubmitHandler(e) {
    e.preventDefault();
    this.setState({ isSpin: true });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const uid = localStorage.getItem("in_userid");
    for (let index = 0; index < this.state.loan_data.length; index++) {
      if (
        this.state[
          `preferred_date_${this.state.loan_data[index].loan_documentation_id}`
        ] ||
        this.state[
          `preferred_mode_${this.state.loan_data[index].loan_documentation_id}`
        ]
      ) {
        /*Check if data is null then logout*/
        var arr = [
          uid,
          this.state.household_id,
          this.state.loan_data[index].loan_documentation_id,
        ];
        var chkdata = chkapiinput(arr);
        if (chkdata === false) {
          this.setState({ isSpin: false });
          setTimeout(function() {
            logout();
          }, 2000);
        }
        const formdata = new FormData();
        formdata.append("in_userid", uid);
        formdata.append("household_id", this.state.household_id);
        formdata.append("mobile_no", "");
        if (
          this.state[
            `preferred_date_${this.state.loan_data[index].loan_documentation_id}`
          ]
        ) {
          formdata.append(
            "preferred_date",
            this.state[
              `preferred_date_${this.state.loan_data[index].loan_documentation_id}`
            ]
          );
        }

        formdata.append("call_duration", "1");
        formdata.append(
          "preferred_mode",
          this.state[
            `preferred_mode_${this.state.loan_data[index].loan_documentation_id}`
          ]
            ? this.state[
                `preferred_mode_${this.state.loan_data[index].loan_documentation_id}`
              ]
            : ""
        );
        formdata.append(
          "loan_documentation_id",
          this.state.loan_data[index].loan_documentation_id
        );
        customFetch(process.env.REACT_APP_URL + "/save/cso/call/details", {
          method: "POST",
          headers: { "api-token": api_token },
          body: formdata,
        })
          .then((res) => {
            if (res.headers.get("api_code") > 0) {
              this.setState({
                isErrOpen: true,
                api_error_message: res.headers.get("api_error_message"),
                isSpin: false,
              });
            }
            return res.json();
          })

          .then((json) => {
            if (
              json.api_code === 4 ||
              json.api_code === 3 ||
              json.api_code === 5
            ) {
              localStorage.clear();
              this.props.history.push("/");
            }
            this.setState({
              isSpin: false,
              isErrOpen: true,
              api_error_message: json.message,
            });
          })
          .catch((error) => console.log("error", error));
      }
    }
  }
  viewclick(path) {
    this.setState({ isSpin: true });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    //let path = this.state.bankac.split("~")[3];
    if (path) {
      const formdata = new FormData();
      // formdata.append("bucket_name", path.split("/")[0]);
      // formdata.append("blob_name", path.split("/")[2]);
      formdata.append("blob_url", path);
      const requestOptions = {
        method: "POST",
        headers: {
          "api-token": api_token,
        },
        body: formdata,
      };
      //console.log("Body",formdata);
      customFetch(
        process.env.REACT_APP_URL + "/download/files/bucket",
        requestOptions
      )
        .then((res) => {
          if (res.headers.get("api_code") > 0) {
            this.setState({
              isErrOpen: true,
              api_error_message: res.headers.get("api_error_message"),
              isSpin: false,
            });
          }

          if (res.api_code === 4 || res.api_code === 3 || res.api_code === 5) {
            localStorage.clear();
            this.props.history.push("/");
          }
          res.blob().then((img) => {
            const url = window.URL.createObjectURL(img);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", path.split("/")[2]);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
            this.setState({
              isSpin: false,
            });
          });
        })
        .catch((error) => console.log("error", error));
    } else {
      this.setState({
        isSpin: false,
      });
    }
  }
  ImgchangeHandler(event, loan_id, nach_order_id) {
    this.setState({ isSpin: true });
    event.stopPropagation();
    //console.log("Change Image");
    //event.preventDefault();
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    var file = event.target.files[0];
    //event.target.value = null;
    console.log("loan_id", loan_id);
    console.log("FileNew", loan_id + "." + file.name.split(".").pop());
    const formdata = new FormData();
    formdata.append("file", file);
    formdata.append("order_id", nach_order_id);
    formdata.append("loan_id", loan_id);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
      },
      body: formdata,
    };
    customFetch(process.env.REACT_APP_URL + "/upload/nach/form", requestOptions)
      .then((response) => {
        if (response.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: response.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        console.log("Data", data);
        this.setState({
          NACH_form_upload: data,
          ISNACH_form_upload: true,
          isSpin: false,
        });
        //this.fetchDetails();
      })
      .catch((error) => console.log("error", error));
  }
  render() {
    const {
      isDisable,
      isVisible,
      isOpen,
      isErrOpen,
      api_error_message,
      loan_data,
      ISNACH_form_upload,
      NACH_form_upload,
    } = this.state;
    const {
      preferred_pay_mode,
      Preferred_pay_date,
      api_token,
      effective_designation_id,
    } = (this.props.location && this.props.location.state) || {};
    console.log("prevloans", this.state.prevloans);
    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div
          className={
            ISNACH_form_upload
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ ISNACH_form_upload: false });
                  window.location.reload();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>
                {NACH_form_upload ? (
                  NACH_form_upload.razorpay_order_id ? (
                    <>
                      <strong>Order Id : </strong>
                      {NACH_form_upload.razorpay_order_id}
                      <br />
                      <strong>Payment Id : </strong>
                      {NACH_form_upload.razorpay_payment_id}
                      <br />
                    </>
                  ) : (
                    <>
                      <span>{NACH_form_upload.message}</span>
                    </>
                  )
                ) : (
                  ""
                )}
              </p>
            </div>
          </div>
        </div>
        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ isErrOpen: false });
                  window.location.reload(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>
        <div
          className={
            isOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ isOpen: false });
                  this.props.history.goBack();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <span>Call Detail Save Successfully!!!</span>
              <button
                data-dismiss="modal"
                onClick={() => {
                  this.setState({ isOpen: false });
                  this.props.history.goBack();
                }}
                className="btn btn-primary btn-lg btn-block rbtn search_elements"
              >
                OK
              </button>
            </div>
          </div>
        </div>
        <div className="call_detail container d-flex flex-column align-items-center">
          <div className="call_detail_nav">
            <button
              className="button back_button"
              onClick={() => this.props.history.goBack()}
            >
              <img src={require("../assets/images/back.png")} />
            </button>
            <span className="nav_heading">Call Notes</span>
            {/* <button className='button call_button'>
              <img
                src={require("../assets/images/telephone.png")}
              />
          </button>
          <button className='button search_button'>
              <img
                src={require("../assets/images/search.png")}
              />
          </button> */}
            <button className="button" onClick={() => logout()}>
              <img src={require("../assets/images/power-off.png")} alt="" />
            </button>
          </div>

          <div className="card card-border w-100 loan-headtitle">
            <div className="card-body name">
              <span>
                {this.props.location.state.name
                  .split(" ")
                  .map((word) => word[0])
                  .join("")}
              </span>
              {this.props.location.state.name}
            </div>
          </div>
          <div className="load-dataholder">
            <form onSubmit={this.onSubmitHandler}>
              {loan_data.length > 0 ? (
                loan_data.map((loandata, index) =>
                  loandata.loan_documentation_id ? (
                    <div key={index}>
                      <input
                        type="hidden"
                        name={`loan_documentation_id${loandata.loan_documentation_id}`}
                        value={loandata.loan_documentation_id}
                      />
                      <span className="loan-title">
                        Loan A/C : {loandata.loan_id}
                      </span>
                      {/* map id : {loandata.map_id} */}
                      <div className="card card-border mb-4">
                        <div className="card-body">
                          <div className="acno-nach">
                            <span>
                              Bank A/C : {loandata.nach_details_account_number}{" "}
                              {loandata.bank_name
                                ? "(" + loandata.bank_name + ")"
                                : ""}
                            </span>
                            {loandata.nach_details_nach_type === "P" &&
                            this.state.nach_status !== 1 &&
                            loandata.nach_details_nach_status_type_flag !==
                              "R" ? (
                              <>
                                {/* below code for upload and download Physical form, if already uploaded then download signed form */}
                                {loandata.signed_filename ? (
                                  <div
                                    className="loan-nach-img m-1"
                                    onClick={() => {
                                      this.viewclick(loandata.signed_filename);
                                    }}
                                  >
                                    <img
                                      src={require("../assets/images/download.png")}
                                      alt=""
                                    />
                                  </div>
                                ) : (
                                  <>
                                    <div
                                      className="loan-nach-img m-1"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        // this.['upload'+item.cash_balance_id].click();
                                        this[
                                          "upload" + loandata.loan_id
                                        ].click();
                                      }}
                                    >
                                      <img
                                        src={require("../assets/images/upload.png")}
                                        alt=""
                                      />
                                    </div>
                                    <input
                                      type="file"
                                      name="file"
                                      accept=".jpg, .JPG, .jpeg, .JPEG, .png, .PNG"
                                      onChange={(event) => {
                                        event.preventDefault();
                                        this.setState({ isSpin: false });
                                        this.ImgchangeHandler(
                                          event,
                                          loandata.loan_id,
                                          loandata.nach_details_nach_token_id
                                        );
                                      }}
                                      //ref={(ref) => (this.upload = ref)}
                                      ref={(ref) => {
                                        this["upload" + loandata.loan_id] = ref;
                                      }}
                                      style={{ display: "none" }}
                                    />
                                    <div
                                      className="loan-nach-img m-1"
                                      onClick={() => {
                                        this.viewclick(
                                          loandata.nach_details_filename
                                        );
                                      }}
                                    >
                                      <img
                                        src={require("../assets/images/download.png")}
                                        alt=""
                                      />
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                            <div className="loan-nach-img m-1">
                              {this.state.nach_status === 1 ? (
                                <img
                                  src={require("../assets/images/nach.png")}
                                  alt=""
                                />
                              ) : effective_designation_id ==
                                process.env
                                  .REACT_APP_effective_designation_id_to ? (
                                loandata.nach_details_nach_status_type_description ? (
                                  loandata.nach_details_nach_status_type_description
                                ) : (
                                  <img
                                    src={require("../assets/images/nach_reg.png")}
                                    alt=""
                                    onClick={() => {
                                      this.props.history.push({
                                        pathname: "/Physical_NACH_register",
                                        state: {
                                          api_token: api_token,
                                          household_id: this.state.household_id,
                                          ntype:
                                            loandata.nach_details_nach_type,
                                          loan_id: loandata.loan_id,
                                          map_id: loandata.map_id,
                                          prevloans: this.state.prevloans,
                                          nach_customer_id:
                                            loandata.nach_details_nach_customer_id,
                                          nach_order_id:
                                            loandata.nach_details_nach_order_id,
                                          nach_status_type_flag:
                                            loandata.nach_details_nach_status_type_flag,
                                          nach_status_type_description:
                                            loandata.nach_details_nach_status_type_description,
                                          filename:
                                            loandata.nach_details_filename,
                                          loan_documentation_id:
                                            loandata.loan_documentation_id,
                                          hhname: this.props.location.state
                                            .name,
                                          nach_details_nach_token_id:
                                            loandata.nach_details_nach_token_id,
                                        },
                                      });
                                    }}
                                  />
                                )
                              ) : loandata.nach_details_nach_status_type_description ? (
                                loandata.nach_details_nach_status_type_description
                              ) : (
                                // <img
                                //   src={require("../assets/images/nach_reg.png")}
                                //   alt=""
                                // />
                                <></>
                              )}
                            </div>
                          </div>

                          {loandata.bank_nach_details_account_number ? (
                            <div className="last-pull">
                              <span className="pull-label">Last Pull</span>
                              <div className="lastpull-data">
                                {loandata.nach_pull.map((pulldata, index) => (
                                  <div className="each-monthdata">
                                    <span>
                                      {pulldata.nach_pull_month
                                        ? moment(
                                            pulldata.nach_pull_month,
                                            "YYY-MM-DD"
                                          ).format("MMM YY")
                                        : ""}
                                    </span>
                                    <div className="lmonthpull mt-1 mb-2">
                                      <span
                                        className={`icon_pull m${index + 1}`}
                                      >
                                        {pulldata.collection_mode_name ===
                                        "C" ? (
                                          <img
                                            src={require("../assets/images/inr-rs-1.png")}
                                            alt=""
                                            className="nach-img"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {pulldata.collection_mode_name ===
                                        "U" ? (
                                          <img
                                            src={require("../assets/images/gpay.png")}
                                            alt=""
                                            className="nach-img"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {pulldata.collection_mode_name ===
                                        "N" ? (
                                          <img
                                            src={require("../assets/images/nach.png")}
                                            alt=""
                                            className="nach-img"
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                      <div
                                        className={
                                          pulldata.settlement_status === "S"
                                            ? "psts sucs"
                                            : "psts fail"
                                        }
                                      >
                                        {pulldata.settlement_status === "S"
                                          ? "S"
                                          : "F"}
                                      </div>
                                    </div>
                                    <span className="pullamt">
                                      <img
                                        src={require("../assets/images/inr-symb.png")}
                                        alt=""
                                        className="loan-inrsym"
                                      />
                                      {pulldata.amount > 0
                                        ? (pulldata.amount / 1000)
                                            .toFixed(1)
                                            .replace(/\.0$/, "") + "K"
                                        : "0"}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : (
                            <div className="mt-4"></div>
                          )}

                          <div className="balance mt-2">
                            <span>Balanced Maintained</span>
                            <div className="loanradio_input">
                              <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                defaultChecked={
                                  loandata.preferred_mode ? false : true
                                }
                                onChange={(e) =>
                                  this.handleChangebal(
                                    e,
                                    loandata.loan_documentation_id
                                  )
                                }
                              />
                            </div>
                          </div>

                          <div
                            style={
                              this.state[
                                `isVisible_${loandata.loan_documentation_id}`
                              ] === 1 || loandata.preferred_mode
                                ? { display: "block" }
                                : { display: "none" }
                            }
                            className="balance-premode mt-2"
                          >
                            <span>Mode of Collection</span>
                            <div className="loanpaymode">
                              <select
                                id="payment_method"
                                name={`preferred_mode_${loandata.loan_documentation_id}`}
                                className="form-control"
                                placeholder="Preferred payment method"
                                onChange={(e) =>
                                  this.handleChange(
                                    e,
                                    loandata.loan_documentation_id
                                  )
                                }
                                defaultValue={
                                  loandata.preferred_mode || "Select"
                                }
                              >
                                <option value="" disabled="">
                                  Select Mode
                                </option>
                                <option value="U">UPI</option>
                                <option value="C">CASH</option>
                                <option value="H">NACH</option>
                              </select>
                            </div>
                          </div>
                          <div
                            className="pref-date mt-2"
                            style={
                              this.state[
                                `ispreferred_date_${loandata.loan_documentation_id}`
                              ] === 0 || loandata.preferred_mode === "H"
                                ? { display: "none" }
                                : { display: "block" }
                            }
                          >
                            <span>Date Of Collection</span>
                            <div className="perfcollect">
                              <input
                                type="date"
                                id="date_select"
                                className="loan-prefdate"
                                defaultValue={moment(
                                  loandata.preferred_date
                                ).format("YYYY-MM-DD")}
                                value={
                                  this.state[
                                    `preferred_date_${loandata.loan_documentation_id}`
                                  ]
                                }
                                min={`${
                                  new Date().toISOString().split("T")[0]
                                }`}
                                onChange={(e) =>
                                  this.handleChange(
                                    e,
                                    loandata.loan_documentation_id
                                  )
                                }
                                name={`preferred_date_${loandata.loan_documentation_id}`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                )
              ) : (
                <div className="text-center">No Data Found</div>
              )}
              <div className="update-btn">
                <input
                  disabled={this.state.isDisable}
                  type="submit"
                  value="Update"
                  className="btn btn-primary updbtn"
                />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}
